import { TextAreaTitles, FeedbackOption, FeedbackType } from '@talkspace/react-toolkit';

type TalktrackFeedbackType = 'helpful' | 'notGood' | 'justOK';

const SKELETON_LOADER_LENGTH = 15;

export const skeletonData = Array.from({ length: SKELETON_LOADER_LENGTH }, (_, index) => {
  let width = '90%';
  if (index % 3 === 2) {
    width = '50%';
  } else if (index % 3 === 1) {
    width = '100%';
  }

  let marginBottom = 8;
  if (index % 3 === 2) {
    marginBottom = 16;
  }
  if (index === SKELETON_LOADER_LENGTH - 1) {
    marginBottom = 8;
  }

  return {
    id: index + 1,
    width,
    marginBottom,
  };
});

export const textAreaTitles: TextAreaTitles<TalktrackFeedbackType> = {
  helpful: 'What did you like about the episode? Please describe it without including PII or PHI.',
  notGood: 'What was the issue with the episode? Please describe it without including PII or PHI.',
  justOK:
    'What could’ve been better with the episode? Please describe it without including PII or PHI.',
};

export const feedbackCheckboxes: Record<TalktrackFeedbackType, FeedbackOption[]> = {
  helpful: [
    { label: 'Quality content', field: 'qualityContent' },
    { label: 'It fits well into my workflow', field: 'fitsInWorkflow' },
    { label: 'Episode length', field: 'episodeLength' },
  ],
  notGood: [
    { label: 'I wasn’t satisfied with the content', field: 'notSatisfiedWithContent' },
    { label: 'It doesn’t fit into my workflow', field: 'notFitWorkflow' },
    { label: 'Episode length', field: 'episodeLength' },
  ],
  justOK: [
    { label: 'I wasn’t satisfied with the content', field: 'notSatisfiedWithContent' },
    { label: 'It doesn’t fit into my workflow', field: 'notFitWorkflow' },
    { label: 'Episode length', field: 'episodeLength' },
  ],
};

export const feedbackTypes: FeedbackType<TalktrackFeedbackType>[] = [
  {
    label: 'Not good',
    value: 'notGood',
    dataQa: 'notGoodChip',
  },
  {
    label: 'Just okay',
    value: 'justOK',
    dataQa: 'justOkayChip',
  },
  {
    label: 'Helpful',
    value: 'helpful',
    dataQa: 'helpfulChip',
  },
];

export const NUMBER_OF_REGENERATIONS = 'numberOfRegenerations';
