import { useMutation } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';

export interface CreateTalkTracksProps {
  roomID: string;
  liveCallID: number;
  providerSuggestion: string | null;
}

export interface CreateTalkTracksResponse {
  transcript?: string;
  talktrackID: number;
}

const createTalkTracks = async (
  params: CreateTalkTracksProps
): Promise<CreateTalkTracksResponse> => {
  const { roomID, liveCallID, providerSuggestion } = params;
  const { data } = await apiWrapper.post(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/talktracks`,
    {
      liveCallID,
      providerSuggestion,
    }
  );
  return data.data;
};

const useMutationCreateTalktracks = () =>
  useMutation<CreateTalkTracksResponse, Error, CreateTalkTracksProps>(createTalkTracks);

export default useMutationCreateTalktracks;
