import { Link, styled, TextDS, View } from '@talkspace/react-toolkit';
import ssoHelper from '@/utils/sso';

export const StyledLink = styled(Link)(({ theme: { colors } }) => {
  return {
    fontSize: 14,
    fontWeight: 700,
    color: colors.accessibilityGreenDark,
    textDecoration: 'underline',
    marginLeft: 0,
    whiteSpace: 'nowrap',
    '&:hover': {
      color: colors.accessibilityGreenDark,
    },
  };
});

export default function MessagingTooltip() {
  return (
    <>
      <View style={{ gap: 4 }}>
        <TextDS variant="headingMd">How was this session determined to be ready to submit?</TextDS>

        <TextDS>
          After 16 minutes of clinical engagement and a session length of 7 days, there may be
          enough clinical engagement to submit.{' '}
          <StyledLink
            onClick={() =>
              ssoHelper.openZendesk('/hc/en-us/articles/360060551231#h_01G899C64JZRRDBZXP63P8CGCH')
            }
          >
            Learn more
          </StyledLink>
        </TextDS>
      </View>

      <View style={{ gap: 4 }}>
        <TextDS variant="headingMd">
          How are the minutes of clinical engagement calculated for messaging sessions?
        </TextDS>

        <TextDS>
          Minutes are calculated at 40 words per minute for texting, 150 words per PDF file, and
          message duration for audio or video messages.
        </TextDS>
      </View>
    </>
  );
}
