import { Button, Modal, styled, TextDS, useWindowWidthState, View } from '@talkspace/react-toolkit';
import IconButton from '@talkspace/react-toolkit/src/designSystems/components/IconButton';
import { XMarkLarge } from '@talkspace/react-toolkit/src/designSystems/icons';
import { getUserData } from '@/auth/helpers/token';
import { useSupervisedNotesTable } from './TableContext';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import TableContainer from './TableContainer';
import CellView from './CellView';
import useMutationApproveProgressNote from '../../../hooks/notes/useMutationApproveProgressNote';
import useQuerySupervisedNotes from '../../../hooks/notes/useQuerySupervisedNotes';
import DateOfSessionCell from './DateOfSessionCell';
import ClientCell from './ClientCell';

const modalPanelStyle = {
  padding: 0,
  width: '100%',
  alignItems: 'center',
};

const ConfirmationText = () => (
  <>
    <TextDS variant="headingLg">Are you sure you want to approve these?</TextDS>

    <TextDS variant="bodySm" colorRole="textSubtlest">
      Once approved, sessions are submitted to billing as claims
    </TextDS>
  </>
);

const HeaderView = styled(View)(
  ({
    theme: {
      window: { isMobile },
      colorRoles,
      spacing,
    },
  }) => {
    return {
      width: '100%',
      gap: spacing('space050'),
      padding: spacing('space300'),
      border: isMobile ? `1px solid ${colorRoles.borders.borderDefault}` : 'none',
      alignItems: isMobile ? 'center' : 'flex-start',
    };
  }
);

const ButtonsView = styled(View)(
  ({
    theme: {
      window: { isMobile },
      spacing,
    },
  }) => {
    return {
      width: '100%',
      flexDirection: isMobile ? 'column-reverse' : 'row',
      justifyContent: 'flex-end',
      alignItems: isMobile ? 'center' : 'flex-end',
      gap: isMobile ? spacing('space050') : spacing('space200'),
      padding: spacing('space200'),
      ...(isMobile ? { position: 'fixed', bottom: 0 } : {}),
    };
  }
);

const desktopColumnWidths = { associate: 120, sessionDate: 120, client: 220 };

const mobileColumnWidths = { associate: 100, client: 150 };

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function BulkConfirmationDialog({ isOpen, onClose }: Props) {
  const { id: therapistUserID } = getUserData();

  const { isMobile } = useWindowWidthState();

  const { tableState } = useSupervisedNotesTable();

  const { mutateAsync: approveProgressNote, isLoading } = useMutationApproveProgressNote();

  const { refetch: refetchSupervisedNotes } = useQuerySupervisedNotes(therapistUserID);

  const handleConfirmPress = async () => {
    await Promise.all(
      tableState.selectedNotes.map((selectedNote) =>
        approveProgressNote({
          roomID: selectedNote.roomID,
          noteID: selectedNote.id,
          therapistUserID,
        })
      )
    );

    await refetchSupervisedNotes();

    onClose();
  };

  const renderDesktopView = () => (
    <>
      <HeaderView>
        <ConfirmationText />

        <IconButton
          dataQa="closeButton"
          Icon={<XMarkLarge size="major" />}
          onPress={onClose}
          style={{ position: 'absolute', top: 16, right: 16 }}
        />
      </HeaderView>

      <TableContainer style={{ width: 508 }}>
        <TableHeader>
          <CellView style={{ width: desktopColumnWidths.associate }}>
            <TextDS variant="headingSm">Associate</TextDS>
          </CellView>

          <CellView style={{ width: desktopColumnWidths.sessionDate }}>
            <TextDS variant="headingSm">Date of session</TextDS>
          </CellView>

          <CellView style={{ width: desktopColumnWidths.client }}>
            <TextDS variant="headingSm">Client</TextDS>
          </CellView>
        </TableHeader>

        <View style={{ maxHeight: 404, overflowY: 'auto' }}>
          {tableState.selectedNotes.map((it) => (
            <TableRow key={it.id}>
              <CellView style={{ width: desktopColumnWidths.associate }}>
                <TextDS variant="bodySm" colorRole="textSubtle">
                  {it.associate.name}
                </TextDS>
              </CellView>

              <CellView style={{ width: desktopColumnWidths.sessionDate }}>
                <DateOfSessionCell
                  startDate={new Date(it.session.startDate)}
                  endDate={new Date(it.session.endDate)}
                  shouldDisplayRange={it.session.modality === 'messaging'}
                />
              </CellView>

              <CellView style={{ width: desktopColumnWidths.client }}>
                <ClientCell client={it.client} />
              </CellView>
            </TableRow>
          ))}
        </View>
      </TableContainer>
    </>
  );

  const renderMobileView = () => (
    <>
      <HeaderView>
        <TextDS variant="headingMd">Approve selected</TextDS>

        <IconButton
          dataQa="closeButton"
          Icon={<XMarkLarge size="major" colorType="brand" />}
          onPress={onClose}
          style={{ position: 'absolute', top: 16, right: 16 }}
        />
      </HeaderView>

      <View style={{ padding: '24px 60px', textAlign: 'center', gap: 8 }}>
        <ConfirmationText />
      </View>

      <TableContainer style={{ width: 360 }}>
        <TableHeader>
          <CellView style={{ width: mobileColumnWidths.associate }}>
            <TextDS variant="headingSm">Associate</TextDS>
          </CellView>

          <CellView style={{ width: mobileColumnWidths.client }}>
            <TextDS variant="headingSm">Client</TextDS>
          </CellView>
        </TableHeader>

        <View style={{ maxHeight: 560, overflowY: 'auto' }}>
          {tableState.selectedNotes.map((it) => (
            <TableRow key={it.id}>
              <CellView style={{ width: mobileColumnWidths.associate }}>
                <TextDS variant="bodySm" colorRole="textSubtle">
                  {it.associate.name}
                </TextDS>
              </CellView>

              <CellView style={{ width: mobileColumnWidths.client }}>
                <TextDS variant="headingSm">{it.client.name || it.client.nickname}</TextDS>

                <DateOfSessionCell
                  startDate={new Date(it.session.startDate)}
                  endDate={new Date(it.session.endDate)}
                  shouldDisplayRange={it.session.modality === 'messaging'}
                />
              </CellView>
            </TableRow>
          ))}
        </View>
      </TableContainer>
    </>
  );

  return (
    <Modal isVisible={isOpen} onBackdropPress={onClose}>
      <Modal.Panel
        onBackdropPress={onClose}
        showCloseButton={false}
        contentViewStyle={modalPanelStyle}
        minHeight={260}
        wrapperStyle={{ width: isMobile ? undefined : 556 }}
      >
        {isMobile ? renderMobileView() : renderDesktopView()}

        <ButtonsView>
          <Button text="Cancel" variant="tertiary" dataQa="cancelButton" onPress={onClose} />

          <Button
            text="Confirm"
            dataQa="confirmButton"
            onPress={handleConfirmPress}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </ButtonsView>
      </Modal.Panel>
    </Modal>
  );
}
