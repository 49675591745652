import { styled, View } from '@talkspace/react-toolkit';

const TableHeader = styled(View)(({ theme: { colorRoles } }) => {
  return {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    background: colorRoles.surfaces.neutralDefault,
    padding: 12,
    borderRadius: '7px 7px 0px 0px',
    borderBottom: `1px solid ${colorRoles.borders.borderDefault}`,
    justifyContent: 'space-between',
  };
});

export default TableHeader;
