import { QuestionnaireAnswerData } from 'ts-frontend/hooks/questionnaires/types';

const safetyPlanDeepCompare = (
  initAnswers: Array<QuestionnaireAnswerData>,
  currentAnswers: Array<QuestionnaireAnswerData>
) => {
  const initAnswersString = initAnswers
    .filter(
      (it) =>
        (it.options?.length && it.options.length > 0) ||
        (it.textOptions?.length && it.textOptions.length > 0)
    )
    .map((initialAnswer) => {
      const initOptions = initialAnswer?.options?.sort((a, b) => a - b).toString() || '';
      const initText =
        initialAnswer?.textOptions?.sort((a, b) => a.localeCompare(b)).toString() || '';

      return `${initOptions}${initText}`;
    });
  const currentAnswersString = currentAnswers
    .filter(
      (it) =>
        (it.options?.length && it.options.length > 0) ||
        (it.textOptions?.length && it.textOptions.length > 0)
    )
    .map((answer) => {
      const currentOptions = answer?.options?.sort((a, b) => a - b).toString() || '';
      const currentText = answer?.textOptions?.sort((a, b) => a.localeCompare(b)).toString() || '';

      return `${currentOptions}${currentText}`;
    });

  return initAnswersString.toString() === currentAnswersString.toString();
};

// eslint-disable-next-line import/prefer-default-export
export { safetyPlanDeepCompare };
