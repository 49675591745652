import { Checkbox } from 'react-bootstrap';
import './CheckListItem.css';
import { Tag } from '@talkspace/react-toolkit';

const getStyle = (disabled, hidden, checked) => {
  let style = 'checkbox-green';
  if (disabled) style = `${style} disabled`;
  if (hidden) style = `${style} hidden`;
  if (checked) style = `${style} checked`;
  return style;
};

const CheckListItem = ({
  item,
  changeHandler,
  displayField,
  disabled,
  hidden,
  checkField,
  dataQa,
}) => (
  <div className="checklist-item">
    <Checkbox
      className={getStyle(disabled, hidden, item[checkField])}
      checked={item[checkField]}
      onChange={(event) => {
        const newItem = { ...item };
        newItem[checkField] = event.target.checked;
        changeHandler(event, newItem);
      }}
      disabled={disabled}
      hidden={hidden}
      data-qa={dataQa || item[displayField]}
    >
      {item.tagText && <Tag text={item.tagText} color="infoDefault" />}
      {item[displayField]}
    </Checkbox>
  </div>
);

CheckListItem.defaultProps = {
  checkField: 'checked',
};

export default CheckListItem;
