import moment, { Moment } from 'moment';
import { Styles } from 'react-select';
import { LiveSessionModality, ProgressNoteModalityType } from 'ts-frontend/types';
import { EmotionStyle, EmotionTheme, OptionType, COLORS } from '@talkspace/react-toolkit';
import { BusinessLineQueryResponse } from 'ts-frontend/hooks/useQueryBusinessLine';
import { ProgressNoteQueryResponse } from 'hooks/notes/useQueryProgressNote';
import { NoteItem, NoteSessionReportData, NotesQueryResponse } from 'hooks/notes/useQueryNotes';
import { AccountType } from 'ts-frontend/entities/Room';
import {
  FormMode,
  NoteType,
  ReferralCondition,
  SessionService,
  TooltipText,
  VideoCall,
  ProgressNoteTabs,
  ProgressNoteRiskAssessmentCreationAttributes,
  SubstanceUseNoteField,
  ProgressNoteSubstanceUseCreationAttributes,
} from './types';
import { SectionInfo, ProgressNoteFormState } from './ProgressNoteForm/context/types';
import capitalize from '../../../../utils/capitalize';
import { SessionSubmissionStatus } from '../../../../hooks/notes/useQueryUnsubmittedSessions';
import { SessionStatus } from '../../../../hooks/dashboard/useQueryTaskListV3';

export const BH_AND_EAP_INITIAL_CPT_CODE = '90791';
export const PSYCH_INITIAL_CPT_CODE = '90792';
export const PSYCH_FOLLOWUP_CPT_CODE_1 = '99213';
export const PSYCH_FOLLOWUP_CPT_CODE_2 = '99214';
export const ACTION_SETTLED_TIMEOUT = 2500;
export const DEFAULT_RISK_OR_BARRIERS_HANDLING = 'None reported by provider';
export const DEFAULT_RELATIONSHIP_TO_CLIENT = 'Identified client';

export enum Modality {
  TEXT = 1,
  VIDEO = 2,
}

export const SUPPORT_ARTICLE_LINK =
  '/hc/en-us/categories/360004281672-Documentation-Billing-Payment';

export const BH_TOOLTIP_ARTICLE_LINK =
  '/hc/en-us/articles/360060551231#h_01G899C64JZRRDBZXP63P8CGCH';

export const EAP_TOOLTIP_ARTICLE_LINK =
  '/hc/en-us/articles/360060109292#h_01GCH70RDH67CBR6ZH0CZAJQ5H';

export const SELF_PAY_TOOLTIP_ARTICLE_LINK =
  '/hc/en-us/articles/8687435091867#h_01GCF76Y6AB2AK33MW01Q2WV72';

export const DTE_TOOLTIP_ARTICLE_LINK =
  '/hc/en-us/articles/8687919484187#h_01GCH8FHKDFW86B0BQGQ3A0QHR';

export const HOW_AM_I_PAID_ARTICLE =
  '/hc/en-us/articles/360056628992-How-am-I-paid-#h_01G6B3MCASD85Q3R4DMD539JVC';

export const QUALITY_DOCUMENTATION_ARTICLE =
  '/hc/en-us/articles/360050009751-How-can-I-document-session-notes-on-the-Talkspace-platform-#01GBDH1PDAF89QYQPF3E5HMKDC';

export const LOW_ENGAGEMENT_ARTICLE = '/hc/en-us/articles/8465130941979#01GBD9E6JRY9VQQCJYPWFXZ55Z';

export const GENERATED_SESSION_SUMMARY_BANNER_ARTICLE =
  '/hc/en-us/articles/360050009751#01GBDH1PDAF89QYQPF3E5HMKDC';

export const MAX_UNSUBMITTED_SESSIONS = 3;

interface GetSessionReportPositionParams {
  progressNoteData?: ProgressNoteQueryResponse;
  formMode?: FormMode;
  businessLineData?: BusinessLineQueryResponse;
  notesData?: NotesQueryResponse;
  eapSessionReport?: NoteSessionReportData | null;
  accountType?: AccountType;
}

export const getSessionReportPosition = ({
  progressNoteData,
  formMode,
  businessLineData,
  notesData,
  eapSessionReport,
  accountType,
}: GetSessionReportPositionParams) => {
  let sessionReportPosition = progressNoteData?.sessionReportPosition;

  if (formMode === 'create') {
    if (businessLineData?.isEAP || accountType === 'eap') {
      sessionReportPosition =
        eapSessionReport?.position || notesData?.currentSessionReport?.position;
    }
    if (businessLineData?.isBH || accountType === 'bh') {
      sessionReportPosition = notesData?.currentSessionReport?.position;
    }
  }

  return sessionReportPosition || 0;
};

interface GetProgressNoteSubmenuTitleAndSubtitle {
  formMode: FormMode;
  businessLine?: BusinessLineQueryResponse;
  sessionReportPosition?: number | null;
  serviceStartDate?: Date | null;
  serviceEndDate?: Date | null;
}

export const moveItemToEndOfArrayByIndex = (array: Array<any>, index: number) => {
  array.push(array.splice(index, 1)[0]);
};

export const getModalityIDFromParam = (param: ProgressNoteModalityType) =>
  param === ProgressNoteModalityType.liveSession ? Modality.VIDEO : Modality.TEXT;

export const isSameDate = (dateOne: Date, dateTwo: Date) =>
  dateOne.getFullYear() === dateTwo.getFullYear() &&
  dateOne.getMonth() === dateTwo.getMonth() &&
  dateOne.getDate() === dateTwo.getDate();

const getProgressNoteSubmenuTitleAndSubtitle = ({
  formMode,
  businessLine,
  sessionReportPosition,
  serviceStartDate,
  serviceEndDate,
}: GetProgressNoteSubmenuTitleAndSubtitle) => {
  let title = formMode === 'create' ? 'New progress note' : 'Progress note';
  let subtitle = '';

  if (businessLine) {
    const { isPsychiatry, isEAP, isBH } = businessLine;

    if (isPsychiatry) {
      title = formMode === 'create' ? 'New psychiatric progress note' : 'Psychiatric progress note';
    }

    if (isBH) {
      title = `Summary note: Session ${sessionReportPosition}`;
    }

    if (isEAP) {
      title = `Summary Note: EAP Session ${sessionReportPosition}`;
    }
  }

  if (serviceStartDate && serviceEndDate) {
    const sameYear = serviceStartDate.getFullYear() === serviceEndDate.getFullYear();

    const serviceStartMoment = moment(serviceStartDate);
    const serviceEndMoment = moment(serviceEndDate);

    const startDateFormatted = sameYear
      ? serviceStartMoment.format('MMM DD')
      : serviceStartMoment.format('MMM DD, YYYY');
    const endDateFormatted = serviceEndMoment.format('MMM DD, YYYY');

    subtitle = isSameDate(serviceStartDate, serviceEndDate)
      ? endDateFormatted
      : `${startDateFormatted} - ${endDateFormatted}`;
  }

  return { title, subtitle };
};

export const getServiceDatesFromVideoCall = (
  videoCall: VideoCall
): {
  serviceStartDate: Date;
  serviceEndDate: Date;
} => {
  const callStartedAtDate = new Date(videoCall.callStartedAt);
  const newServiceEndDate = moment(videoCall.callStartedAt)
    .add(videoCall.totalActiveCallDuration, 's')
    .toDate();
  return {
    serviceStartDate: callStartedAtDate,
    serviceEndDate: newServiceEndDate,
  };
};

export const getVideoCallModalityText = (
  startedAt: Moment,
  endedAt: Moment,
  modality: LiveSessionModality
) =>
  `${modality.charAt(0).toUpperCase()}${modality.slice(1)} - ${startedAt.format(
    'ddd, MMM D, LT'
  )} - ${endedAt.format('LT')}`;

export const generateVideoCallSelectOption = (
  videoCall: VideoCall | undefined
): OptionType<number> => {
  if (!videoCall) {
    return {
      value: 0,
      label: '',
    };
  }
  const startDate = moment(videoCall.callStartedAt);
  const endDate = moment(videoCall.callStartedAt).add(videoCall.totalActiveCallDuration, 's');
  return {
    value: videoCall.id,
    label: getVideoCallModalityText(startDate, endDate, videoCall.modality),
  };
};

export const generateReferralOptions = (
  referralConditions: Array<ReferralCondition>
): Array<OptionType<number | null>> => [
  { value: null, label: 'None' },
  ...referralConditions.map((it) => {
    return {
      value: it.id,
      label: it.condition,
    };
  }),
];

export const updateObjectInArray = <T extends Record<string, unknown>>(
  arr: Array<T>,
  index: number,
  updatedPartial: Partial<T>
): Array<T> => {
  const targetValue = arr[index];
  const updatedItem = {
    ...targetValue,
    ...updatedPartial,
  };
  const copy = [...arr];
  copy[index] = updatedItem;

  return copy;
};

export const getTextAreaContainerBorderStyle = (
  colors: EmotionTheme['colors'],
  isError: boolean = false
): EmotionStyle => {
  return {
    borderColor: isError ? colors.permaDebianRed : colors.permaGainsboro,
  };
};

export const getTextAreaContainerBorderStyleV2 = (
  colors: EmotionTheme['colors'],
  isError: boolean = false
): EmotionStyle => {
  return {
    backgroundColor: colors.permaPhantomWhite,
    minHeight: 60,
    margin: 0,
    boxSizing: 'border-box',
    padding: 0,
    ...(isError && { borderColor: colors.permaFuchsia }),
  };
};

export const getTextAreaStyle = (disabled: boolean): EmotionStyle => {
  return {
    padding: 5,
    margin: 10,
    opacity: 1,
    ...(disabled ? { backgroundColor: 'white' } : {}),
  };
};

export const getTextAreaStyleV2 = (
  disabled: boolean,
  colors: EmotionTheme['colors']
): EmotionStyle => {
  return {
    opacity: 1,
    fontSize: 14,
    caretColor: colors.accessibilityGreenDark,
    backgroundColor: disabled ? colors.white : colors.permaPhantomWhite,
    padding: 0,
    margin: '12px',
    color: colors.black,
    letterSpacing: '0.1px',
    lineHeight: '18px',
    width: '93%',
    overflowX: 'hidden',
  };
};

export const getSelectStyle = (
  colors: EmotionTheme['colors'],
  isError: boolean
): Styles<OptionType, boolean> => {
  return {
    control: (provided) => {
      return {
        ...provided,
        ...(isError ? { borderColor: colors.permaDebianRed } : {}),
      };
    },
  };
};

export const getSelectStyleWithoutBorder = (
  colors: EmotionTheme['colors'],
  isError: boolean
): Styles<OptionType, boolean> => {
  return {
    control: (provided) => {
      return {
        ...provided,
        ...(isError ? { borderColor: colors.permaFuchsia } : {}),
      };
    },
    option: (provided, { isSelected }) => {
      return {
        ...provided,
        padding: 11,
        '&:hover': {
          backgroundColor: colors.aqua,
          color: colors.white,
        },
        backgroundColor: isSelected ? colors.whiteLilac : colors.white,
        width: isSelected ? 'calc(100% - 10px)' : '100%',
        margin: isSelected && '6px auto 6px auto',
        borderRadius: isSelected && 5,
        color: isSelected && colors.black,
      };
    },
    menu: (provided) => {
      return {
        ...provided,
        right: 0,
        padding: 0,
        borderRadius: 8,
        boxShadow: '0px 7px 16px rgba(0, 29, 47, 0.3875)',
      };
    },
  };
};

interface GetSelectStyleV2Props {
  colors: EmotionTheme['colors'];
  isError?: boolean;
  hasValue?: boolean;
  disableCapitalized?: boolean;
  minWidth?: number;
}
export const getSelectStyleV2 = ({
  colors,
  isError,
  hasValue,
  disableCapitalized = false,
  minWidth = 71,
}: GetSelectStyleV2Props): Styles<OptionType, boolean> => {
  return {
    control: (provided, state) => {
      let borderColor = colors.periwinkleGrey;
      if (state.isFocused) {
        borderColor = colors.darkBlue;
      }
      if (isError) {
        borderColor = colors.permaFuchsia;
      }
      if (hasValue) {
        borderColor = 'transparent';
      }
      return {
        ...provided,
        minHeight: 30,
        height: 30,
        alignContent: 'center',
        paddingLeft: 0,
        borderRadius: 8,
        borderColor: `${borderColor} !important`,
      };
    },
    container: (provided) => {
      return {
        ...provided,
        minWidth,
      };
    },
    singleValue: (provided) => {
      return {
        ...provided,
        fontSize: 14,
        fontWeight: 400,
        paddingLeft: 9,
      };
    },
    dropdownIndicator: (provided) => {
      return {
        ...provided,
        paddingRight: 4,
        paddingLeft: 4,
      };
    },
    menu: (provided) => {
      return {
        ...provided,
        minWidth: 200,
        width: 'max-content',
        maxWidth: 355,
        right: 0,
        padding: 0,
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      };
    },
    menuList: (provided) => {
      return {
        ...provided,
        width: '100%',
        padding: '0 !important',
        maxHeight: 240,
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      };
    },
    valueContainer: (provided) => {
      return {
        ...provided,
        ...(disableCapitalized ? {} : { textTransform: 'capitalize' }),
      };
    },
    option: (provided, { isSelected }) => {
      return {
        ...provided,
        padding: 11,
        '&:hover': {
          backgroundColor: colors.aqua,
          color: colors.white,
        },
        backgroundColor: isSelected ? colors.whiteLilac : colors.white,
        width: isSelected ? 'calc(100% - 10px)' : '100%',
        margin: isSelected && '6px auto 6px auto',
        borderRadius: isSelected && 5,
        color: isSelected && colors.black,
      };
    },
    placeholder: (provided) => {
      return {
        ...provided,
        padding: '2px 10px',
        fontSize: 14,
        color: colors.softGray,
      };
    },
  };
};

export const SESSION_SUMMARY_CHARS_LIMIT = 15000;
export const RISK_ASSESSMENT_CHARS_LIMIT = 5000;
export const SUBSTANCE_ABUSE_CHARS_LIMIT = 5000;
export const SESSION_SUMMARY_WORD_LIMIT = 2500;
export const NO_TREATMENT_PLAN_WORD_LIMIT = 5000;
export const SESSION_SAMMARY_MIN_WORD_COUNT = 20;

interface GetSubmenuTitleAndSubtitleParams {
  noteType: NoteType;
  formMode: FormMode;
  noteDate?: string | null;
  businessLine?: BusinessLineQueryResponse;
  sessionReportPosition?: number | null;
  serviceStartDate?: Date | null;
  serviceEndDate?: Date | null;
  isTherapist?: boolean;
}

export const noteTypeDict: Record<NoteType, string> = {
  'case-consultation': 'case consultation note',
  collateral: 'collateral note',
  discharge: 'discharge note',
  progress: 'progress note',
  psychotherapy: 'psychotherapy note',
  transfer: 'transfer',
  'psychiatry-referral': 'psychiatry referral',
};

export const getSubmenuTitleAndSubtitle = (params: GetSubmenuTitleAndSubtitleParams) => {
  if (params.isTherapist) {
    return {
      title: 'Progress note',
      subtitle: '',
    };
  }
  if (params.noteType === 'progress') {
    return getProgressNoteSubmenuTitleAndSubtitle(params);
  }

  if (params.formMode === 'create') {
    return {
      title: `New ${noteTypeDict[params.noteType]}`,
      subtitle: '',
    };
  }

  return {
    title: capitalize(noteTypeDict[params.noteType]),
    subtitle: params.noteDate ? `${moment(params.noteDate).format('MMM DD, YYYY')}` : '',
  };
};

export const getNoteTypeDescription = (
  noteType: NoteType,
  businessLine?: BusinessLineQueryResponse
) => {
  if (noteType === 'progress' && (businessLine?.isBH || businessLine?.isEAP)) {
    return 'Session summary note';
  }
  return capitalize(noteTypeDict[noteType]);
};

export const OTHER_REFERRAL_ID = 13;

interface GetNoteTitleParams {
  noteItem: NoteItem;
  businessLine?: BusinessLineQueryResponse;
}

export const getNoteTitle = ({ noteItem, businessLine }: GetNoteTitleParams) => {
  const { startDate, endDate, sessionReportData } = noteItem;

  let title = '';

  if (businessLine?.isEAP && sessionReportData) {
    title += `EAP ${sessionReportData.position}: `;
  }

  if (businessLine?.isBH && sessionReportData) {
    title += `Session ${sessionReportData.position}: `;
  }

  const startDateFormatted = moment(startDate).format('MMM D, YYYY');

  if (!endDate || isSameDate(new Date(startDate), new Date(endDate))) {
    return `${title}${startDateFormatted}`;
  }

  const endDateFormatted = moment(endDate).format('MMM D, YYYY');

  const dates =
    new Date(startDate).getFullYear() === new Date(endDate).getFullYear()
      ? `${moment(startDate).format('MMM D')} - ${endDateFormatted}`
      : `${startDateFormatted} - ${endDateFormatted}`;

  return `${title}${dates}`;
};

const removeChangedField = (formSection: SectionInfo) => {
  const { completed, open } = formSection;
  return { completed, open };
};

const getStartDateWithEndDateTime = (startDate: Date, endDate: Date) => {
  const startDateWithEndDateTime = new Date(startDate);
  startDateWithEndDateTime.setHours(endDate.getHours());
  startDateWithEndDateTime.setMinutes(endDate.getMinutes());
  startDateWithEndDateTime.setSeconds(endDate.getSeconds());
  return startDateWithEndDateTime;
};

const cleanProgressNoteState = (
  progressNoteState: Omit<ProgressNoteFormState, 'formMode' | 'showGlobalError' | 'formVariant'>
) => {
  const {
    currentSection,
    isSaveAsDraftPressed,
    isSubmissionTooLateForPayout,
    submissionStatus,
    draftProgressNoteID,
    isSessionSummaryTooShort,
    isFirstNote,
    unsubmittedSession,
    unsubmittedSessionID,
    unsubmittedFreeLiveCalls,
    ...restFormState
  } = progressNoteState;
  const formSections = Object.entries(restFormState.formSections).reduce(
    (prev, [sectionName, sectionInfo]) => {
      return { ...prev, [sectionName]: removeChangedField(sectionInfo) };
    },
    {}
  );
  return {
    ...restFormState,
    formSections,
  };
};

export const createProgressNoteRequest = (
  progressNoteState: Omit<ProgressNoteFormState, 'formMode' | 'showGlobalError' | 'formVariant'>,
  isTherapist?: boolean
) => {
  const cleanState = cleanProgressNoteState(progressNoteState);
  return {
    ...cleanState,
    serviceStartDate:
      !isTherapist &&
      progressNoteState.modalityID === Modality.TEXT &&
      progressNoteState.serviceEndDate &&
      progressNoteState.serviceStartDate
        ? getStartDateWithEndDateTime(
            progressNoteState.serviceStartDate,
            progressNoteState.serviceEndDate
          )
        : progressNoteState.serviceStartDate,
  };
};

export const BooleanDropdown: Array<OptionType<boolean>> = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

export const getCheckBoxStyle = (
  colors: EmotionTheme['colors'],
  isChecked: boolean,
  isDisabled?: boolean
) => {
  let backgroundColor = colors.permaPhantomWhite;
  if (isChecked) {
    backgroundColor = colors.accessibilityGreenDark;
  } else if (isDisabled) {
    backgroundColor = colors.permaGreyed;
  }
  return {
    width: 21,
    height: 21,
    borderColor: colors.grey950,
    background: backgroundColor,
    cursor: isDisabled && 'default',
  };
};

export const filterSessionService = (
  modalityId: Modality | null,
  sessionServiceArray: SessionService[]
) => {
  if (modalityId === Modality.VIDEO) {
    return sessionServiceArray.filter((session) => session.allowSync);
  }

  return sessionServiceArray.filter((session) => session.allowAsync);
};

export const getSubmissionStatus = (
  submissionStatus: SessionStatus | null,
  colors: EmotionTheme['colors']
) => {
  const statusMap = {
    reopenedAndReadyForReview: {
      text: 'Reopened and ready for review',
      background: 'successDefault',
      color: colors.accessibilityDarkGreenBold,
      wordCountColor: colors.accessibilityGreenDark,
    },
    needsEngagement: {
      text: 'May need engagement',
      background: 'infoDefault',
      color: colors.aqua800,
      wordCountColor: colors.accessibilityGreenDark,
    },
    readyForReview: {
      text: 'Ready for review',
      background: 'successDefault',
      color: colors.accessibilityDarkGreenBold,
      wordCountColor: colors.forestGreen,
    },
    readyToStart: {
      text: 'Ready to start',
      background: 'successBoldDefault',
      color: colors.white,
      wordCountColor: colors.forestGreen,
    },
    readyToSubmit: {
      text: 'Ready to submit',
      background: 'successBoldDefault',
      color: colors.white,
      wordCountColor: colors.forestGreen,
    },
    noShow: {
      text: 'Client no-show',
      background: 'warningDefault',
      color: colors.darkPurple500,
      wordCountColor: colors.forestGreen,
    },
    lateCancellation: {
      text: 'Client late cancellation',
      background: 'warningDefault',
      color: colors.darkPurple500,
      wordCountColor: colors.forestGreen,
    },
    tooSoonToSubmit: {
      text: null,
      background: null,
      color: null,
      wordCountColor: colors.accessibilityGreenDark,
    },
    needsRevision: {
      text: 'Needs revision',
      background: 'criticalDefault',
      color: colors.white,
      wordCountColor: colors.forestGreen,
    },
    default: {
      text: 'Ready to submit',
      background: 'successBoldDefault',
      color: colors.white,
      wordCountColor: colors.forestGreen,
    },
  };

  return submissionStatus && statusMap[submissionStatus]
    ? statusMap[submissionStatus]
    : statusMap.default;
};

export const getModalitySubtitle = (
  sessionModality: string,
  liveSessionType?: string | null
): string => {
  if (sessionModality === 'messaging') {
    return 'Messaging session';
  }
  return liveSessionType ? `Live ${liveSessionType} session` : `Live video session`;
};

const getSessionServiceBulletPoints = (sessionServices?: SessionService[]) => {
  const isTitleExists = sessionServices?.some(
    (item) => !item.sessionServiceTitle || !item.sessionServiceSubtitle
  );
  if (!sessionServices || isTitleExists) {
    return [
      {
        title: 'Psychotherapy, 30-min follow up 90832',
        subtitle: '(16 - 37 min or 640 - 1,499 words)',
      },
      {
        title: 'Psychotherapy, 45-min follow up 90834',
        subtitle: '(38 - 52 min or 1,500 - 2,099 words)',
      },
      { title: 'Psychotherapy, 60-min follow up- 90837', subtitle: '(Over 53 min or 2,100 words)' },
      {
        title: 'Psychotherapy, 45-min initial eval- 90791',
        subtitle: '(30 - 90 min or 1,200 - 3,600 words)',
      },
    ];
  }

  return sessionServices.map((item) => {
    return {
      title: item.sessionServiceTitle,
      subtitle: item.sessionServiceSubtitle.replace(/-/g, '–'),
    };
  });
};

const getSessionServiceBulletPointsV2 = (sessionServices?: SessionService[]) => {
  const isTitleExists = sessionServices?.some(
    (item) => !item.sessionServiceTitle || !item.sessionServiceSubtitle
  );
  if (!sessionServices || isTitleExists) {
    return [
      {
        title: 'Psychotherapy, 30-min follow up 90832',
        subtitle: '16 - 37 min',
      },
      {
        title: 'Psychotherapy, 45-min follow up 90834',
        subtitle: '38 - 52 min',
      },
      { title: 'Psychotherapy, 60-min follow up- 90837', subtitle: 'Over 53 min' },
      {
        title: 'Psychotherapy, 45-min initial eval- 90791',
        subtitle: '30 - 90 min',
      },
    ];
  }

  return sessionServices.map((item) => {
    return {
      title: item.sessionServiceTitle,
      subtitle: item.sessionServiceSubtitle.replace(/-/g, '–'),
    };
  });
};

interface GetBHTooltipTextV2Params {
  submissionStatus: SessionSubmissionStatus | null;
  minDurationPerSession?: number;
  maxDurationPerSession?: number;
  sessionServices?: SessionService[];
}
export const getBHTooltipTextV2 = ({
  submissionStatus,
  minDurationPerSession = 16,
  maxDurationPerSession = 52,
  sessionServices,
}: GetBHTooltipTextV2Params): Array<TooltipText> => {
  // TODO: change link
  const bulletPoints = getSessionServiceBulletPointsV2(sessionServices);
  const defaultTooltip = {
    title: 'Why does this say ready to submit?',
    text: `After ${maxDurationPerSession} minutes of clinical engagement and a session length of 7 days, there may be enough clinical engagement to submit.`,
    link: BH_TOOLTIP_ARTICLE_LINK,
  };

  const engagementTooltip = {
    title: 'Why does this say may need engagement?',
    text: `We recommend at least ${minDurationPerSession.toString()} minutes of clinical engagement and a session length of 7 days to submit a note.`,
    link: BH_TOOLTIP_ARTICLE_LINK,
  };

  const reviewTooltip = {
    title: 'Why does this say ready for review?',
    text: `After ${minDurationPerSession.toString()} minutes of clinical engagement and a session length of 7 days, there may be enough clinical engagement to submit.`,
    link: BH_TOOLTIP_ARTICLE_LINK,
  };

  const durationCalculationTooltip = {
    title: 'How are minutes of clinical engagement calculated for messaging sessions?',
    text: 'Minutes are calculated at 40 words per minute for texting, 150 words per PDF file, and message duration for audio or video messages.',
  };

  const submitNowReviewTooltip = {
    title: 'Should I submit now?',
    text: "If your client's engagement has paused or lulled, then consider submitting. If your client is actively engaging, then consider waiting to submit a higher-engagement CPT code. Here's our guidance:",
    bulletPoints,
  };

  const submitNowReadyToSubmitTooltip = {
    title: 'Should I submit now?',
    text: "Yes, this session qualifies for our highest-engagement CPT code. Here's our guidance:",
    bulletPoints,
  };

  const shouldSubmitThisSessionTooltip = {
    title: 'Should I submit this session?',
    text: `We recommend waiting for at least 7 days and ${minDurationPerSession} minutes of clinical engagement to submit a note.`,
    link: BH_TOOLTIP_ARTICLE_LINK,
  };

  if (submissionStatus === 'tooSoonToSubmit') {
    return [shouldSubmitThisSessionTooltip, durationCalculationTooltip];
  }

  if (submissionStatus === 'needsEngagement') {
    return [engagementTooltip, durationCalculationTooltip];
  }

  if (submissionStatus === 'readyForReview') {
    return [reviewTooltip, durationCalculationTooltip, submitNowReviewTooltip];
  }

  return [defaultTooltip, durationCalculationTooltip, submitNowReadyToSubmitTooltip];
};

export const getBHTooltipText = ({
  submissionStatus,
  minWordsPerSession = 640,
  maxWordsPerSession = 2100,
  sessionServices,
}: {
  submissionStatus: SessionSubmissionStatus | null;
  minWordsPerSession?: number;
  maxWordsPerSession?: number;
  sessionServices?: SessionService[];
}): Array<TooltipText> => {
  // TODO: change link
  const bulletPoints = getSessionServiceBulletPoints(sessionServices);
  const defaultTooltip = {
    title: 'Why does this say ready to submit?',
    text: `At ${maxWordsPerSession.toLocaleString()} words, there is likely enough clinical engagement and a session length of 7 days to submit a note.`,
    link: BH_TOOLTIP_ARTICLE_LINK,
  };

  const engagementTooltip = {
    title: 'Why does this say may need engagement?',
    text: `We recommend at least ${minWordsPerSession.toLocaleString()} words of clinical engagement and a session length of 7 days to submit a note.`,
    link: BH_TOOLTIP_ARTICLE_LINK,
  };

  const reviewTooltip = {
    title: 'Why does this say ready for review?',
    text: `After ${minWordsPerSession.toLocaleString()} words and 7 days, there may be enough clinical engagement to submit a note.`,
    link: BH_TOOLTIP_ARTICLE_LINK,
  };

  const wordCountTooltip = {
    title: 'What does this word count include?',
    text: 'This word count includes all words sent by you and your client through chat, audio, and video.',
  };

  const submitNowReviewTooltip = {
    title: 'Should I submit now?',
    text: "If your client's engagement has paused or lulled, then consider submitting. If your client is actively engaging, then consider waiting to submit a higher-engagement CPT code. Here's our guidance:",
    bulletPoints,
  };

  const submitNowReadyToSubmitTooltip = {
    title: 'Should I submit now?',
    text: "Yes, this session qualifies for our highest-engagement CPT code. Here's our guidance:",
    bulletPoints,
  };

  const shouldSubmitThisSessionTooltip = {
    title: 'Should I submit this session?',
    text: 'We recommend waiting for at least 7 days and 640 words of clinical engagement to submit a note.',
    link: BH_TOOLTIP_ARTICLE_LINK,
  };

  if (submissionStatus === 'tooSoonToSubmit') {
    return [shouldSubmitThisSessionTooltip, wordCountTooltip];
  }

  if (submissionStatus === 'needsEngagement') {
    return [engagementTooltip, wordCountTooltip];
  }

  if (submissionStatus === 'readyForReview') {
    return [reviewTooltip, submitNowReviewTooltip, wordCountTooltip];
  }

  return [defaultTooltip, submitNowReadyToSubmitTooltip, wordCountTooltip];
};

export const getDTEOrB2CTooltipText = (
  submissionStatus: SessionSubmissionStatus | null,
  isDTE?: boolean
): Array<TooltipText> => {
  if (submissionStatus === 'readyToSubmit') {
    return [
      {
        title: 'Why does this say ready to submit?',
        text: `It's been at least 7 days since you've submitted a note. We recommend submitting progress notes for ${
          isDTE ? 'DTE' : 'self-pay'
        } messaging sessions every 7 days, based on your clinical judgment, to maintain up-to-date medical records.`,
        link: isDTE ? DTE_TOOLTIP_ARTICLE_LINK : SELF_PAY_TOOLTIP_ARTICLE_LINK,
      },
    ];
  }
  if (submissionStatus === 'readyForReview') {
    return [
      {
        title: 'Why does this say ready for review?',
        text: `It's been at least 7 days since you've submitted a note. If there is enough clinical engagement, we recommend submitting progress notes for ${
          isDTE ? 'DTE' : 'self-pay'
        } messaging sessions every 7 days to maintain up-to-date medical records.`,
        link: isDTE ? DTE_TOOLTIP_ARTICLE_LINK : SELF_PAY_TOOLTIP_ARTICLE_LINK,
      },
    ];
  }
  return [];
};

export const getEAPTooltipText = (
  submissionStatus: SessionSubmissionStatus | null
): Array<TooltipText> => {
  // TODO: change link
  if (submissionStatus === 'reopenedAndReadyForReview') {
    return [
      {
        title: 'Why does this say reopened and ready for review?',
        text: 'You reopened an EAP messaging session that previously ended. All EAP messaging sessions end after 7 days regardless of the client’s level of engagement. Please wrap up the session and submit the note.',
      },
      {
        title: 'Should I submit a note for a session with little or no engagement?',
        text: 'Yes. If the session has low engagement, then write that in the summary section of the progress note and submit.',
        link: EAP_TOOLTIP_ARTICLE_LINK,
      },
    ];
  }
  if (submissionStatus === 'readyToSubmit') {
    return [
      {
        title: 'Why does this say ready to submit?',
        text: 'An EAP messaging session ends after 7 days. All EAP clients understand that one session equals 7 days of access to messaging therapy and that they will be charged regardless of their level of engagement.',
      },
      {
        title: 'Should I submit a note for a session with little or no engagement?',
        text: 'Yes. If the session has low engagement, then write that in the summary section of the progress note and submit.',
        link: EAP_TOOLTIP_ARTICLE_LINK,
      },
    ];
  }
  return [];
};

export const getShouldDisplayStatusAndTooltip = ({
  sessionModality,
  businessLine,
  submissionStatus,
}): {
  shouldDisplayStatus: boolean;
  shouldDisplayTooltip: boolean;
} => {
  let shouldDisplayStatus = false;
  let shouldDisplayTooltip = false;

  if (sessionModality === 'live') {
    shouldDisplayStatus = true;
  } else {
    if (businessLine?.isB2C || businessLine?.isDTE) {
      shouldDisplayStatus = ['readyToSubmit', 'readyForReview'].includes(submissionStatus);
      shouldDisplayTooltip = shouldDisplayStatus;
    }
    if (businessLine?.isEAP) {
      shouldDisplayStatus =
        submissionStatus === 'readyToSubmit' || submissionStatus === 'reopenedAndReadyForReview';
      shouldDisplayTooltip = shouldDisplayStatus;
    }
    if (businessLine?.isBH) {
      shouldDisplayTooltip = true;
      shouldDisplayStatus = true;
    }
  }

  if (['noShow', 'lateCancellation'].includes(submissionStatus)) {
    shouldDisplayTooltip = false;
  }

  return {
    shouldDisplayStatus,
    shouldDisplayTooltip,
  };
};

export const getWordCount = (value: string) =>
  value.split(/\s+/).filter((word) => word !== '').length;

export const buildPostActionURL = ({ draftNoteID, search }) =>
  `progress-notes/${draftNoteID}/edit${search}`;

export const getNoteSummaryItemsColor = (isDisabled, isHovering) => {
  if (isDisabled) {
    return COLORS.softGray;
  }
  if (isHovering) {
    return COLORS.darkTeal;
  }
  return COLORS.accessibilityGreenDark;
};

export const getDischargeView = (notesList: NoteItem[] | undefined) => {
  const dischargeDraft = notesList?.find(
    (it: NoteItem) => it.status === 'draft' && it.type === 'discharge'
  );
  if (dischargeDraft) {
    return `discharge-notes/${dischargeDraft.noteID}/edit`;
  }
  return 'discharge-notes/new';
};
export const progressNoteTabs = Object.values(ProgressNoteTabs);

export const currentSubstanceUseNotes: Array<SubstanceUseNoteField> = [
  { label: 'Cigarettes', fieldName: 'currentCigarettesUseNotes' },
  { label: 'Vaping', fieldName: 'currentVapingUseNotes' },
  { label: 'Alcohol', fieldName: 'currentAlcoholUseNotes' },
  { label: 'Marijuana', fieldName: 'currentMarijuanaUseNotes' },
  { label: 'Stimulants', fieldName: 'currentStimulantsUseNotes' },
  { label: 'Cocaine', fieldName: 'currentCocaineUseNotes' },
  { label: 'Heroin', fieldName: 'currentHeroinUseNotes' },
  {
    label: 'Benzodiazepines (Xanax, Ativan, Valium, etc)',
    fieldName: 'currentBenzodiazepinesUseNotes',
  },
  { label: 'Opioids/painkillers', fieldName: 'currentOpioidsUseNotes' },
  { label: 'Other substances', fieldName: 'currentOtherSubstanceUseNotes' },
];

export const getFormattedOtherAssessmentNotesFromV1 = (
  riskAssessment: ProgressNoteRiskAssessmentCreationAttributes
): string => {
  let value = '';
  if (riskAssessment.currentHomicidalIdeationNotes) {
    value = value.concat(
      `Homicidal assessment: ${riskAssessment.currentHomicidalIdeationNotes}\n\n`
    );
  }
  if (riskAssessment.currentPsychosisNotes) {
    value = value.concat(`Psychosis assessment: ${riskAssessment.currentPsychosisNotes}\n\n`);
  }
  if (riskAssessment.currentSuicidalIdeationNotes) {
    value = value.concat(`Suicidal assessment: ${riskAssessment.currentSuicidalIdeationNotes}\n\n`);
  }
  return value;
};

export const getFormattedOtherSubstanceUseNotesFromV1 = (
  substanceUse: ProgressNoteSubstanceUseCreationAttributes
): string => {
  let value = '';
  currentSubstanceUseNotes.forEach(({ label, fieldName }) => {
    if (substanceUse[fieldName]) {
      value = value.concat(`${label}: ${substanceUse[fieldName]}\n\n`);
    }
  });
  return value;
};

export const parseTime = (time: string): { hours: number; minutes: number } => {
  const parsedTime = moment(time, 'hh:mm A', true);

  if (!parsedTime.isValid()) {
    throw new Error(`Invalid time param: ${time}`);
  }

  const hours = Number(parsedTime.format('HH'));
  const minutes = Number(parsedTime.format('mm'));

  return { hours, minutes };
};
