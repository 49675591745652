import { styled, TouchableView } from '@talkspace/react-toolkit';

const TableRow = styled(TouchableView)<{ isHovering?: boolean }>(
  ({ theme: { colorRoles, spacing }, isHovering }) => {
    return {
      padding: `${spacing('space100', true)} ${spacing('space150', true)}`,
      borderBottom: `1px solid ${colorRoles.borders.borderDefault}`,
      justifyContent: 'space-between',
      background: isHovering ? colorRoles.surfaces.surfaceInteractiveHovered : 'inherit',
      cursor: 'pointer',
      flexDirection: 'row',
      position: 'relative',
      '&:last-child': {
        border: 'none',
      },
    };
  }
);

export default TableRow;
