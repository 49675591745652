import { useState } from 'react';
import {
  TextDS,
  View,
  TouchableView,
  useWindowWidthState,
  Image,
  AnimatedSwipeDownModal,
  useEmotionTheme,
  ColorRolesVersion,
} from '@talkspace/react-toolkit';
import tooltipImage from '../assets/tooltipImg.png';
import styled from '@/core/styled';

const Text = styled(TouchableView)(({ theme: { colorRoles, spacing } }) => {
  return {
    textDecoration: 'underline dotted',
    cursor: 'pointer',
    display: 'inline-block',
  };
});

const TooltipContainer = styled(View)(({ theme: { colors } }) => {
  return {
    display: 'flex',
    width: 335,
    height: 'max-content',
    padding: '15px 20px',
    flexDirection: 'column',
    borderRadius: 8,
    boxShadow: '0px 7px 16px 0px rgba(0, 29, 47, 0.39)',
    position: 'absolute',
    zIndex: 10,
    background: colors.white,
    left: 200,
  };
});

const getTooltipCloseButtonStyle = (colors: ColorRolesVersion) => {
  return {
    background: colors.button.brandPrimarySurfaceDefault,
    color: colors.button.brandPrimaryTextDefault,
    minWidth: 104,
    padding: '18px 24px',
    borderRadius: 12,
    fontSize: 16,
  };
};

const CalendarSyncTooltip = ({ text }: { text: string }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const { isMobile } = useWindowWidthState();
  const { colorRoles } = useEmotionTheme();

  const closeTextStyle = getTooltipCloseButtonStyle(colorRoles);

  const getContent = () => (
    <>
      <Image src={tooltipImage} alt="tooltip image" style={{ marginBottom: 12 }} />
      <TextDS style={{ marginBottom: 4 }} variant="headingMd">
        How sessions are displayed
      </TextDS>
      <TextDS variant="bodySm">
        Sessions will display in your external calendar with a Talkspace label, the Room ID (e.g,
        86733), and your client’s initials.{' '}
      </TextDS>
    </>
  );

  return (
    <>
      <Text
        onMouseEnter={() => !isMobile && setIsTooltipOpen(true)}
        onMouseLeave={() => !isMobile && setIsTooltipOpen(false)}
        onPress={() => setIsTooltipOpen(true)}
      >
        {text}
      </Text>
      {isMobile && (
        <AnimatedSwipeDownModal
          closeModalText="close"
          closeTextStyle={closeTextStyle}
          isOpen={isTooltipOpen}
          onClose={() => setIsTooltipOpen(false)}
        >
          {getContent()}
        </AnimatedSwipeDownModal>
      )}
      {isTooltipOpen && !isMobile && <TooltipContainer>{getContent()}</TooltipContainer>}
    </>
  );
};

export default CalendarSyncTooltip;
