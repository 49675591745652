import { useMemo } from 'react';
import { FloatingMenuSimple } from '@talkspace/react-toolkit';
import { Sliders } from '@talkspace/react-toolkit/src/designSystems/icons';

import useQuerySupervisedNotes, {
  SupervisedNote,
} from '../../../hooks/notes/useQuerySupervisedNotes';
import { ALL_ASSOCIATES_FILTER } from './constants';
import { useSupervisedNotesTable } from './TableContext';
import { getUserData } from '../../../utils/token';

const getFilterOptions = (
  associates: SupervisedNote['associate'][],
  onChange: (selectedAssociatedID: string) => void
) => [
  {
    callback: () => onChange(ALL_ASSOCIATES_FILTER),
    optionLabel: 'All',
    selectedLabel: 'Filter: All',
    optionValue: ALL_ASSOCIATES_FILTER,
  },
  ...associates.map((associate) => {
    return {
      callback: () => onChange(associate.id.toString()),
      optionLabel: associate.name,
      selectedLabel: `Filter: ${associate.name}`,
      optionValue: associate.id.toString(),
    };
  }),
];

export default function FilterMenu() {
  const therapistID = getUserData().id;

  const { data: supervisedNotes = [] } = useQuerySupervisedNotes(therapistID);

  const { tableState, setTableState } = useSupervisedNotesTable();

  const handleFilterChange = (value: string) => {
    setTableState({ filterByUserID: value });
  };

  const associates = useMemo(
    () =>
      [...new Set(supervisedNotes.map((it) => JSON.stringify(it.associate)))].map((it) =>
        JSON.parse(it)
      ),
    [supervisedNotes]
  );

  const options = getFilterOptions(associates, handleFilterChange);

  return (
    <FloatingMenuSimple
      initialValue={tableState.filterByUserID}
      options={options}
      Icon={() => <Sliders colorType="brand" />}
      hasCaretDown={false}
      buttonVariant="secondary"
      hideSelected={false}
      dataQa="filtersMenu"
      buttonStyle={{ height: 40 }}
    />
  );
}
