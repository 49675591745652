import {
  View,
  Standard,
  Small,
  TouchableView,
  FloatingMenu,
  ClipBoardIcon,
  Clipboard,
  IDMenuBox,
  useEmotionTheme,
  withHover,
  CheckMark,
  EmotionStyle,
  WithHoverProps,
  useWindowWidthState,
  AnimatedSwipeDownModal,
  styled,
} from '@talkspace/react-toolkit';
import { ReactNode, useEffect, useState } from 'react';

const IDText = styled(Small)(() => {
  return {
    marginRight: 8,
    marginTop: 2,
  };
});

const CopyClipBoardText = styled(Standard)<{ isHovering?: boolean }>(
  ({ theme: { colors }, isHovering = false }) => {
    return {
      color: isHovering ? colors.permaBlueStone : colors.accessibilityGreenDark,
      marginLeft: 6,
    };
  }
);

const FloatingMenuWrapper = {
  width: 240,
  height: 164,
  paddingLeft: 23,
  left: 35,
  top: -50,
  paddingTop: 18,
  zIndex: 3,
};

interface ClipBoardContainerProps extends WithHoverProps {
  onPress: () => void;
}

const ClipBoardContainer = withHover(
  styled(TouchableView)<ClipBoardContainerProps>(() => {
    return {
      marginTop: 16,
    };
  })
);

const IDBoxContainer = styled(TouchableView)<{ isHovering: boolean }>(({ isHovering = false }) => {
  return {
    display: 'flex',
    visibility: isHovering ? 'visible' : 'hidden',
  };
});

interface IDViewProps {
  pseudonym: string;
  roomID: number;
  userID: number;
  isHovering?: boolean;
  floatingMenuStyle?: EmotionStyle;
  customIcon?: ReactNode;
  shouldDisplayIcon?: boolean;
  isMenuOpen?: boolean;
  handleCloseMenu?: () => void;
}
const IDView = ({
  pseudonym,
  roomID,
  userID,
  isHovering = false,
  floatingMenuStyle,
  customIcon,
  shouldDisplayIcon = true,
  isMenuOpen,
  handleCloseMenu,
}: IDViewProps) => {
  const { isMobile } = useWindowWidthState();
  const [hideMenu, setHideMenu] = useState<boolean>(true);
  const [isClipBoardViewHovering, setIsClipBoardViewHovering] = useState<boolean>(false);
  const [hasTextOnClipBoard, setHasTextOnClipBoard] = useState<boolean>(false);

  const { colors } = useEmotionTheme();

  useEffect(() => {
    if (isMenuOpen !== undefined) {
      setHideMenu(!isMenuOpen);
    }
  }, [isMenuOpen]);

  const handleCopyText = () => {
    Clipboard.setString(`Client ID - Nickname: ${pseudonym}, Room: ${roomID}, User: ${userID}`);
    setHasTextOnClipBoard(true);
  };

  const content = (
    <>
      <View style={{ marginBottom: 8 }}>
        <Standard variant="standardBlack">Client ID</Standard>
      </View>
      <IDText>{`Nickname: ${pseudonym}`}</IDText>
      <IDText>{`Room: ${roomID}`}</IDText>
      <IDText>{`User: ${userID}`}</IDText>
      <ClipBoardContainer
        onPress={handleCopyText}
        onHoverChange={(hovered) => {
          setIsClipBoardViewHovering(hovered);
        }}
      >
        <View row>
          {hasTextOnClipBoard ? (
            <CheckMark style={{ marginTop: 4 }} color={colors.a11yVidaLocaGreen} />
          ) : (
            <ClipBoardIcon {...(isClipBoardViewHovering && { color: colors.permaBlueStone })} />
          )}
          <CopyClipBoardText isHovering={isClipBoardViewHovering || hasTextOnClipBoard}>
            {hasTextOnClipBoard ? `Copied to clipboard!` : `Copy to clipboard`}
          </CopyClipBoardText>
        </View>
      </ClipBoardContainer>
    </>
  );

  return (
    <>
      {shouldDisplayIcon && (
        <IDBoxContainer isHovering={isHovering} onPress={() => setHideMenu(false)}>
          {customIcon ? <>{customIcon}</> : <IDMenuBox isMenuShowing={!hideMenu} />}
        </IDBoxContainer>
      )}
      {!isMobile ? (
        <FloatingMenu
          hide={hideMenu}
          onBackdropPress={() => {
            setHideMenu(true);
            setHasTextOnClipBoard(false);
            handleCloseMenu && handleCloseMenu();
          }}
          style={{ ...FloatingMenuWrapper, ...floatingMenuStyle }}
        >
          {content}
        </FloatingMenu>
      ) : (
        <AnimatedSwipeDownModal
          isOpen={!hideMenu}
          closeModalText="cancel"
          onClose={() => {
            setHideMenu(true);
            handleCloseMenu && handleCloseMenu();
          }}
        >
          {content}
        </AnimatedSwipeDownModal>
      )}
    </>
  );
};

export default IDView;
