import { ChangeEvent, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
  useEmotionTheme,
  styled,
  Standard,
  TouchableView,
  View,
  OptionType,
  PlusIcon,
  formatSelectOptionsFromConfig,
} from '@talkspace/react-toolkit';
import { states, countries } from '@talkspace/configs';
import { components as DefaultComponents, OptionProps } from 'react-select';
import useQueryProgressNoteHelperData from 'hooks/notes/useQueryProgressNoteHelperData';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useRouteMatch, useLocation } from '@/core/routerLib';
import {
  useProgressNoteFormActions,
  useProgressNoteFormState,
} from '../context/TherapistProgressNoteFormContext';
import { ProgressNoteSessionAttendeeCreationAttributes, SessionService } from '../../types';
import { Section } from '../context/types';
import {
  getSelectStyleV2,
  updateObjectInArray,
  BooleanDropdown,
  filterSessionService,
  DEFAULT_RELATIONSHIP_TO_CLIENT,
} from '../../utils';
import trackEvent from '../../../../../../modules/analytics/trackEvent';
import ContactNameWithRelationship from './ContactNameWithRelationship';
import useQuerySessionServices from '../../../../../../hooks/useQuerySessionServicesV2';
import useQueryProgressNote from '../../../../../../hooks/notes/useQueryProgressNote';
import { Theme } from '../../../../../../core/styled';
import SelectContainer from '../containers/SelectContainer';
import useDidUpdateEffect from '../../../../../../hooks/useDidUpdateEffect';
import {
  UnsubmittedLiveSession,
  UnsubmittedMessagingSession,
} from '../../../../../../hooks/notes/useQueryUnsubmittedSessions';
import IndentedView from '../../IndentedView';

interface SessionServicesOptionType extends OptionType {
  recommended: boolean;
  recommendedText?: string;
  isSelectable: boolean;
}

const generateSessionServiceOptions = (
  sessionServices: Array<SessionService> | undefined
): Array<SessionServicesOptionType> => {
  if (!sessionServices) {
    return [];
  }
  return sessionServices.map((it) => {
    return {
      value: it.id.toString(),
      label: it.name,
      recommended: it.recommended,
      recommendedText: it.recommendedText || undefined,
      title: it.sessionServiceTitle,
      subtitle: it.sessionServiceSubtitle?.replace(/-/g, '–'),
      isSelectable: it.isSelectable,
    };
  });
};

const RecommendedText = styled(Standard)(({ theme: { colors } }) => {
  return {
    fontWeight: 700,
    letterSpacing: 0.3,
    paddingBottom: 5,
    color: colors.pantoneBlue,
  };
});

const getCPTOptionStyle = (colors: Theme['colors'], isSelected: boolean) => {
  return {
    padding: isSelected ? 9 : 11,
    '&:hover': {
      backgroundColor: colors.aqua,
      color: colors.white,
    },
    backgroundColor: isSelected ? colors.whiteLilac : colors.white,
    width: isSelected ? 340 : 350,
    margin: isSelected && '6px auto 6px auto',
    borderRadius: isSelected && 5,
    minHeight: 48,
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
    justifyContent: 'center',
    lineHeight: '21px',
  };
};

const sortSessionAttendees = (
  sessionAttendees: ProgressNoteSessionAttendeeCreationAttributes[]
) => {
  const identifiedClients = sessionAttendees.filter(
    (attendee) => attendee.relationshipToClient === DEFAULT_RELATIONSHIP_TO_CLIENT
  );
  const restOfAttendees = sessionAttendees.filter(
    (attendee) => attendee.relationshipToClient !== DEFAULT_RELATIONSHIP_TO_CLIENT
  );
  return [...identifiedClients, ...restOfAttendees];
};

const SelectOption = (props: OptionProps<OptionType, false>) => {
  const { data, isSelected } = props;
  const { colors } = useEmotionTheme();
  const { accessUnsubmittedSessions: { inRoomUnsubmittedSessions } = {} } = useFlags();
  return (
    <DefaultComponents.Option {...props} getStyles={() => getCPTOptionStyle(colors, isSelected)}>
      <View>
        {data.recommended && <RecommendedText>{data.recommendedText}</RecommendedText>}
        {inRoomUnsubmittedSessions && data.title && data.subtitle ? (
          <View style={{ fontSize: 14 }}>
            <View style={{ fontWeight: 700 }}>{data.title}</View>
            <View>{data.subtitle}</View>
          </View>
        ) : (
          <View style={{ fontWeight: 400 }}>{data.label}</View>
        )}
      </View>
    </DefaultComponents.Option>
  );
};
interface StoreProps {
  clientUserID: number;
  nextSection: Section;
}

const SessionInformationSection: FunctionComponent<StoreProps> = ({
  clientUserID,
  nextSection,
}) => {
  const formState = useProgressNoteFormState();
  const {
    formMode,
    sessionAttendees,
    sessionServiceID,
    formSections,
    currentSection,
    isSaveAsDraftPressed,
    modalityID,
    submissionStatus,
    unsubmittedSession,
    sessionSummary,
    serviceStartDate,
    formVariant,
  } = formState;

  const {
    clientState,
    clientCountry,
    clientAppropriateForTelehealth,
    clientRequestedLanguageAssistance,
    languageAssistanceProvided,
  } = sessionSummary;
  const { setFormState } = useProgressNoteFormActions();
  const [attendeeNameErrors, setAttendeeNameErrors] = useState<Array<boolean>>([]);
  const [relationshipToClientErrors, setRelationshipToClientErrors] = useState<Array<boolean>>([]);
  const [sessionServiceError, setSessionServiceError] = useState(false);
  const [isOtherAttendees, setIsOtherAttendees] = useState<boolean>(false);
  const { colors } = useEmotionTheme();
  const match = useRouteMatch<{ roomID: string; noteID?: string }>();
  const userID = formState.clientUserID || clientUserID;
  const { data: progressNoteData } = useQueryProgressNote(userID, match.params.noteID);
  const noteRoomID = progressNoteData?.roomID?.toString() || match.params.roomID;
  const { data: helperData } = useQueryProgressNoteHelperData(noteRoomID, true);
  const sortedSessionAttendees = useMemo(
    () => sortSessionAttendees(sessionAttendees),
    [sessionAttendees]
  );
  const { newProgressNoteFields } = useFlags();

  const location = useLocation<{ outcomeMeasure: { id: number } }>();

  const asyncSessionID =
    (unsubmittedSession as UnsubmittedMessagingSession)?.asyncSessionID ||
    progressNoteData?.asyncSessionID;

  const liveCallID =
    (unsubmittedSession as UnsubmittedLiveSession)?.liveCallID || progressNoteData?.liveCallID;

  const newProgressNoteFieldsOn =
    newProgressNoteFields &&
    !isNaN(Date.parse(newProgressNoteFields)) &&
    serviceStartDate &&
    serviceStartDate >= new Date(newProgressNoteFields);

  const { data: sessionServices = [] } = useQuerySessionServices({
    roomID: noteRoomID,
    clientUserID: userID,
    asyncSessionID,
    liveCallID,
    therapistID: progressNoteData?.createdByUser.id,
    isSupervisor: formVariant === 'supervisor',
  });

  const memoizedSessionServices = useMemo(() => sessionServices, [sessionServices]);

  const unsubmittedSessionID = new URLSearchParams(location.search).get('id');

  const hasSessionService = unsubmittedSession?.accountType === 'bh' || sessionServiceID;

  useEffect(() => {
    setIsOtherAttendees(
      sessionAttendees?.some((it) => it?.relationshipToClient !== DEFAULT_RELATIONSHIP_TO_CLIENT)
    );
  }, [formMode, sessionAttendees]);

  const filteredSessionServices = useMemo(() => {
    if (hasSessionService) {
      return generateSessionServiceOptions(
        filterSessionService(modalityID, memoizedSessionServices)
      );
    }
    return [];
  }, [hasSessionService, memoizedSessionServices, modalityID]);

  useEffect(() => {
    if (hasSessionService) {
      if (!progressNoteData?.sessionServiceID && filteredSessionServices.length) {
        const recommendedSession = filteredSessionServices.find((item) => item.recommended);
        if (recommendedSession) {
          setFormState({ sessionServiceID: Number(recommendedSession.value) });
        } else {
          setFormState({ sessionServiceID: null });
        }
      }
    }
  }, [
    hasSessionService,
    progressNoteData?.sessionServiceID,
    filteredSessionServices,
    setFormState,
  ]);

  useEffect(() => {
    if (filteredSessionServices.length) {
      trackEvent(
        'addNoteInfo',
        {
          actionName: 'progressNotesInteraction',
        },
        {
          sessionServices,
          filteredServiceSessions: filteredSessionServices,
          roomID: match.params.roomID,
          unsubmittedSessionID,
        }
      );
    }
  }, [filteredSessionServices, match.params.roomID, sessionServices, unsubmittedSessionID]);

  const validateAttendees = (): boolean => {
    const newAttendeeNameErrors = sessionAttendees.map((it) => !it.attendeeName);
    const newRelationshipToClientErrors = sessionAttendees.map((it) => !it.relationshipToClient);
    if (
      isOtherAttendees &&
      (newAttendeeNameErrors.some((it) => it) || newRelationshipToClientErrors.some((it) => it))
    ) {
      if (!isSaveAsDraftPressed) {
        setAttendeeNameErrors(newAttendeeNameErrors);
        setRelationshipToClientErrors(newRelationshipToClientErrors);
      }
      return true;
    }
    return false;
  };

  const validateSessionService = (): boolean => {
    if (hasSessionService && !sessionServiceID) {
      if (!isSaveAsDraftPressed) {
        setSessionServiceError(true);
      }
      return true;
    }
    return false;
  };

  const handleConfirmPress = () => {
    const isError = validateAttendees() || validateSessionService();
    const completed = !isError;

    if (isError && !isSaveAsDraftPressed) {
      setFormState({
        showGlobalError: true,
        formSections: {
          ...formSections,
          sessionInformation: {
            open: false,
            changed: true,
            completed: false,
          },
        },
      });
      return;
    }

    let dataToSend = [...sessionAttendees];
    if (!isOtherAttendees) {
      dataToSend = sessionAttendees.filter(
        (item) => item?.relationshipToClient === DEFAULT_RELATIONSHIP_TO_CLIENT
      );
    }

    setFormState({
      sessionAttendees: dataToSend,
      formSections: {
        ...formSections,
        sessionInformation: {
          open: false,
          changed: true,
          completed,
        },
        ...(formSections.diagnosis.completed
          ? {}
          : {
              diagnosis: {
                ...formSections.diagnosis,
                open: true,
              },
            }),
      },
    });
  };

  useDidUpdateEffect(() => {
    if (currentSection === 'sessionInformation') {
      setFormState({ currentSection: nextSection });
      handleConfirmPress();
    }
  }, [currentSection]);

  const stateOptions = useMemo(
    () =>
      [
        { value: undefined, label: 'Not provided' },
        { value: null, label: 'Outside of US' },
        ...formatSelectOptionsFromConfig(states),
      ] as OptionType<string>[],
    []
  );

  const countriesOptions = useMemo(
    () =>
      [
        { value: undefined, label: 'Not provided' },
        ...formatSelectOptionsFromConfig(countries),
      ].filter((it) => it.value !== 'US') as OptionType<string>[],
    []
  );

  const appropriateForTelehealthOptions = useMemo(
    () => [
      { value: true, label: 'Yes' },
      { value: false, label: 'No, referred to alternative level of care' },
    ],
    []
  );

  const markSectionAsIncomplete = () => {
    if (formSections.sessionInformation.completed) {
      setFormState({
        formSections: {
          ...formSections,
          sessionInformation: {
            open: formSections.sessionInformation.open,
            changed: true,
            completed: false,
          },
        },
      });
    }
  };

  const handleTextFieldChange = (
    index: number,
    field: keyof ProgressNoteSessionAttendeeCreationAttributes,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    markSectionAsIncomplete();

    if (field === 'attendeeName' && attendeeNameErrors[index]) {
      const copy = [...attendeeNameErrors];
      copy[index] = false;
      setAttendeeNameErrors(copy);
    }

    if (field === 'relationshipToClient' && relationshipToClientErrors[index]) {
      const copy = [...relationshipToClientErrors];
      copy[index] = false;
      setRelationshipToClientErrors(copy);
    }

    const updatedSessionAttendees =
      updateObjectInArray<ProgressNoteSessionAttendeeCreationAttributes>(sessionAttendees, index, {
        [field]: event.target.value,
      });
    setFormState({
      sessionAttendees: updatedSessionAttendees,
    });
  };

  const handleAddAnotherPersonPress = () => {
    markSectionAsIncomplete();
    setFormState({
      sessionAttendees: [
        ...sessionAttendees,
        { attendeeName: null, relationshipToClient: null, id: Date.now() },
      ],
    });
  };

  const shouldCloseAttendeeList = () =>
    sessionAttendees.length === 1 ||
    (sessionAttendees.length === 2 && helperData?.identifiedClient);

  const handleRemovePersonPress = (id: number) => {
    const filteredAttendees = sessionAttendees.filter((item) => item.id !== id);
    setFormState({ sessionAttendees: filteredAttendees });
    if (shouldCloseAttendeeList()) {
      setIsOtherAttendees(false);
    }
    markSectionAsIncomplete();
  };

  const handleCPTCodeChange = (option: { value: string; label: string } | null) => {
    markSectionAsIncomplete();
    setSessionServiceError(false);
    const newValue = option ? Number(option.value) : null;
    setFormState({ sessionServiceID: newValue });

    trackEvent(
      'addNoteInfo',
      {
        actionName: 'progressNotesInteraction',
      },
      {
        action: 'Provider adds information to progress note',
        noteStatus: submissionStatus,
        sessionServiceID: newValue,
        cptCodeLabel: option?.label,
        roomID: match.params.roomID,
        unsubmittedSessionID,
      }
    );
  };

  const otherAttendeeSelect = (value: boolean) => {
    setIsOtherAttendees(value);
    if (value && sessionAttendees.length <= 1) {
      setFormState({
        sessionAttendees: [
          ...sessionAttendees,
          { attendeeName: null, relationshipToClient: null, id: Date.now() },
        ],
      });
    }
    if (!value) {
      setFormState({
        sessionAttendees: helperData?.identifiedClient
          ? [
              {
                attendeeName: helperData.identifiedClient,
                relationshipToClient: DEFAULT_RELATIONSHIP_TO_CLIENT,
                id: 0,
              },
            ]
          : [],
      });
    }
  };

  const clientStateSelect = (value: keyof typeof states | null) => {
    const clientCountrySelected = value ? 'US' : sessionSummary.clientCountry;
    setFormState({
      sessionSummary: {
        ...sessionSummary,
        clientState: value,
        clientCountry: clientCountrySelected,
      },
    });
  };

  const clientCountrySelect = (value: keyof typeof countries | null) => {
    setFormState({ sessionSummary: { ...sessionSummary, clientCountry: value } });
  };

  const clientAppropriateForTelehealthSelect = (value: boolean | null) => {
    setFormState({ sessionSummary: { ...sessionSummary, clientAppropriateForTelehealth: value } });
  };

  const clientRequestedLanguageAssistanceSelect = (value: boolean | null) => {
    setFormState({
      sessionSummary: {
        ...sessionSummary,
        clientRequestedLanguageAssistance: value,
        languageAssistanceProvided: value ? true : null,
      },
    });
  };

  const languageAssistanceProvidedSelect = (value: boolean | null) => {
    setFormState({
      sessionSummary: { ...sessionSummary, languageAssistanceProvided: value },
    });
  };

  return (
    <>
      {hasSessionService && (
        <SelectContainer
          key={`key__${sessionServiceID}`}
          value={
            filteredSessionServices.find((it) => it.value === sessionServiceID?.toString()) ||
            undefined
          }
          placeholder="CPT code"
          components={{ Option: SelectOption }}
          title="CPT code:"
          handleChange={handleCPTCodeChange as any}
          options={filteredSessionServices.filter((it) => it.isSelectable)}
          styles={getSelectStyleV2({
            colors,
            isError: sessionServiceError,
            disableCapitalized: true,
          })}
          isDisabled={formMode === 'view' || progressNoteData?.status === 'submitted'}
          flex
          dataQa="cptCodeDropdown"
        />
      )}
      <SelectContainer
        value={BooleanDropdown.find((it) => it.value === isOtherAttendees) as any}
        title="Attendees other than identified client?"
        handleChange={((option) => otherAttendeeSelect(option?.value)) as any}
        options={BooleanDropdown as any}
        styles={getSelectStyleV2({ colors })}
        isDisabled={formMode === 'view'}
        dataTestID="attendeesSelectId"
        dataQa="otherAttendeesDropdown"
      />
      {isOtherAttendees && (
        <View style={{ marginBottom: 20 }}>
          {sortedSessionAttendees.map(
            (attendee, index) =>
              attendee?.relationshipToClient !== DEFAULT_RELATIONSHIP_TO_CLIENT && (
                <ContactNameWithRelationship
                  key={attendee.id}
                  namePlaceholder="Name of other attendee"
                  isNameError={attendeeNameErrors[index]}
                  nameValue={attendee.attendeeName || ''}
                  onNameChange={(event) => handleTextFieldChange(index, 'attendeeName', event)}
                  disabled={formMode === 'view'}
                  isRelationshipError={relationshipToClientErrors[index]}
                  relationshipValue={attendee.relationshipToClient || ''}
                  onRelationshipChange={(event) =>
                    handleTextFieldChange(index, 'relationshipToClient', event)
                  }
                  onRemovePress={() => handleRemovePersonPress(attendee.id)}
                  index={index}
                />
              )
          )}
          {formMode !== 'view' && (
            <TouchableView style={{ marginTop: 14, alignSelf: 'flex-end' }}>
              <Standard
                variant="standardBoldAccessibilityGreen"
                onPress={handleAddAnotherPersonPress}
              >
                <PlusIcon /> Add another person
              </Standard>
            </TouchableView>
          )}
        </View>
      )}
      {newProgressNoteFieldsOn && (
        <>
          <SelectContainer
            value={stateOptions.find((it) => it.value === clientState) as any}
            title="Client location during session?"
            handleChange={((option) => clientStateSelect(option?.value)) as any}
            options={stateOptions}
            styles={getSelectStyleV2({ colors, minWidth: 130 })}
            isDisabled={formMode === 'view'}
            dataTestID="clientStateLocationSelectID"
          />
          {!clientState && (
            <IndentedView>
              <SelectContainer
                value={countriesOptions.find((it) => it.value === clientCountry) as any}
                title="Where outside the US?"
                handleChange={((option) => clientCountrySelect(option?.value)) as any}
                options={countriesOptions}
                styles={getSelectStyleV2({ colors, minWidth: 130 })}
                isDisabled={formMode === 'view'}
                dataTestID="clientCountryLocationSelectID"
              />
            </IndentedView>
          )}
          <SelectContainer
            value={
              appropriateForTelehealthOptions.find(
                (it) => it.value === clientAppropriateForTelehealth
              ) as any
            }
            title="Is client appropriate for telehealth?"
            handleChange={((option) => clientAppropriateForTelehealthSelect(option?.value)) as any}
            options={appropriateForTelehealthOptions as any}
            styles={getSelectStyleV2({ colors })}
            isDisabled={formMode === 'view'}
            dataTestID="isClientAppropriateForTelehealthID"
            tooltipText="Client appropriateness for telehealth refers to the suitability of a client for receiving healthcare services remotely based on their medical, psychological, technological, social and other situational needs"
          />
          <SelectContainer
            value={
              BooleanDropdown.find((it) => it.value === clientRequestedLanguageAssistance) as any
            }
            title="Client requested language assistance?"
            handleChange={
              ((option) => clientRequestedLanguageAssistanceSelect(option?.value)) as any
            }
            options={BooleanDropdown as any}
            styles={getSelectStyleV2({ colors })}
            isDisabled={formMode === 'view'}
            dataTestID="clientRequestedLanguageAssistanceID"
          />
          {clientRequestedLanguageAssistance && (
            <IndentedView>
              <SelectContainer
                value={BooleanDropdown.find((it) => it.value === languageAssistanceProvided) as any}
                title="Did you provide assistance?"
                handleChange={((option) => languageAssistanceProvidedSelect(option?.value)) as any}
                options={BooleanDropdown as any}
                styles={getSelectStyleV2({ colors })}
                isDisabled={formMode === 'view'}
                dataTestID="languageAssistanceProvidedID"
              />
            </IndentedView>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    clientUserID: state.room.clientUserID,
  };
};

export default connect(mapStateToProps)(SessionInformationSection);
