import { EmotionStyle, TextDS, useEmotionTheme, View } from '@talkspace/react-toolkit';
import { CircleExclamation } from '@talkspace/react-toolkit/src/designSystems/icons';

interface Props {
  text: string;
  isVisible: boolean;
  style?: EmotionStyle;
}

const ErrorText = ({ text, isVisible, style = {} }: Props) => {
  const { colorRoles } = useEmotionTheme();
  if (!isVisible) {
    return null;
  }
  return (
    <View row align="center" style={{ marginTop: 8, gap: 6, ...style }}>
      <CircleExclamation colorType="critical" />
      <TextDS variant="bodyXs" style={{ color: colorRoles.typography.textCriticalDefault }}>
        {text}
      </TextDS>
    </View>
  );
};

export default ErrorText;
