import { useMemo, useState } from 'react';
import { View } from '@talkspace/react-toolkit';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { AccountType } from 'ts-frontend/entities/Room';

import {
  useProgressNoteFormState,
  useProgressNoteFormActions,
} from '../../context/TherapistProgressNoteFormContext';
import { Section } from '../../context/types';
import MentalStatusExamQuestion from './MentalStatusExamQuestion';
import useDidUpdateEffect from '../../../../../../../hooks/useDidUpdateEffect';
import {
  defaultCategoryErrors,
  defaultDescriptionErrors,
  getQuestionsConfig,
  validateCategories,
  validateOtherDescriptions,
} from './utils';
import {
  MentalExamCategoryErrors,
  MentalExamDescriptionErrors,
  MentalStatusExamCategory,
  MentalStatusExamOtherDescription,
} from './types';

interface Props {
  nextSection: Section;
  accountType?: AccountType;
}

export default function MentalStatusExamSection({ nextSection, accountType }: Props) {
  const { diagnosticIntake: isIntakeNoteActive } = useFlags();

  const {
    mentalStatusExam,
    formMode,
    currentSection,
    formSections,
    modalityID,
    isSaveAsDraftPressed,
  } = useProgressNoteFormState();

  const { setFormState } = useProgressNoteFormActions();

  const [categoryErrors, setCategoryErrors] =
    useState<MentalExamCategoryErrors>(defaultCategoryErrors);

  const [descriptionErrors, setDescriptionErrors] =
    useState<MentalExamDescriptionErrors>(defaultDescriptionErrors);

  useDidUpdateEffect(() => {
    if (currentSection === 'therapyMSE') {
      setFormState({
        currentSection: nextSection,
        formSections: {
          ...formSections,
          mentalStatusExam: {
            open: false,
            changed: true,
            completed: true,
          },
        },
      });

      const shouldValidate = accountType === 'bh' && isIntakeNoteActive;

      if (!shouldValidate) {
        return;
      }

      const categoriesErrors = validateCategories(mentalStatusExam);
      const descriptionsError = validateOtherDescriptions(mentalStatusExam);

      const isError =
        Object.values(categoriesErrors).some((it) => it) ||
        Object.values(descriptionsError).some((it) => it);

      if (isError && !isSaveAsDraftPressed) {
        setCategoryErrors(categoriesErrors);
        setDescriptionErrors(descriptionsError);

        setFormState({ showGlobalError: true });
      }
    }
  }, [
    accountType,
    currentSection,
    isSaveAsDraftPressed,
    mentalStatusExam,
    formSections,
    setFormState,
    nextSection,
  ]);

  const questionsConfig = useMemo(() => getQuestionsConfig(modalityID), [modalityID]);

  const handleOptionChange = (field: MentalStatusExamCategory, option: string) => {
    if (!mentalStatusExam) {
      return;
    }

    setCategoryErrors({ ...categoryErrors, [field]: false });

    setFormState({
      showGlobalError: false,
      mentalStatusExam: { ...mentalStatusExam, [field]: option },
    });
  };

  const handleOtherDescriptionChange = (
    field: MentalStatusExamOtherDescription,
    newValue: string
  ) => {
    if (!mentalStatusExam) {
      return;
    }

    setDescriptionErrors({ ...descriptionErrors, [field]: false });

    setFormState({
      showGlobalError: false,
      mentalStatusExam: { ...mentalStatusExam, [field]: newValue },
    });
  };

  return (
    <View style={{ marginTop: 16 }}>
      {questionsConfig.map(({ key, title, options }, index) => (
        <MentalStatusExamQuestion
          key={key}
          title={`${title}:`}
          options={options}
          value={mentalStatusExam?.[key] || null}
          otherOption={mentalStatusExam?.[`${key}Description`] || null}
          onOptionChange={(option) => handleOptionChange(key, option.value)}
          onOtherOptionChange={(value) => handleOtherDescriptionChange(`${key}Description`, value)}
          otherOptionPlaceholder={`Describe ${title.toLowerCase()}`}
          disabled={formMode === 'view'}
          isCategoryError={categoryErrors[key]}
          isDescriptionError={descriptionErrors[`${key}Description`]}
          isLast={index === questionsConfig.length - 1}
        />
      ))}
    </View>
  );
}
