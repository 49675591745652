import { useQuery } from 'react-query';

import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';

export interface RoomPresentingProblem {
  expertiseID: number;
  id: number;
  therapistFacingText: string;
}

const fetchRoomPresentingProblems = (roomID: number) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/presenting-problems`
  );

  return data.data;
};

const useQueryRoomPresentingProblems = (roomID: number) =>
  useQuery<RoomPresentingProblem[], Error>({
    queryKey: ['presenting-problems', roomID.toString()],
    queryFn: fetchRoomPresentingProblems(roomID),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

export default useQueryRoomPresentingProblems;
