import {
  Button,
  EmotionThemeProvider,
  Select,
  useEmotionTheme,
  useWindowWidthState,
  View,
} from '@talkspace/react-toolkit';
import { useCallback, useEffect, useRef, useState } from 'react';
import Footer from 'components/Reusable/Footer/Footer';
import moment from 'moment';
import useQueryQuestionnaireQuestions, {
  Question,
} from 'ts-frontend/hooks/questionnaires/useQueryQuestionnaireQuestions';
import useQueryQuestionnaires, {
  Questionnaire,
} from 'ts-frontend/hooks/questionnaires/useQueryQuestionnaires';
import {
  QuestionnaireAnswerData,
  QuestionnaireQuestionOption,
} from 'ts-frontend/hooks/questionnaires/types';
import { useHistory, useLocation, useParams } from '../../../../../core/routerLib';
import Submenu from '../../../../Reusable/Submenu';
import { RiskAndSafetyDialogLocationState } from './types';

interface CSSRSScreenerFormParams {
  mode: 'create' | 'view';
}

type SelectedOption = { label: string; value: string };

const getMenuPlacement = (top: number) => {
  const spaceBelow = window.innerHeight - top;
  return spaceBelow < 230 ? 'top' : 'bottom';
};

interface CSSRSScreenerQuestionParams {
  question: Question;
  parseQuestionAnswersByQuestionID: (id: any) =>
    | never[]
    | {
        label: string;
        value: string;
      };
  updateQuestionAnswerByQuestionID: ({
    selectedOption,
    id,
  }: {
    selectedOption: SelectedOption;
    id: number;
  }) => void;
  mode: 'create' | 'view';
}

const CSSRSScreenerQuestion = ({
  question,
  parseQuestionAnswersByQuestionID,
  updateQuestionAnswerByQuestionID,
  mode,
}: CSSRSScreenerQuestionParams) => {
  const questionRef = useRef<HTMLDivElement>(null);

  const [menuPlacement, setMenuPlacement] = useState<'bottom' | 'top'>('bottom');
  const { isMobile, isTablet } = useWindowWidthState();

  const handleScroll = useCallback(() => {
    const { top } = questionRef?.current?.getBoundingClientRect() || {
      top: 0,
    };
    setMenuPlacement(getMenuPlacement(top));
  }, []);

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll, {
      capture: true,
      passive: true,
    });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <View ref={questionRef} style={{ marginBottom: 10 }} key={question.order}>
      {question.order === 3 && (
        <View style={{ marginTop: 5, gap: 10 }}>
          <View>If yes, please answer questions 3, 4, and 5. </View>
          <View>If no, please skip to question 6 </View>
        </View>
      )}
      {question.order === 7 && <View style={{ marginTop: 5 }}>If yes, ask:</View>}
      <Select
        dataQa={`cssrsScreenerSelectQuestion${question.order}`}
        isDisabled={mode === 'view'}
        isAlwaysHoverStyle={isMobile || isTablet}
        isSearchable={false}
        placeholderIsTwoLines
        placeHolderHeight={30}
        placeholder={`${question.order}. ${question.text}`}
        value={parseQuestionAnswersByQuestionID(question.id)}
        options={[
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' },
        ]}
        onChange={(selectedOption: SelectedOption) => {
          updateQuestionAnswerByQuestionID({
            selectedOption,
            id: question.id,
          });
        }}
        menuPlacement={menuPlacement}
      />
    </View>
  );
};

const CSSRSScreenerForm = (params: CSSRSScreenerFormParams) => {
  const { mode } = params;
  const { roomID, cssrsScreenerID } = useParams<{ roomID: string; cssrsScreenerID: string }>();

  const history = useHistory();

  const { data: cssrsScreenerActiveQuestions } = useQueryQuestionnaireQuestions({
    roomID: Number(roomID),
    questionnaireType: 'cssrs_screener',
  });

  const [cssrsScreener, setCssrsScreener] = useState<Questionnaire>();
  const location = useLocation<RiskAndSafetyDialogLocationState | undefined>();

  const [answers, setAnswers] = useState<Array<QuestionnaireAnswerData>>([]);

  const { data: cssrsScreeners } = useQueryQuestionnaires({
    roomID: Number(roomID),
    questionnaireTypes: ['cssrs_screener'],
  });

  const [cssrsScreenerQuestions, setCssrsScreenerQuestions] = useState<Array<Question>>([]);

  const [answersChanged, setAnswersChanged] = useState(false);

  useEffect(() => {
    answers.length > 0 ? setAnswersChanged(true) : setAnswersChanged(false);
  }, [answers.length, answers]);

  useEffect(() => {
    if (cssrsScreenerActiveQuestions) {
      setCssrsScreenerQuestions(
        mode === 'create'
          ? cssrsScreenerActiveQuestions
          : cssrsScreener?.questionnaireAnswers.map((it) => {
              return {
                description: it.questionnaireQuestion.description,
                order: it.questionnaireQuestion.order,
                id: it.questionnaireQuestion.id,
                text: it.questionnaireQuestion.text,
                questionnaireQuestionOptions:
                  it.questionnaireQuestionOptions as Array<QuestionnaireQuestionOption>,
                type: it.questionnaireQuestion.type as 'boolean' | 'multi',
                deletedAt: it.questionnaireQuestion.deletedAt || null,
              };
            }) || []
      );
    }
  }, [cssrsScreener, cssrsScreener?.questionnaireAnswers, cssrsScreenerActiveQuestions, mode]);

  useEffect(() => {
    setCssrsScreener(cssrsScreeners?.find((it) => it.id === Number(cssrsScreenerID)));
  }, [cssrsScreenerID, cssrsScreeners]);

  useEffect(() => {
    if (location?.state?.answers) {
      setAnswers(location.state.answers);
      setAnswersChanged(location.state.answersChanged);
    } else if (mode === 'view') {
      const formattedAnswers =
        cssrsScreener?.questionnaireAnswers.map((answer) => {
          return {
            questionID: answer.questionnaireQuestionID,
            booleanAnswer: answer.booleanAnswer,
          };
        }) || [];
      setAnswers(formattedAnswers);
    }
  }, [
    cssrsScreener?.questionnaireAnswers,
    cssrsScreeners,
    location?.state?.answers,
    location?.state?.answersChanged,
    mode,
  ]);

  const handleBackButtonPressed = useCallback(() => {
    mode === 'create' && answersChanged
      ? history.push(`/room/${roomID}/risk-and-safety/leave`, {
          questionnaireType: 'cssrs_screener',
          title: 'C-SSRS',
          answers,
          answersChanged,
        })
      : history.push(`/room/${roomID}/clinical-tab`, { section: 'risk-and-safety' });
  }, [answers, answersChanged, history, mode, roomID]);

  const parseQuestionAnswersByQuestionID = useCallback(
    (id) => {
      const answer = answers.filter(({ questionID }) => id === questionID)?.[0];
      if (answer?.booleanAnswer !== undefined && answer?.booleanAnswer !== null) {
        return {
          label: answer.booleanAnswer ? 'Yes' : 'No',
          value: answer.booleanAnswer ? 'true' : 'false',
        };
      }
      return [];
    },
    [answers]
  );

  const updateQuestionAnswerByQuestionID = useCallback(
    ({ selectedOption, id }: { selectedOption: SelectedOption; id: number }) => {
      const answersClone = [...answers];
      const answer = answersClone?.find(({ questionID }) => id === questionID);

      if (!answer) {
        setAnswers([
          ...answers,
          {
            questionID: id,
            booleanAnswer: selectedOption?.value === 'true',
          },
        ]);
      } else if (selectedOption?.value === undefined) {
        setAnswers(answersClone.filter(({ questionID }) => id !== questionID));
      } else {
        answer.booleanAnswer = selectedOption?.value === 'true';
        setAnswers(answersClone);
      }
    },
    [answers]
  );

  const { colors } = useEmotionTheme();

  const renderFooter = useCallback(
    () => (
      <Footer
        childComponents={[
          <View style={{ justifyContent: 'end', padding: 15 }}>
            <EmotionThemeProvider version="2.0.0">
              <Button
                data-qa="cssrsScreenerSaveButton"
                disabled={!answersChanged}
                variant="tertiary"
                text="Save"
                onPress={() => {
                  history.push(`/room/${roomID}/risk-and-safety/save`, {
                    questionnaireType: 'cssrs_screener',
                    title: 'C-SSRS',
                    answers: (() => {
                      if (!cssrsScreenerQuestions) {
                        return [];
                      }
                      const newAnswers = answers.filter((it) => {
                        const answersExistInQuestions = cssrsScreenerQuestions
                          .map((question) => question.id)
                          .includes(it.questionID);
                        return answersExistInQuestions;
                      });
                      return newAnswers;
                    })(),
                    answersChanged,
                  });
                }}
              />
            </EmotionThemeProvider>
          </View>,
        ]}
        isError={false}
        isUpdating={false}
        showSpinner={false}
        spinnerMessage=""
      />
    ),
    [answers, answersChanged, cssrsScreenerQuestions, history, roomID]
  );

  const getSubmenuChildComponent = useCallback(
    () => (
      <View style={{ fontSize: 16, paddingTop: 16, color: colors.permaSlateGrayApprox }}>
        {mode === 'view' && (
          <View style={{ alignSelf: 'center', marginBottom: 16 }}>
            {`${moment(cssrsScreener?.createdAt).format('MMM DD, YYYY')}`}
          </View>
        )}
        <View>Over the past month...</View>
        {cssrsScreenerQuestions
          ?.sort((a, b) => a.order - b.order)
          .map((question) => (
            <CSSRSScreenerQuestion
              key={question.order}
              question={question}
              parseQuestionAnswersByQuestionID={parseQuestionAnswersByQuestionID}
              updateQuestionAnswerByQuestionID={updateQuestionAnswerByQuestionID}
              mode={mode}
            />
          ))}
      </View>
    ),
    [
      colors.permaSlateGrayApprox,
      cssrsScreener?.createdAt,
      cssrsScreenerQuestions,
      mode,
      parseQuestionAnswersByQuestionID,
      updateQuestionAnswerByQuestionID,
    ]
  );

  return (
    <View style={{ height: '100%' }}>
      <Submenu
        title="CSSR Screener Form"
        titleComponent={<View>C-SSRS</View>}
        childComponents={[getSubmenuChildComponent()]}
        bodyStyle={{ padding: 10 }}
        childComponentsWrapperStyle={{ height: '100%' }}
        footerComponent={mode === 'view' ? undefined : renderFooter()}
        onBackAlt={() => {
          handleBackButtonPressed();
        }}
      />
    </View>
  );
};

export default CSSRSScreenerForm;
