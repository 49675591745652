import { useState, useEffect, useMemo, VoidFunctionComponent } from 'react';
import { Link, useHistory } from 'react-router-dom';

import moment from 'moment';
import {
  Small,
  View,
  Big,
  Standard,
  LiveSessionIconSmallV2,
  Table,
  useWindowWidthState,
  useEmotionTheme,
  CaretRight,
  TouchableView,
  Cell,
  EmotionTheme,
  TabContainer,
  TabContent,
  TabsOld,
  Tab,
  NumberTab,
  TooltipV2,
} from '@talkspace/react-toolkit';
import useQueryBookings from 'hooks/dashboard/useQueryBookings';

import { Booking, SessionModality } from 'ts-frontend/types';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { getUserData } from '../../../utils/token';
import SocketService from '../../../utils/socket/SocketService';
import { UpcomingBookingCard } from '../../../modules/upcomingBookings';
import extractBookingUIContext from '../../../modules/upcomingBookings/utils/extractBookingUIContext';
import styled from '../../../modules/core/styled';
import DashboardTablePlaceholder from '../Placeholders/DashboardTablePlaceholder';
import { dashboardGetHeaderProps } from '../DashboardTable';
import IconCalendar from '../Placeholders/IconCalendar';
import { DashboardCellRoomDetailV4, DashboardCellRoomActions } from '../Cells';

interface LiveSessionProps {
  isHidden: boolean;
  updateNumberOfBookings: (index: number) => void;
}

interface DateAndTimeProps {
  startTime: string;
  creditMinutes: number;
  modality: SessionModality;
  hasBreakAfterSession: boolean;
  isTentative: boolean;
  isClientBooked: boolean;
}

interface UpdateLiveSessionAvailabilityProps {
  updateAvailabilityBreakpoint: boolean;
  color: string;
  onPress: () => void;
}

type BookingTabs = 'today' | 'week' | 'all' | 'unconfirmed';

const BookingWrapper = styled(View)<{ index: number }>(({ index }) => {
  return {
    borderWidth: 0,
    borderTopWidth: index === 0 ? 0 : 1,
    borderStyle: 'solid',
    borderColor: '#C3CBDA',
    marginLeft: 20,
    marginRight: 20,
  };
});

const BookingsErrorWrapper = styled(View)(({ theme: { colors } }) => {
  return {
    background: colors.permaLinkWaterGrey,
    height: 81,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
  };
});

const LiveSessionsContentWrapper = styled(View)({
  padding: 0,
});

const LiveSessionsTitleWrapper = styled(View)<{ updateAvailabilityBreakpoint?: boolean }>(
  ({ updateAvailabilityBreakpoint }) => {
    return {
      marginBottom: 0,
      paddingTop: updateAvailabilityBreakpoint ? 20 : 0,
      paddingRight: 15,
      paddingBottom: 10,
      minHeight: 48,
      alignItems: 'baseline',
    };
  }
);

const BookingsError = () => (
  <BookingsErrorWrapper>
    <Big variant="bigMedium">Unable to load content</Big>
  </BookingsErrorWrapper>
);

const tabTitleStyle = { paddingTop: 0, paddingBottom: 0 };

const UpdateLiveSessionAvailability: VoidFunctionComponent<UpdateLiveSessionAvailabilityProps> = ({
  updateAvailabilityBreakpoint,
  color,
  onPress,
}) => (
  <TouchableView
    row
    style={{
      alignItems: 'baseline',
      justifySelf: updateAvailabilityBreakpoint ? 'flex-end' : undefined,
      marginLeft: updateAvailabilityBreakpoint ? 'auto' : undefined,
      marginTop: updateAvailabilityBreakpoint ? undefined : 8,
      marginBottom: updateAvailabilityBreakpoint ? undefined : 15,
    }}
    onPress={onPress}
    dataQa="updateLiveSessionAvailabilityButton"
  >
    <Small variant="smallBoldTSBlack" style={{ color, fontWeight: 1000, marginRight: 4 }}>
      Update live availability
    </Small>
    <CaretRight color={color} width={6} height={11} />
  </TouchableView>
);

const DateAndTime: VoidFunctionComponent<DateAndTimeProps> = ({
  startTime,
  creditMinutes,
  modality,
  hasBreakAfterSession,
  isTentative,
  isClientBooked,
}) => (
  <View row align="center">
    <View style={{ width: 16 }}>
      <LiveSessionIconSmallV2 modality={modality} />
    </View>
    <Standard style={{ marginLeft: 15 }} inline>
      {moment(startTime).format('ddd, MMM D')}
    </Standard>
    <Small variant="smallDarkGrey" style={{ marginLeft: 15, textAlign: 'center' }} inline>
      {`${moment(startTime).format('h:mma')} - ${moment(startTime)
        .add(creditMinutes, 'minute')
        .format('h:mma')}`}
    </Small>
    {(!isTentative || (isTentative && !isClientBooked)) && hasBreakAfterSession ? (
      <Small variant="smallBoldTSBlack" style={{ marginLeft: 15 }} inline>
        (+15min break)
      </Small>
    ) : null}
  </View>
);

const SessionStatus: VoidFunctionComponent<{
  text: string | JSX.Element;
  Icon: any;
  colors: EmotionTheme['colors'];
}> = ({ text, Icon, colors }) => (
  <View row align="center">
    <Icon />
    <Small style={{ color: colors.black, marginLeft: 6 }}>{text}</Small>
  </View>
);

const SessionStatusWithTooltip: VoidFunctionComponent<{
  text: string | JSX.Element;
  Icon: any;
  colors: EmotionTheme['colors'];
  tooltipText: JSX.Element;
}> = ({ text, Icon, colors, tooltipText }) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  return (
    <View row align="center">
      <Icon />
      <View
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        row
        align="center"
      >
        <Small style={{ color: colors.black, marginLeft: 6 }}>{text}</Small>
        <TooltipV2
          toolTipText={tooltipText}
          isTooltipOpen={isHovering}
          containerStyle={{ right: -200, top: 20 }}
          circleStyle={{
            width: 17,
            height: 17,
            marginTop: 2,
            marginLeft: 3,
          }}
          circleColor="#5F7A9B"
          hoverColor="#005C65"
        />
      </View>
    </View>
  );
};

const today = () => moment();
const filterForActive = (booking: Booking) =>
  ['active', 'completed', 'completed-after-late-start'].includes(booking?.status || '') &&
  booking?.timekitBookingState !== 'declined';

export const getAllActiveStatus = (bookings: Booking[]) => bookings.filter(filterForActive);
const getToday = (bookings: Booking[]) =>
  bookings
    .filter(filterForActive)
    .filter((booking) => moment(booking.startTime).isSame(today(), 'day'));
const getWeek = (bookings: Booking[]) =>
  bookings
    .filter(filterForActive)
    .filter((booking) =>
      moment(booking.startTime).isBetween(
        today().toISOString(),
        today().add(7, 'days').toISOString()
      )
    );
const getUnconfirmed = (bookings: Booking[]) =>
  bookings.filter((booking) => {
    const { status, timekitBookingState, scheduledByUserType } = booking;
    if (
      status === 'active' &&
      timekitBookingState === 'tentative' &&
      scheduledByUserType === 'client'
    ) {
      return true;
    }
    return false;
  });

const tabIndexEnum = {
  TODAY: 0,
  WEEK: 1,
  ALL: 2,
  UNCONFIRMED: 3,
};

const bookingFuncMappings = {
  [tabIndexEnum.TODAY]: getToday,
  [tabIndexEnum.WEEK]: getWeek,
  [tabIndexEnum.ALL]: getAllActiveStatus,
  [tabIndexEnum.UNCONFIRMED]: getUnconfirmed,
};

const placeholderCopyPerTabIndex: { [index: number]: { title: string; subtitle: string } } = {
  [tabIndexEnum.TODAY]: {
    title: 'You have no live sessions today!',
    subtitle: 'This is where you’ll see your daily live session schedule.',
  },
  [tabIndexEnum.WEEK]: {
    title: 'You have no live sessions this week!',
    subtitle: 'This is where you’ll see your weekly live session schedule.',
  },
  [tabIndexEnum.ALL]: {
    title: 'You have no upcoming live sessions!',
    subtitle: 'This is where you’ll see all your upcoming live sessions.',
  },
  [tabIndexEnum.UNCONFIRMED]: {
    title: 'You have no unconfirmed live sessions!',
    subtitle:
      'This is where you’ll see all the client requested live sessions that you have not yet confirmed.',
  },
};

const LiveSessions: VoidFunctionComponent<LiveSessionProps> = ({
  isHidden,
  updateNumberOfBookings,
}) => {
  const history = useHistory();
  const therapistID: number = getUserData().id;

  const {
    data: bookings = [],
    isError,
    isLoading,
    isFetching,
    refetch: refetchBookings,
  } = useQueryBookings(therapistID);

  const { isMobile, width } = useWindowWidthState();
  const { colors } = useEmotionTheme();
  const { calendarAvailability } = useFlags();
  const activeStatusBookings = useMemo(() => getAllActiveStatus(bookings), [bookings]);

  const totalBookings: { [key in BookingTabs]: number } = useMemo(() => {
    return {
      today: getToday(bookings).length,
      week: getWeek(bookings).length,
      all: getAllActiveStatus(bookings).length,
      unconfirmed: getUnconfirmed(bookings).length,
    };
  }, [bookings]);

  const [sortedBookings, setSortedBookings] = useState<Booking[]>(bookings);

  const initialTab = useMemo(
    () => (totalBookings.unconfirmed === 0 ? tabIndexEnum.TODAY : tabIndexEnum.UNCONFIRMED),
    [totalBookings.unconfirmed]
  );

  const [currentTabIndex, setCurrentTabIndex] = useState<number>(initialTab);

  useEffect(() => {
    const handleLiveSessionRefetch = () => refetchBookings();
    SocketService.instance().on('bookingUpdate', handleLiveSessionRefetch);
    return () => {
      SocketService.instance().off('bookingUpdate', handleLiveSessionRefetch);
    };
  }, [refetchBookings, therapistID]);

  useEffect(() => {
    if (currentTabIndex && updateNumberOfBookings) {
      updateNumberOfBookings(activeStatusBookings.length);
    }
    setSortedBookings(bookingFuncMappings[currentTabIndex](activeStatusBookings));
  }, [activeStatusBookings, currentTabIndex, updateNumberOfBookings]);

  const handleSelect = (tabIndex: number) => setCurrentTabIndex(tabIndex);

  const getPath = (booking) => (booking?.roomID > 0 ? `/room/${booking.roomID}/lvs-tab` : '');

  const getCursorStyle = (booking) => (booking?.roomID > 0 ? {} : { cursor: 'default' });

  const columns = useMemo(
    () => [
      {
        Header: 'Date and time',
        id: 'startTime',
        accessor: ({ startTime }) => new Date(startTime),
        sortType: 'datetime',
        style: {
          width: '45%',
          verticalAlign: 'middle',
          cursor: 'default',
        },
        Cell: ({ row: { original: booking } }: Cell<Booking>) => (
          <Link
            to={{ pathname: getPath(booking) }}
            className="dashboard-link"
            style={getCursorStyle(booking)}
          >
            <DateAndTime
              modality={booking.modality}
              startTime={booking.startTime}
              creditMinutes={booking.creditMinutes}
              hasBreakAfterSession={booking.hasBreakAfterSession}
              isTentative={booking.timekitBookingState === 'tentative'}
              isClientBooked={booking.scheduledByUserType === 'client'}
            />
          </Link>
        ),
      },
      {
        Header: 'Client',
        accessor: 'clientPseudonym',
        style: { width: '25%', cursor: 'default' },
        Cell: ({
          row: { original: booking },
          isHovering,
        }: Cell<Booking> & { isHovering: boolean }) => (
          <Link
            to={{ pathname: getPath(booking) }}
            className="dashboard-link"
            style={{ ...getCursorStyle(booking), flexDirection: 'row', display: 'flex' }}
          >
            <DashboardCellRoomDetailV4
              fullName={[booking.clientFirstName, booking.clientLastName].join(' ').trim()}
              pseudonym={booking.clientPseudonym}
              isHovering={isHovering}
            />
            {isHovering && booking.roomID > 0 && (
              <View style={{ marginLeft: -25 }}>
                <DashboardCellRoomActions
                  roomID={booking.roomID}
                  clientNickname={booking.clientPseudonym}
                  clientUserID={booking.clientUserID}
                />
              </View>
            )}
          </Link>
        ),
      },
      {
        Header: 'Confirmation status',
        disableSortBy: true,
        style: {
          cursor: 'default',
          width: '25%',
          verticalAlign: 'middle',
        },
        Cell: ({ row: { original: booking } }: Cell<Booking>) => {
          const { text, Icon, tooltipText } = extractBookingUIContext(booking);
          return (
            <Link
              to={{ pathname: getPath(booking) }}
              className="dashboard-link"
              style={getCursorStyle(booking)}
            >
              {tooltipText ? (
                <SessionStatusWithTooltip
                  text={text}
                  Icon={Icon}
                  colors={colors}
                  tooltipText={tooltipText}
                />
              ) : (
                <SessionStatus text={text} Icon={Icon} colors={colors} />
              )}
            </Link>
          );
        },
      },
    ],
    [colors]
  );

  if (isHidden) {
    return null;
  }

  const handleOnUpdateLivePress = () =>
    history.push(calendarAvailability ? '/calendar' : '/my-account?scrollTo=availability');
  const updateAvailabilityBreakpoint = width >= 975;

  return (
    <View style={{ marginTop: 20 }}>
      <LiveSessionsTitleWrapper className="unstackable" row={!isMobile}>
        <View
          row={updateAvailabilityBreakpoint}
          style={{ width: updateAvailabilityBreakpoint ? '100%' : undefined }}
        >
          <View row={isMobile}>
            <h4 style={{ margin: 0 }}>Upcoming live sessions</h4>
          </View>
          {!updateAvailabilityBreakpoint && (
            <UpdateLiveSessionAvailability
              color={colors.accessibilityGreenDark}
              onPress={handleOnUpdateLivePress}
              updateAvailabilityBreakpoint={updateAvailabilityBreakpoint}
            />
          )}

          {isMobile && (
            <View style={{ paddingTop: isMobile ? 10 : 0 }} row={isMobile}>
              <TabsOld onHandleSelect={handleSelect} initialTab={initialTab}>
                <Tab
                  tabTitleStyle={{
                    paddingLeft: updateAvailabilityBreakpoint ? 44 : 0,
                    ...tabTitleStyle,
                  }}
                  component={<NumberTab label="Today" count={totalBookings.today} />}
                />
                <Tab
                  tabTitleStyle={tabTitleStyle}
                  component={<NumberTab label="Week" count={totalBookings.week} />}
                />
                <Tab
                  tabTitleStyle={tabTitleStyle}
                  component={<NumberTab label="All" count={totalBookings.all} />}
                />
                <Tab
                  tabTitleStyle={tabTitleStyle}
                  component={
                    <NumberTab
                      label="Unconfirmed"
                      count={totalBookings.unconfirmed}
                      showNotificationDot={totalBookings.unconfirmed > 0}
                    />
                  }
                />
              </TabsOld>
            </View>
          )}

          {updateAvailabilityBreakpoint && (
            <UpdateLiveSessionAvailability
              color={colors.accessibilityGreenDark}
              onPress={handleOnUpdateLivePress}
              updateAvailabilityBreakpoint={updateAvailabilityBreakpoint}
            />
          )}
        </View>
      </LiveSessionsTitleWrapper>
      {!isMobile && (
        <View style={{ marginBottom: 15 }}>
          <TabContainer handleSelect={handleSelect} currentTab={currentTabIndex}>
            <TabContent title={`Today (${totalBookings.today})`} />
            <TabContent title={`This week (${totalBookings.week})`} />
            <TabContent title={`All (${totalBookings.all})`} />
            <TabContent title={`Unconfirmed (${totalBookings.unconfirmed})`} />
          </TabContainer>
        </View>
      )}

      {/* error */}
      {isError && <BookingsError />}
      {!isError && (
        <LiveSessionsContentWrapper className="unstackable hidden-xs hidden-sm">
          <Table
            columns={columns}
            data={sortedBookings}
            disableSortRemove
            getHeaderProps={dashboardGetHeaderProps(colors)}
            initialState={{ sortBy: [{ id: 'startTime', desc: false }] }}
            tableStyle={{ marginTop: 0 }}
            Empty={
              <DashboardTablePlaceholder
                title={placeholderCopyPerTabIndex[currentTabIndex].title}
                subtitle={placeholderCopyPerTabIndex[currentTabIndex].subtitle}
                icon={<IconCalendar />}
              />
            }
            isLoading={isLoading}
          />
        </LiveSessionsContentWrapper>
      )}
      {/* responsive cards section */}
      {!isError && (
        <div className="hidden-md hidden-lg">
          {!!sortedBookings.length &&
            sortedBookings.map((booking, i) => (
              <BookingWrapper index={i} key={booking.id}>
                <Link to={{ pathname: `/room/${booking.roomID}/lvs-tab` }}>
                  <UpcomingBookingCard
                    booking={booking}
                    handleCancelBooking={() => undefined}
                    handleConfirmBooking={() => undefined}
                    handleDeclineBooking={() => undefined}
                    handleRemoveBooking={() => undefined}
                    handleJoinCall={() => undefined}
                    shouldHideActions
                    isUpdating={isFetching}
                  />
                </Link>
              </BookingWrapper>
            ))}
        </div>
      )}
    </View>
  );
};

export default LiveSessions;
