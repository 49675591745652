import moment from 'moment';
import { TextDS, View } from '@talkspace/react-toolkit';
import { gender } from '@talkspace/configs';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { EmergencyContactData } from 'ts-frontend/types';
import { useParams } from '@/core/routerLib';
import useQueryRoomClientPresentingProblems from '../../../../hooks/useQueryRoomClientPresentingProblems';
import useQueryClientConditions from '../../../../hooks/useQueryClientConditions';
import useQueryEmergencyContact from '../../../../hooks/useQueryEmergencyContact';
import useQueryCustomerInformation, {
  CustomerInformation,
} from '../../../../hooks/useQueryCustomerInformation';
import useQueryParticipants from '../../../../hooks/useQueryParticipants';
import { Participant } from '../../../../types/participant';

interface FormatClientNameParams {
  userID: number;
  emergencyContact?: EmergencyContactData;
  customerInformation?: CustomerInformation;
  participants: Participant[];
}

const formatClientName = ({
  userID,
  emergencyContact,
  customerInformation,
  participants,
}: FormatClientNameParams) => {
  if (
    emergencyContact?.firstName &&
    emergencyContact?.lastName &&
    customerInformation?.preferredName
  ) {
    return `${emergencyContact.firstName} ${emergencyContact.lastName} (${customerInformation.preferredName})`;
  }

  if (emergencyContact?.firstName && emergencyContact?.lastName) {
    return `${emergencyContact?.firstName} ${emergencyContact?.lastName}`;
  }

  if (customerInformation?.preferredName) {
    return `${customerInformation.preferredName}`;
  }

  const participant = participants.filter((c) => c.userID === userID)?.[0];

  return participant?.displayName || '';
};

const formatClientGenderAndAge = (customerInformation?: CustomerInformation) => {
  if (!customerInformation) {
    return '';
  }

  if (customerInformation?.gender && customerInformation?.dateOfBirth) {
    return `${gender[customerInformation.gender as keyof typeof gender]}, ${moment().diff(
      moment(customerInformation.dateOfBirth),
      'years'
    )}`;
  }

  if (customerInformation?.gender) {
    return `${gender[customerInformation.gender as keyof typeof gender]}`;
  }

  if (customerInformation?.dateOfBirth) {
    return `${moment().diff(moment(customerInformation.dateOfBirth), 'years')}`;
  }

  return '';
};

export default function ClientInfo() {
  const { roomID } = useParams<{ roomID: string }>();

  const { data: roomDetails } = useQueryRoomDetails(Number(roomID));

  const { data: presentingProblems = [] } = useQueryRoomClientPresentingProblems(
    roomID,
    roomDetails?.clientUserID
  );

  const { data: conditions = [] } = useQueryClientConditions(roomDetails?.clientUserID);

  const { data: emergencyContact } = useQueryEmergencyContact(roomDetails?.clientUserID);

  const { data: customerInformation } = useQueryCustomerInformation(roomDetails?.clientUserID);

  const { data: participants = [] } = useQueryParticipants(Number(roomID));

  const clientName = roomDetails?.clientUserID
    ? formatClientName({
        userID: roomDetails.clientUserID,
        emergencyContact,
        customerInformation,
        participants,
      })
    : '';

  return (
    <View>
      <TextDS variant="headingMd" style={{ marginBottom: 4 }}>
        {clientName}
      </TextDS>
      <View>
        <TextDS variant="bodySm" colorRole="textSubtle">
          {formatClientGenderAndAge(customerInformation)}
        </TextDS>

        <TextDS variant="bodySm" colorRole="textSubtle">
          {presentingProblems.length > 1
            ? `Presenting problems: ${presentingProblems.map((it) => it.label).join(', ')}`
            : `Presenting problem: ${presentingProblems[0]?.label || 'none indicated'}`}
        </TextDS>

        {conditions.length ? (
          <TextDS variant="bodySm" colorRole="textSubtle">
            {conditions.length > 1
              ? `Diagnoses: ${conditions.map((it) => it.value).join(', ')}`
              : `Diagnosis: ${conditions[0].value}`}
          </TextDS>
        ) : null}
      </View>
    </View>
  );
}
