import { Divider, EmotionThemeProvider, styled, TextDS, View } from '@talkspace/react-toolkit';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { useParams } from '@/core/routerLib';
import JourneyTab from '../JourneyTab/JourneyTab';
import SmartInsights from './SmartInsights';
import ClientInfo from './ClientInfo';
import RiskInfo from './RiskInfo';
import TalktrackCard from './TalktrackCard';

const MainView = styled(View)(({ theme: { spacing } }) => {
  return {
    width: '100%',
    alignSelf: 'flex-start',
    paddingRight: spacing('space200'),
    paddingLeft: spacing('space200'),
  };
});

const ClientInfoAndRiskView = styled(View)(({ theme: { spacing } }) => {
  return {
    paddingTop: spacing('space200'),
    paddingBottom: spacing('space200'),
    gap: spacing('space150'),
  };
});

const RemindersView = styled(View)(({ theme: { spacing } }) => {
  return {
    paddingTop: spacing('space200'),
  };
});

const EmptyView = styled(View)(({ theme: { colorRoles, spacing } }) => {
  return {
    borderRadius: 8,
    border: `1px solid ${colorRoles.borders.borderDefault}`,
    backgroundColor: colorRoles.surfaces.neutralSubtleDefault,
    marginBottom: spacing('space200'),
    textAlign: 'center',
    height: 76,
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0 48px',
  };
});

export default function InsightsTab() {
  const { roomID } = useParams<{ roomID: string }>();

  const { data: { isSmartInsightsActive } = {}, isLoading } = useQueryRoomDetails(roomID);

  if (isLoading) {
    return <JourneyTab />;
  }

  return (
    <>
      <EmotionThemeProvider version="2.0.0">
        <MainView>
          <ClientInfoAndRiskView>
            <ClientInfo />

            <RiskInfo />
          </ClientInfoAndRiskView>

          {isSmartInsightsActive ? (
            <>
              <Divider />
              <SmartInsights />
            </>
          ) : (
            <EmptyView>
              <TextDS variant="bodySm">
                The Smart Insights feature is not available for this client
              </TextDS>
            </EmptyView>
          )}

          <Divider />

          <TalktrackCard />

          <RemindersView>
            <TextDS variant="headingMd">Reminders</TextDS>
          </RemindersView>
        </MainView>
      </EmotionThemeProvider>

      <JourneyTab />
    </>
  );
}
