import { connect } from 'react-redux';
import {
  styled,
  usePanelManagerActions,
  View,
  CloseButton,
  EmotionThemeProvider,
  Spinner,
} from '@talkspace/react-toolkit';
import { useEffect, useState } from 'react';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { FEEDBACK_REMINDER_FOR_TALKTRACK_ID } from 'ts-frontend/hooks/useTalktrackFeedbackReminder';
import { useQueryClient } from 'react-query';
import { useParams } from '@/core/routerLib';
import TalktrackSuggestionScreen from './screens/TalktrackSuggestionScreen';
import TalktrackPreviewScreen from './screens/TalktrackPreviewScreen';
import storage from '@/core/storage';
import ErrorScreen from './screens/ErrorScreen';
import useMutationCreateTalktracks from '../../../hooks/talkTrack/useMutationCreateTalktracks';
import SocketService from '../../../utils/socket/SocketService';
import { TalktrackCreatedEventResponse, TalkrackData } from './types';
import useQueryAvailableTalktrack from '../../../hooks/talkTrack/useQueryAvailableTalktrack';
import { availableTalkTrack } from '../../../hooks/talkTrack/queryKeys';

const Wrapper = styled(View)({
  padding: 16,
  flexDirection: 'column',
  height: '100%',
});

interface Props {
  completedVideoCallID: string;
  dispatch: Function;
}

const TalktrackModal = ({ completedVideoCallID, dispatch }: Props) => {
  const { roomID } = useParams<{ roomID: string }>();
  const { mutate: createTalkTracks } = useMutationCreateTalktracks();
  const { data: { clientUserID } = {} } = useQueryRoomDetails(roomID);
  const { data: businessLineData, isLoading: businessLineLoading } = useQueryBusinessLine(
    roomID,
    clientUserID
  );
  const queryClient = useQueryClient();

  const { data: availableTalktrack, isLoading: isAvailableTalktrackLoading } =
    useQueryAvailableTalktrack({ roomID });

  const { setIsLeftPanelOnTop, setIsRightPanelOpen } = usePanelManagerActions();

  const [activeScreen, setActiveScreen] = useState<'suggestion' | 'preview'>('suggestion');
  const [suggestionInput, setSuggestionInput] = useState<string | null>(null);
  const [talktrackData, setTalktrackData] = useState<TalkrackData | null>(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!isAvailableTalktrackLoading && availableTalktrack) {
      setTalktrackData((prev) => {
        return {
          ...prev,
          ...(availableTalktrack.talktrackID && { talktrackID: availableTalktrack.talktrackID }),
          ...(availableTalktrack.transcript && { transcript: availableTalktrack.transcript }),
          ...(availableTalktrack.talktrackURL && { talktrackURL: availableTalktrack.talktrackURL }),
          ...(availableTalktrack.canRegenerate && {
            canRegenerate: availableTalktrack.canRegenerate,
          }),
        };
      });
      if (availableTalktrack.providerSuggestion) {
        setSuggestionInput(availableTalktrack.providerSuggestion);
      }
    }
  }, [availableTalktrack, isAvailableTalktrackLoading]);

  useEffect(() => {
    if (
      talktrackData?.talktrackURL ||
      talktrackData?.transcript ||
      availableTalktrack?.status !== null
    ) {
      setActiveScreen('preview');
    }
  }, [availableTalktrack?.status, talktrackData?.talktrackURL, talktrackData?.transcript]);

  useEffect(() => {
    const handleTalktrackCreatedEvent = (data: { message: TalktrackCreatedEventResponse }) => {
      if (
        data?.message?.roomID === Number(roomID) &&
        data?.message?.liveCallID === Number(completedVideoCallID)
      ) {
        if (data?.message?.status === 'error') {
          setIsError(true);
          setActiveScreen('suggestion');
          return;
        }
        setTalktrackData((prev) => {
          return {
            ...(prev || {}),
            talktrackURL: data?.message?.talktrackURL ?? prev?.talktrackURL,
            transcript: data?.message?.transcript ?? prev?.transcript,
            talktrackID: data?.message?.talktrackID ?? prev?.talktrackID,
            canRegenerate: data?.message?.canRegenerate ?? prev?.canRegenerate,
          };
        });
        if (data?.message?.providerSuggestion) {
          setSuggestionInput(data?.message?.providerSuggestion);
        }
      }
    };

    SocketService.instance().on('talktrackCreated', handleTalktrackCreatedEvent);

    return () => {
      SocketService.instance().off('talktrackCreated', handleTalktrackCreatedEvent);
    };
  }, [completedVideoCallID, roomID]);

  const handleOpenFeedback = () => {
    setTimeout(() => {
      if (talktrackData?.talktrackID && activeScreen === 'preview') {
        storage.setItem(FEEDBACK_REMINDER_FOR_TALKTRACK_ID, talktrackData.talktrackID.toString());
        const talktrackClosedEvent = new CustomEvent('talktrackClosed');
        document.dispatchEvent(talktrackClosedEvent);
      }
    }, 2000);
  };

  const closeModal = () => {
    dispatch({ type: 'CLOSE_POST_LVS_TALK_TRACK' });
    queryClient.invalidateQueries(availableTalkTrack(roomID));
    setIsLeftPanelOnTop(true);
    setIsRightPanelOpen(false);
    handleOpenFeedback();
  };

  const handlePreviewEpisode = () => {
    setIsError(false);
    if (suggestionInput || businessLineData?.isBH) {
      createTalkTracks(
        {
          liveCallID: Number(completedVideoCallID),
          providerSuggestion: suggestionInput || null,
          roomID,
        },
        {
          onError: () => {
            setIsError(true);
            setActiveScreen('suggestion');
          },
        }
      );

      setActiveScreen('preview');
    }
  };

  const handleRegenerate = () => {
    handlePreviewEpisode();
    setTalktrackData(null);
  };

  if (businessLineLoading || !businessLineData || isAvailableTalktrackLoading) {
    return <Spinner />;
  }

  return (
    <EmotionThemeProvider version="2.0.0">
      <Wrapper>
        <View justify="end" row style={{ width: '100%' }}>
          <CloseButton onPress={closeModal} />
        </View>
        {isError ? (
          <ErrorScreen onTryAgain={() => setIsError(false)} />
        ) : (
          <>
            {activeScreen === 'suggestion' && (
              <TalktrackSuggestionScreen
                onCloseModal={closeModal}
                isOptional={businessLineData.isBH}
                onPreviewEpisode={handlePreviewEpisode}
                suggestionInput={suggestionInput}
                onSuggestionInputChange={(val) => setSuggestionInput(val)}
              />
            )}
            {activeScreen === 'preview' && (
              <TalktrackPreviewScreen
                talktrackID={talktrackData?.talktrackID}
                transcript={talktrackData?.transcript}
                talktrackURL={talktrackData?.talktrackURL}
                canRegenerate={talktrackData?.canRegenerate}
                onCloseModal={closeModal}
                onRegenerate={handleRegenerate}
              />
            )}
          </>
        )}
      </Wrapper>
    </EmotionThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    completedVideoCallID: state.room.completedVideoCallID,
  };
};

export default connect(mapStateToProps)(TalktrackModal);
