import React, { useEffect, useRef, useState } from 'react';
import { styled, TouchableView, View } from '../../../index';
import ThreeDotsIcon from './ThreeDotsIcon';

const MenuContentWrapper = styled(View)({
  display: 'block',
  position: 'relative',
  width: 0,
  height: 0,
  top: -40,
  left: -160,
});

const MenuContainer = styled(View)(({ theme: { colorRoles } }) => {
  return {
    position: 'absolute',
    border: `1px solid ${colorRoles.borders.borderDefault}`,
    borderRadius: 5,
    padding: 10,
    minHeight: 31,
    minWidth: 158,
    backgroundColor: 'white',
    lineHeight: '18px',
    fontWeight: 'bold',
    justifyContent: 'space-between',
    gap: 5,
  };
});

export interface ThreeDotsMenuProps {
  options: { text: string; onOptionPress: () => void }[];
  sectionName: 'cssrs' | 'safetyPlan';
}
const ThreeDotsMenu = (props: ThreeDotsMenuProps) => {
  const { options, sectionName } = props;
  const [showMenuActions, setShowMenuActions] = useState<boolean>(false);

  const MenuRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event) => {
    if (MenuRef.current && !MenuRef.current.contains(event.target)) {
      setShowMenuActions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside);

    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, []);

  return (
    <View>
      <TouchableView
        dataQa={`three-dots-menu-${sectionName}`}
        onPress={() => setShowMenuActions(true)}
      >
        <ThreeDotsIcon />
      </TouchableView>
      {showMenuActions && (
        <MenuContentWrapper>
          <MenuContainer ref={MenuRef}>
            {options.map((option) => (
              <TouchableView
                dataQa={`three-dots-menu-option-${option.text}`}
                onPress={() => {
                  option.onOptionPress();
                  setShowMenuActions(false);
                }}
              >
                {option.text}
              </TouchableView>
            ))}
          </MenuContainer>
        </MenuContentWrapper>
      )}
    </View>
  );
};

export default ThreeDotsMenu;
