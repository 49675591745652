import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  View,
  styled,
  Button,
  Tiny,
  TextAreaRounded,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import * as treatmentPlannerActions from 'actions/TreatmentPlannerActions';
import useQueryProgressNote from 'hooks/notes/useQueryProgressNote';
import useMutationUpdateProgressNote from 'hooks/notes/useMutationUpdateProgressNote';
import useMutationSaveDraftProgressNote from 'hooks/notes/useMutationSaveDraftProgressNote';
import { useHistory, useLocation, useParams } from '@/core/routerLib';
import {
  DEFAULT_RISK_OR_BARRIERS_HANDLING,
  NO_TREATMENT_PLAN_WORD_LIMIT,
  buildPostActionURL,
  getTextAreaContainerBorderStyleV2,
  getTextAreaStyleV2,
} from '../../../utils';
import {
  useProgressNoteFormActions,
  useProgressNoteFormState,
} from '../../context/TherapistProgressNoteFormContext';
import { Section } from '../../context/types';
import useDidUpdateEffect from '../../../../../../../hooks/useDidUpdateEffect';
import trackEvent from '../../../../../../../modules/analytics/trackEvent';
import { UnsubmittedSessionTask } from '../../../../../../../hooks/dashboard/useQueryTaskListV3';

const Container = styled(View)<{ isError: boolean }>(({ theme: { colors }, isError }) => {
  return {
    border: isError && `1px solid ${colors.permaFuchsia}`,
    borderRadius: 8,
    padding: isError && 10,
  };
});

const Wrapper = styled(View)(({ theme: { colors } }) => {
  return {
    marginTop: 10,
    marginBottom: 10,
  };
});

export const ErrorText = styled(Tiny)(({ theme: { colors } }) => {
  return {
    color: colors.permaFuchsia,
    fontSize: 11,
    fontWeight: 400,
  };
});

interface StoreProps {
  createNewTreatmentPlan(presentingProblems: Array<any>): Promise<void>;
}
interface OwnProps {
  currentTreatmentPlanID: number | undefined;
  nextSection: Section;
}

type Props = StoreProps & OwnProps;

const NoTreatmentPlanV2: FunctionComponent<Props> = ({
  currentTreatmentPlanID,
  createNewTreatmentPlan,
  nextSection,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { roomID, noteID } = useParams<{ roomID: string; noteID?: string }>();
  const formState = useProgressNoteFormState();
  const [noTreatmentPlanError, setNoTreatmentPlanError] = useState<boolean>(false);
  const { mutate: saveDraftProgressNote } = useMutationSaveDraftProgressNote();
  const { setFormState } = useProgressNoteFormActions();
  const navigateToTreatmentPlan = useRef(false);
  const { clientUserID, treatmentPlanProgress } = formState;
  const { data: progressNoteData } = useQueryProgressNote(
    clientUserID,
    noteID,
    formState.formMode !== 'create'
  );
  const { mutate: updateProgressNote } = useMutationUpdateProgressNote();
  const unsubmittedSessionID = new URLSearchParams(location.search).get('id');
  const postAction = history.location.pathname + history.location.search;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { isTransferred } = (formState.unsubmittedSession as UnsubmittedSessionTask) || {};

  const { colors } = useEmotionTheme();

  useEffect(() => {
    if (
      treatmentPlanProgress?.riskOrBarriersHandling === DEFAULT_RISK_OR_BARRIERS_HANDLING &&
      isTransferred
    ) {
      setFormState({
        treatmentPlanProgress: {
          ...treatmentPlanProgress!,
          riskOrBarriersHandling: null,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTransferred]);

  const navigateToTreatmentPlanner = (draftNoteID?: string) => {
    const postActionURL = draftNoteID
      ? buildPostActionURL({ draftNoteID, search: location.search })
      : postAction;
    if (currentTreatmentPlanID) {
      history.push(`/room/${roomID}/treatment-planner-goals`, { postAction: postActionURL });
    } else {
      createNewTreatmentPlan([]).then(() => {
        history.push(`/room/${roomID}/treatment-planner-goals`, { postAction: postActionURL });
      });
    }
  };

  const handleCreateTreatmentPlanPress = () => {
    const { showGlobalError, formMode, ...restFormState } = formState;
    const params = {
      roomID,
      noteID,
      clientUserID,
      isTherapist: true,
      ...restFormState,
    };

    if (formMode !== 'view') {
      if (noteID && progressNoteData?.status === 'submitted') {
        updateProgressNote(
          { ...params, noteID, clientUserID },
          {
            onSuccess: () => {
              navigateToTreatmentPlanner();
            },
          }
        );
      } else {
        saveDraftProgressNote(params, {
          onSuccess: (data) => {
            navigateToTreatmentPlanner(data.noteID?.toString());
          },
        });
      }
    }
  };

  useDidUpdateEffect(() => {
    if (formState.currentSection === 'finished' && navigateToTreatmentPlan.current) {
      handleCreateTreatmentPlanPress();
    }
  }, [formState.currentSection]);

  const handleConfirmPress = () => {
    let isError = !formState.isFirstNote && !formState.lowEngagement;

    isError =
      isError &&
      (!isTransferred
        ? progressNoteData?.status !== 'submitted'
        : !treatmentPlanProgress?.riskOrBarriersHandling);

    const completed = !isError;
    if (isError && !formState.isSaveAsDraftPressed) {
      setNoTreatmentPlanError(true);
      setFormState({ showGlobalError: true });
      return;
    }

    setFormState({
      treatmentPlanProgress: {
        riskOrBarriersHandling:
          treatmentPlanProgress?.riskOrBarriersHandling || DEFAULT_RISK_OR_BARRIERS_HANDLING,
        buildRapport: true,
        shortTermTreatmentObjective: DEFAULT_RISK_OR_BARRIERS_HANDLING,
      },
      formSections: {
        ...formState.formSections,
        treatmentPlanProgress: {
          open: false,
          changed: true,
          completed,
        },
        ...(formState.formSections.sessionSummary.completed
          ? {}
          : {
              sessionSummary: {
                ...formState.formSections.sessionSummary,
                open: true,
              },
            }),
      },
    });
  };
  useDidUpdateEffect(() => {
    if (formState.currentSection === 'treatmentPlanProgress') {
      setFormState({ currentSection: nextSection });
      handleConfirmPress();
    }
  }, [formState.currentSection]);

  const handleCreateTreatmentPlan = () => {
    setIsLoading(true);
    setFormState({ showGlobalError: false });
    navigateToTreatmentPlan.current = true;
    trackEvent(
      'addNoteInfo',
      {
        actionName: 'progressNotesInteraction',
      },
      {
        action: 'Provider adds information to progress note',
        createTreatmentPlanPressed: true,
        roomID,
        unsubmittedSessionID,
      }
    );
    setFormState({ currentSection: 'sessionDateAndModality', isSaveAsDraftPressed: true });
  };

  const handleChangeRiskOrBarriers = (value: string) => {
    setNoTreatmentPlanError(false);
    setFormState({
      treatmentPlanProgress: { ...treatmentPlanProgress!, riskOrBarriersHandling: value },
    });
  };

  return (
    <Wrapper>
      {!isTransferred ? (
        <Container isError={noTreatmentPlanError && !formState.lowEngagement}>
          {!formState.lowEngagement && (
            <Button
              disabled={isLoading || progressNoteData?.status === 'submitted'}
              onPress={handleCreateTreatmentPlan}
              dataQa="createTreatmentPlanButton"
              text="Create treatment plan"
              isLoading={isLoading}
              variant="secondary"
              stretch
            />
          )}
        </Container>
      ) : (
        <TextAreaRounded
          autoSize
          style={{ ...getTextAreaContainerBorderStyleV2(colors, noTreatmentPlanError) }}
          textAreaStyle={getTextAreaStyleV2(false, colors)}
          value={treatmentPlanProgress?.riskOrBarriersHandling || ''}
          onChangeText={handleChangeRiskOrBarriers}
          placeholder="Goal: Increase frequency of..."
          rows={4}
          limit={NO_TREATMENT_PLAN_WORD_LIMIT}
          hasLimit
        />
      )}
      {noTreatmentPlanError && !formState.lowEngagement && (
        <ErrorText data-testid="noTreatmentPlanError">
          {`Required: You need to ${
            isTransferred ? 'write' : 'create'
          } a treatment plan for this client`}
        </ErrorText>
      )}
    </Wrapper>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    createNewTreatmentPlan: (presentingProblems: Array<any>) =>
      dispatch(treatmentPlannerActions.createNewTreatmentPlan(presentingProblems)),
  };
};

export default connect(null, mapDispatchToProps)(NoTreatmentPlanV2);
