import { EmotionThemeProvider, Spinner, styled, TextDS, View } from '@talkspace/react-toolkit';
import {
  WebPageError,
  ClipboardCheck,
} from '@talkspace/react-toolkit/src/designSystems/illustrations';
import { getUserData } from '@/auth/helpers/token';

import useQuerySupervisedNotes from '../../../hooks/notes/useQuerySupervisedNotes';
import Footer from '../../Footer/Footer';
import TableView from './TableView';
import FilterMenu from './FilterMenu';
import SortMenu from './SortMenu';
import { SupervisedNotesTableProvider } from './TableContext';
import BulkActionView from './BulkActionView';
import ProgressNoteModal from '../ProgressNoteModal';

const PlaceholderView = styled(View)(({ theme: { colorRoles, spacing } }) => {
  return {
    alignItems: 'center',
    justifyContent: 'center',
    background: colorRoles.surfaces.neutralSubtleDefault,
    gap: 4,
    borderRadius: 7,
    border: `1px solid ${colorRoles.borders.borderDefault}`,
    padding: spacing('space400'),
  };
});

const MainView = styled(View)(
  ({
    theme: {
      spacing,
      window: { isMobile },
    },
  }) => {
    return {
      flex: 1,
      flexDirection: 'column',
      gap: spacing('space300'),
      padding: spacing(isMobile ? 'space200' : 'space400'),
      position: 'relative',
    };
  }
);

const HeaderView = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      flexDirection: isMobile ? 'column' : 'row',
      justifyContent: isMobile ? 'start' : 'space-between',
      gap: 24,
    };
  }
);

const FilterAndSortView = styled(View)({
  flexDirection: 'row',
  gap: 11,
});

export default function SupervisedNotes() {
  const therapistID = getUserData().id;

  const { data, isLoading, isError } = useQuerySupervisedNotes(therapistID);

  return (
    <EmotionThemeProvider version="2.0.0">
      <SupervisedNotesTableProvider>
        <MainView>
          <HeaderView>
            <View style={{ gap: 8 }}>
              <TextDS variant="headingLg">Review progress notes</TextDS>
              <TextDS variant="bodySm">Review notes written by your associates</TextDS>
            </View>

            {!!data?.length && (
              <FilterAndSortView>
                <FilterMenu />

                <SortMenu />
              </FilterAndSortView>
            )}
          </HeaderView>

          {(() => {
            if (isLoading) {
              return (
                <PlaceholderView>
                  <Spinner />
                </PlaceholderView>
              );
            }

            if (isError) {
              return (
                <PlaceholderView>
                  <WebPageError width={48} height={48} />

                  <TextDS variant="headingMd">Oops, something went wrong</TextDS>

                  <TextDS colorRole="textSubtle">
                    Please check your internet connection and try again later
                  </TextDS>
                </PlaceholderView>
              );
            }

            if (data && data.length === 0) {
              return (
                <PlaceholderView>
                  <ClipboardCheck />

                  <TextDS variant="headingMd">You’re all caught up!</TextDS>

                  <TextDS colorRole="textSubtle">
                    This is where you’ll see sessions that need your approval
                  </TextDS>
                </PlaceholderView>
              );
            }

            return <TableView />;
          })()}

          <Footer hidden={false} />

          <BulkActionView />
        </MainView>

        <ProgressNoteModal />
      </SupervisedNotesTableProvider>
    </EmotionThemeProvider>
  );
}
