import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import getParamByName from '@/utils/queryString';
import {
  editTreatmentPlan,
  getStandardObjectives,
  getObjectives,
  saveObjectives,
  removeObjective,
  editObjective,
} from '../../../../../actions/TreatmentPlannerActions';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import Footer from '../../../../Reusable/Footer/Footer';
import Button from '../../../../Reusable/Buttons/Button';
import './TreatmentPlannerObjectives.css';
import CheckList from '../../../../Reusable/ItemList/CheckList';
import AlertContainer from '../../../../Reusable/AlertContainer/AlertContainer';
import { doActions, getPreviousItems, getCombinedItems } from './TreatmentPlannerOperationHelper';

class TreatmentPlannerObjectives extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCard: false,
      combinedObjectives: [],
      previousObjectives: [],
      treatmentPlanObjectives: props.objectives,
    };

    if (!props.currentTreatmentPlan.id) {
      props.history.push(`/room/${props.roomID}/clinical-tab`);
    }
    this.checkboxHandler = this.checkboxHandler.bind(this);
    this.textAreaChangeHandler = this.textAreaChangeHandler.bind(this);
    this.adderChangeHandler = this.adderChangeHandler.bind(this);
    this.setCardState = this.setCardState.bind(this);
    this.handleNavigation = this.handleNavigation.bind(this);
  }

  handleNavigation() {
    const { history } = this.props;
    if (history.location?.state?.postAction) {
      history.push(history.location.state.postAction);
    } else if (this.postAction === 'notes') {
      history.push(`/room/${this.props.roomID}/notes-tab`);
    } else {
      history.push(`/room/${this.props.roomID}/clinical-tab`);
    }
  }

  getFooterChildren() {
    const {
      props: {
        history,
        roomID,
        currentTreatmentPlan: { id: treatmentPlanID },
      },
    } = this;

    const activeObjectives = this.state.combinedObjectives.filter((objective) => objective.checked);
    const historyState = history.location.state;

    const postAction = getParamByName('postAction');

    return this.state.showCard
      ? [
          <AlertContainer
            key={4}
            title="No objectives selected"
            childComponents={[
              <Button
                key={1}
                title="Continue"
                isSecondary={false}
                clickHandler={() => {
                  this.setState({ showCard: false });
                  doActions(
                    treatmentPlanID,
                    this.state.combinedObjectives,
                    this.state.previousObjectives,
                    this.props.saveObjectives,
                    this.props.removeObjective,
                    this.props.editObjective
                  ).then(() => {
                    this.props.editTreatmentPlan(treatmentPlanID, { committed: true });
                    this.handleNavigation();
                  });
                }}
                dataQa="noObjectivesSelectedContinueButton"
              />,
              <Button
                title="Cancel"
                key={2}
                isSecondary
                clickHandler={() => this.setState({ showCard: false })}
                dataQa="noObjectivesSelectedCancelButton"
              />,
            ]}
            body="You can select objectives by checking the ones listed above. By clicking continue no objectives will be saved to this treatment plan. You can add objectives later by editing the treatment plan."
            containerStyle={{
              width: 355,
              border: 'none',
            }}
          />,
        ]
      : [
          <Button
            title="Cancel"
            isSecondary
            key={1}
            clickHandler={() => history.goBack()}
            dataQa="treatmentPlanObjectivesCancelButton"
          />,
          <Button
            title="Next"
            isSecondary={false}
            key={2}
            dataQa="treatmentPlanObjectivesNextButton"
            clickHandler={() => {
              const {
                roomID,
                currentTreatmentPlan: { id: treatmentPlanID },
                history,
              } = this.props;
              if (!!activeObjectives && !!activeObjectives.length) {
                doActions(
                  treatmentPlanID,
                  this.state.combinedObjectives,
                  this.state.previousObjectives,
                  this.props.saveObjectives,
                  this.props.removeObjective,
                  this.props.editObjective
                ).then(() => {
                  history.push(
                    `/room/${this.props.roomID}/treatment-planner-interventions${
                      postAction ? `?postAction=${postAction}` : ''
                    }`,
                    historyState
                  );
                });
              } else {
                this.setState({
                  showCard: true,
                });
              }
            }}
          />,
        ];
  }

  getFooterComponent() {
    const { isUpdating, isError, spinnerMessage } = this.props;
    const footerStyle = this.state.showCard
      ? {
          height: 186,
          flexDirection: 'column-reverse',
          paddingTop: 3,
        }
      : {};
    return (
      <Footer
        key={4}
        childComponents={[...this.getFooterChildren()]}
        isError={isError}
        isUpdating={isUpdating}
        showSpinner={this.state.showSpinner}
        spinnerMessage={spinnerMessage}
        footerStyle={footerStyle}
      />
    );
  }

  getViewContent() {
    return (
      <form>
        <div key={1} className="treatment-planner-intro-text">
          Please select objectives from the list for each presenting problem.
        </div>
        <div key={2} className="treatment-plan-checklist-container">
          {!!this.state.combinedObjectives &&
            this.props.treatmentPlanPresentingProblems.map((presentingProblem, key) => {
              const objectives = this.state.combinedObjectives.filter(
                (objective) => presentingProblem.id === objective.presentingProblemID
              );
              return (
                <CheckList
                  key={key}
                  listTitle={`${presentingProblem.text} objectives`}
                  checkboxChangeHandler={this.checkboxHandler}
                  textAreaChangeHandler={this.textAreaChangeHandler}
                  displayField="description"
                  items={objectives}
                  adderChangeHandler={this.adderChangeHandler}
                  itemInit={{
                    presentingProblemID: presentingProblem.id,
                    standard: false,
                    checked: false,
                  }}
                  editorPlaceholder="Edit objective"
                  adderPlaceholder="Add objective"
                  unCheckEmpty
                />
              );
            })}
        </div>
      </form>
    );
  }

  setCardState() {
    const activeObjectives = this.state.combinedObjectives.filter((objective) => objective.checked);
    if (!!activeObjectives && !!activeObjectives.length) {
      this.setState({ showCard: false });
    }
  }

  UNSAFE_componentWillMount() {
    const {
      props: { currentTreatmentPlan },
    } = this;
    if (currentTreatmentPlan.id) {
      Promise.all([
        ...this.props.treatmentPlanPresentingProblems.map((presentingProblem) =>
          this.props.getStandardObjectives(presentingProblem.id)
        ),
        this.props.getObjectives(this.props.currentTreatmentPlan.id),
      ]).then(() => {
        const standardObjectives = this.props.standardObjectives.map(
          ({ id, presentingProblemID, description, isNewVersion }) => {
            return {
              id,
              presentingProblemID,
              description,
              tagText: isNewVersion ? 'NEW' : null,
              checked: false,
              standard: true,
            };
          }
        );

        const treatmentPlanObjectives = this.props.objectives.map(
          ({ treatmentPlanID, id, description, presentingProblemID, standard }) => {
            return {
              treatmentPlanID,
              id,
              description,
              presentingProblemID,
              standard,
              checked: true,
            };
          }
        );

        const combinedObjectives = getCombinedItems(
          treatmentPlanObjectives,
          standardObjectives,
          'description'
        );

        const previousObjectives = getPreviousItems(combinedObjectives);

        this.setState({
          combinedObjectives,
          previousObjectives,
          treatmentPlanObjectives,
        });
      });
    }
  }

  adderChangeHandler(event, item) {
    this.setState(
      {
        combinedObjectives: this.state.combinedObjectives.concat([item]),
      },
      this.setCardState
    );
  }

  checkboxHandler(event, objective) {
    const newCombinedObjectives = this.state.combinedObjectives.map((combinedObjective) =>
      ((objective.id !== undefined && objective.id === combinedObjective.id) ||
        (objective.itemIdx !== undefined && objective.itemIdx === combinedObjective.itemIdx)) &&
      objective.description === combinedObjective.description
        ? objective
        : combinedObjective
    );

    this.setState(
      {
        combinedObjectives: newCombinedObjectives,
      },
      this.setCardState
    );
  }

  textAreaChangeHandler(event, item) {
    const combinedObjectives = this.state.combinedObjectives.map((i) => {
      if (
        ((item.id !== undefined && i.id === item.id) ||
          (item.itemIdx !== undefined && i.itemIdx === item.itemIdx)) &&
        i.standard === false &&
        i.standard === item.standard
      )
        return item;
      return i;
    });
    this.setState(
      {
        combinedObjectives,
      },
      this.setCardState
    );
  }

  render() {
    return (
      <Submenu
        bodyStyle={{
          paddingRight: 35,
          paddingLeft: 35,
        }}
        childComponents={[this.getViewContent()]}
        title="Objectives"
        footerComponent={this.getFooterComponent()}
        prevRoute="treatment-planner-goals"
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roomID: state.room.roomID,
    currentTreatmentPlan: state.treatmentPlanner.currentTreatmentPlan,
    treatmentPlanPresentingProblems: state.treatmentPlanner.currentTreatmentPlan.presentingProblems,
    standardObjectives: state.treatmentPlanner.standardObjectives,
    objectives: state.treatmentPlanner.objectives,
    showSpinner: state.treatmentPlanner.isUpdating,
    spinnerMessage: state.treatmentPlanner.spinnerMessage,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editTreatmentPlan,
      getStandardObjectives,
      getObjectives,
      removeObjective,
      saveObjectives,
      editObjective,
    },
    dispatch
  );

const TreatmentPlannerObjectivesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TreatmentPlannerObjectives);

export default withRouter(TreatmentPlannerObjectivesContainer);
