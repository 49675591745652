import { useMutation, useQueryClient } from 'react-query';
import { ProgressNoteFormState } from 'components/Room/CRMContainer/NotesTab/ProgressNoteForm/context/types';
import { createProgressNoteRequest } from 'components/Room/CRMContainer/NotesTab/utils';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import {
  notesListQueryKey,
  progressNoteHelperDataQueryKey,
  progressNoteQueryKey,
} from './queryKeys';
import analytics from '../../components/Room/CRMContainer/NotesTab/Analytics';

interface UpdateProgressNoteMutateVariables
  extends Omit<ProgressNoteFormState, 'showGlobalError' | 'formMode'> {
  roomID: string;
  noteID: string;
  clientUserID?: number;
  isTherapist?: boolean;
}

const updateProgressNote = async (params: UpdateProgressNoteMutateVariables): Promise<void> => {
  const { roomID, noteID, clientUserID, isTherapist, formVariant, ...progressNoteState } = params;
  const requestBody = createProgressNoteRequest(progressNoteState, isTherapist);

  analytics.trackProgressNoteApiCalledEvent({
    calledEndPointDescription: 'UpdateProgressNote',
    roomID,
    noteID,
    serviceStartDate: progressNoteState.serviceStartDate,
    serviceEndDate: progressNoteState.serviceEndDate,
    modalityID: progressNoteState.modalityID,
    videoCalls: progressNoteState.videoCalls,
  });

  await apiWrapper.patch(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/progress-notes/${noteID}`,
    requestBody
  );
};

const useMutationUpdateProgressNote = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, UpdateProgressNoteMutateVariables>(updateProgressNote, {
    onSuccess: (_, { roomID, noteID, clientUserID }) => {
      queryClient.invalidateQueries(notesListQueryKey(roomID));
      queryClient.invalidateQueries(progressNoteHelperDataQueryKey(roomID));
      queryClient.invalidateQueries(progressNoteQueryKey(clientUserID, noteID, roomID));
    },
  });
};

export default useMutationUpdateProgressNote;
