import { useQuery } from 'react-query';
import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';
import { SessionService } from '../components/Room/CRMContainer/NotesTab/types';
import { sessionServicesV3QueryKey } from './queryKeys';
import { getUserData } from '../utils/token';

interface SessionServicesVariables {
  roomID: string;
  clientUserID?: number;
  liveCallID?: number;
  asyncSessionID?: number;
  therapistID?: number;
  isSupervisor?: boolean;
}

const fetchSessionServices =
  ({
    roomID,
    clientUserID,
    asyncSessionID,
    liveCallID,
    therapistID,
    isSupervisor = false,
  }: SessionServicesVariables) =>
  async () => {
    const isOtherTherapist =
      therapistID && getUserData()?.id && Number(therapistID) !== getUserData().id
        ? therapistID
        : undefined;

    const version = isOtherTherapist ? 'v2' : 'v3';

    const entity = isSupervisor ? `therapists/${therapistID}` : `clients/${clientUserID}`;

    let url = `${apiHelper().apiEndpoint}/${version}/rooms/${roomID}/${entity}/session-services`;

    if (!isOtherTherapist || isSupervisor) {
      const queryParams = new URLSearchParams();

      if (asyncSessionID) {
        queryParams.set('asyncSessionID', asyncSessionID.toString());
      }

      if (liveCallID) {
        queryParams.set('liveCallID', liveCallID.toString());
      }

      url += `?${queryParams.toString()}`;
    }

    const { data } = await apiWrapper.get(url);

    return data.data;
  };

const useQuerySessionServicesV2 = (params: SessionServicesVariables) =>
  useQuery<SessionService[], Error>({
    queryKey: sessionServicesV3QueryKey(params),
    queryFn: fetchSessionServices(params),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!params.clientUserID && (!!params.liveCallID || !!params.asyncSessionID),
  });

export default useQuerySessionServicesV2;
