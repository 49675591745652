import { ReactNode } from 'react';
import {
  Standard,
  SelectRounded,
  View,
  useEmotionTheme,
  styled,
  EmotionTheme,
  EmotionThemeProvider,
  TextDS,
  TooltipV2,
} from '@talkspace/react-toolkit';

import { SelectViewProps } from '@talkspace/react-toolkit/src/components/Select/SelectView';

const Title = styled(Standard)({
  width: 'max-content',
  marginRight: 8,
  fontWeight: 400,
  fontSize: 14,
});

const Container = styled(View)(() => {
  return {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
});

const getWrapperStyle = (colors: EmotionTheme['colors'], isDisabled?: boolean, flex?: boolean) => {
  return {
    marginTop: 0,
    minWidth: 70,
    width: 'fit-content',
    background: isDisabled ? colors.permaGreyed : colors.permaPhantomWhite,
    flex: flex && 1,
  };
};

const SelectContainer = ({
  handleChange,
  isDisabled,
  styles,
  title,
  value,
  options,
  components,
  children,
  flex = false,
  dataTestID,
  tooltipText = undefined,
  tooltipComponent = undefined,
  dataQa,
  menuPlacement = 'auto',
  error = '',
}: SelectViewProps & { tooltipText?: string; tooltipComponent?: ReactNode; error?: string }) => {
  const { colors } = useEmotionTheme();
  const wrapperStyle = getWrapperStyle(colors, isDisabled, flex);
  return (
    <Container style={{ marginBottom: error ? 30 : 12 }}>
      <View row align="center">
        <Title>{title}</Title>
        {tooltipText && <TooltipV2 containerStyle={{ left: -210 }} toolTipText={tooltipText} />}
        {tooltipComponent && tooltipComponent}
      </View>

      <View style={{ position: 'relative' }}>
        <EmotionThemeProvider version="0">
          <SelectRounded
            dataTestID={dataTestID}
            value={value}
            onChange={handleChange}
            options={options}
            isDisabled={isDisabled}
            wrapperStyle={wrapperStyle}
            styles={styles}
            components={{ ...components }}
            shouldDisplayLock={false}
            menuPlacement={menuPlacement}
            dataQa={dataQa}
            placeholder="Select"
          />
        </EmotionThemeProvider>

        {error && (
          <View style={{ position: 'absolute', bottom: -20, left: 0 }}>
            <TextDS variant="bodyXs" colorRole="textCriticalDefault">
              {error}
            </TextDS>
          </View>
        )}
      </View>

      {children}
    </Container>
  );
};

export default SelectContainer;
