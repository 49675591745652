import { useQuery } from 'react-query';
import { LiveSessionModality } from 'ts-frontend/types';
import { AccountType } from 'ts-frontend/entities/Room';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { unsubmittedSessionsQueryKey } from './queryKeys';
import { VideoCall } from '../../components/Room/CRMContainer/NotesTab/types';

const DEFAULT_POLLING_TIME_IN_MINUTES = 5;

export type SessionSubmissionStatus =
  | 'needsEngagement'
  | 'readyForReview'
  | 'readyToSubmit'
  | 'readyToStart'
  | 'reopenedAndReadyForReview'
  | 'tooSoonToSubmit'
  | 'pendingReview'
  | 'needsRevision';

export interface UnsubmittedSession {
  id: string;
  canReopen: boolean;
  submissionStatus: SessionSubmissionStatus | null;
  serviceStartDate: Date;
  serviceEndDate: Date | null;
  draftProgressNoteID?: number;
  sessionModality: 'live' | 'messaging';
  isSubmissionTooLateForPayout: boolean;
  scheduledStartDate: Date;
  generatedSessionSummaryEnabled: boolean;
  generatedSessionSummaryAvailable: boolean;
  userID: number;
  accountType: AccountType;
}

export interface UnsubmittedMessagingSession extends UnsubmittedSession {
  wordCount: number | null;
  wordDuration: number | null;
  isEndDateFixed: boolean;
  asyncSessionID: number;
  sessionReportID: number | null;
  caseID: number | null;
  minSessionWords: number | null;
  maxSessionWords: number | null;
  isStartDateFixed: boolean;
}

export interface UnsubmittedLiveSession extends UnsubmittedSession {
  liveCallID: number;
  liveSessionType: LiveSessionModality;
  liveCallDuration: number | null;
  freeLiveCallIDs: number[];
}

export interface GetUnsubmittedSessionsResponse {
  unsubmittedSessions: UnsubmittedSession[];
  unsubmittedFreeLiveCalls: VideoCall[];
}

const fetchUnsubmittedSessions = (roomID: string) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/progress-notes/unsubmitted-sessions`
  );
  return data.data;
};

const useQueryUnsubmittedSessions = ({
  roomID,
  inRoomPollingInterval = DEFAULT_POLLING_TIME_IN_MINUTES,
  isEnabled = true,
}) =>
  useQuery<GetUnsubmittedSessionsResponse, Error>({
    queryKey: unsubmittedSessionsQueryKey(roomID),
    queryFn: fetchUnsubmittedSessions(roomID),
    cacheTime: Infinity,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    refetchInterval: inRoomPollingInterval * 1000 * 60,
    enabled: isEnabled,
  });

export default useQueryUnsubmittedSessions;
