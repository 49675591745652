export interface AdminConfigValueParams {
  key: string;
  json?: boolean;
}

interface SessionServicesV3QueryKeyProps {
  roomID: string | number;
  clientUserID?: string | number;
  asyncSessionID?: string | number;
  liveCallID?: string | number;
}

export const referralConditionsQueryKey = ['referral-conditions'];

export const sessionServicesQueryKey = (
  roomID: string | number,
  clientUserID?: string | number
): Array<string> => [
  'rooms',
  roomID.toString(),
  'clients',
  clientUserID?.toString() || '',
  'session-services',
];

export const sessionServicesV3QueryKey = ({
  roomID,
  clientUserID,
  asyncSessionID,
  liveCallID,
}: SessionServicesV3QueryKeyProps): Array<string> => [
  'rooms',
  roomID.toString(),
  'clients',
  clientUserID?.toString() || '',
  asyncSessionID?.toString() || '',
  liveCallID?.toString() || '',
  'session-services',
];

export const adminConfigQueryKey = (configKey: string): Array<string> => ['adminConfig', configKey];

export const userAdminConfigQueryKey = (config: AdminConfigValueParams) => [
  'userAdminConfig',
  config,
];

export const roomClientPresentingProblemsQueryKey = (
  roomID: string | number,
  clientUserID?: string | number
): Array<string> => [
  'rooms',
  roomID.toString(),
  'clients',
  clientUserID?.toString() || '',
  'presenting-problems',
];

export const roomPresentingProblemsQueryKey = (roomID: string | number): Array<string> => [
  'rooms',
  roomID.toString(),
  'presenting-problems',
];

export const videoCreditsQueryKey = (roomID: number) => [roomID];

export const clientConditionsQueryKey = (clientUserID?: number) => [
  'client-conditions',
  clientUserID,
];

export const emergencyContactQueryKey = (clientUserID?: number) => [
  'emergency-contact',
  clientUserID,
];

export const customerInformationQueryKey = (clientUserID?: number) => [
  'customer-information',
  clientUserID,
];

export const riskIndicationsQueryKey = (roomID: number) => ['risk-indications', roomID];

export const smartInsightsQueryKey = (roomID: number) => ['smart-insights', roomID];
