import {
  Button,
  getSpacing,
  TextDS,
  View,
  useWindowWidthState,
  Checkbox,
  CheckV2,
  SkeletonLoader,
  Spinner,
  useEmotionTheme,
  styled,
  Tooltip,
} from '@talkspace/react-toolkit';
import { useState } from 'react';
import AudioPlayer from 'chat/components/AudioPlayer';
import { ArrowRotateRight } from '@talkspace/react-toolkit/src/designSystems/icons';
import { useParams } from '@/core/routerLib';
import { skeletonData, NUMBER_OF_REGENERATIONS } from '../utils';
import ErrorText from '../components/ErrorText';
import useMutationSendTalktrack from '../../../../hooks/talkTrack/useMutationSendTalktrack';

const Wrapper = styled(View)(() => {
  return {
    flex: 1,
    maxWidth: 650,
    alignSelf: 'center',
  };
});

export const Separator = styled(View)(({ theme: { colorRoles, spacing } }) => {
  return {
    height: '1px',
    background: colorRoles.borders.borderDefault,
    width: 'calc(100% + 32px)',
    marginBottom: spacing('space200'),
    marginLeft: -16,
  };
});

const TranscriptWrapper = styled(View)(
  ({
    theme: {
      colorRoles,
      window: { isDesktop },
    },
  }) => {
    return {
      width: isDesktop && 429,
      maxHeight: !isDesktop ? 208 : 387,
      border: `1px solid ${colorRoles.borders.borderDefault}`,
      borderRadius: 8,
      padding: 12,
      textAlign: 'left',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: 4,
      },
      marginBottom: 24,
      flex: 1,
      whiteSpace: 'pre-wrap',
    };
  }
);

const AudioLoader = styled(View)(
  ({
    theme: {
      colorRoles,
      spacing,
      window: { isDesktop },
    },
  }) => {
    return {
      paddingTop: spacing('space200'),
      paddingBottom: spacing('space200'),
      paddingRight: spacing('space100'),
      paddingLeft: spacing('space100'),
      textAlign: 'center',
      backgroundColor: colorRoles.surfaces.neutralDefault,
      borderRadius: spacing('space150'),
      marginTop: spacing('space050'),
      minWidth: 205,
      maxWidth: isDesktop && 205,
    };
  }
);

export const ActionWrapper = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      gap: isMobile ? 12 : 24,
      flexDirection: isMobile && 'column-reverse',
    };
  }
);

const tooltipStyle = {
  opacity: '1 !important',
  width: 209,
  padding: '6px 16px !important',
};

const AudionPlaceholder = () => {
  const { colorRoles } = useEmotionTheme();

  return (
    <AudioLoader>
      <Spinner
        secondaryColor={colorRoles.icons.iconSubtlestDefault}
        primaryColor="rgba(104, 118, 141, 0.4)"
        thickness={3}
        style={{ marginBottom: 8 }}
      />
      <TextDS variant="bodyXs">
        Generating personalized podcast...our AI is working hard to craft a meaningful episode.
      </TextDS>
    </AudioLoader>
  );
};

const SkeletonPlaceholder = () => (
  <>
    {skeletonData.map((style) => (
      <SkeletonLoader
        key={style.id}
        variant="bodySm"
        style={{ marginBottom: style.marginBottom }}
        wrapperStyle={{ width: style.width }}
      />
    ))}
  </>
);

const ContentBlock = ({ title, children }: { title: string; children: React.ReactNode }) => (
  <View>
    <TextDS style={{ marginBottom: 4 }} variant="bodySm">
      {title}
    </TextDS>
    {children}
  </View>
);

const TranscriptBlock = ({ transcript }: { transcript?: string }) => (
  <ContentBlock title="Transcript">
    <TranscriptWrapper>{!transcript ? <SkeletonPlaceholder /> : transcript}</TranscriptWrapper>
  </ContentBlock>
);

const AudioBlock = ({ talktrackURL }: { talktrackURL?: string }) => (
  <ContentBlock title="Audio">
    {!talktrackURL ? (
      <AudionPlaceholder />
    ) : (
      <AudioPlayer style={{ width: '100%' }} audioUrl={talktrackURL} />
    )}
  </ContentBlock>
);

interface TalkTrackPreviewScreenProps {
  transcript?: string;
  talktrackURL?: string;
  talktrackID?: number;
  canRegenerate?: boolean;
  onCloseModal: () => void;
  onRegenerate: () => void;
}

const TalktrackPreviewScreen = ({
  transcript,
  talktrackURL,
  talktrackID,
  canRegenerate,
  onCloseModal,
  onRegenerate,
}: TalkTrackPreviewScreenProps) => {
  const { roomID } = useParams<{ roomID: string }>();
  const { mutate: sendTalktrack } = useMutationSendTalktrack();
  const { isDesktop, isMobile } = useWindowWidthState();
  const { colorRoles } = useEmotionTheme();

  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const [isError, setIsError] = useState(false);
  const isReadyToSend = transcript && talktrackURL;

  const handleSend = () => {
    if (!isConsentChecked) {
      setIsError(true);
    } else if (talktrackID) {
      sendTalktrack({ roomID, talktrackID });
      onCloseModal();
      localStorage.removeItem(NUMBER_OF_REGENERATIONS);
    }
  };

  const handleRegenerate = () => {
    if (canRegenerate) {
      onRegenerate();
    }
  };

  const handleCheckboxChange = () => {
    setIsError(false);
    setIsConsentChecked((prev) => !prev);
  };

  const checkboxStyle = {
    height: 20,
    width: 20,
    borderColor: isError && `${colorRoles.surfaces.criticalBoldDefault} !important`,
  };

  const regenerateDisabled = !isReadyToSend || !canRegenerate;

  const checkBox = (
    <>
      {isReadyToSend && (
        <View justify="start" style={{ marginBottom: getSpacing('space300') }}>
          <Checkbox
            alignCenter={false}
            isChecked={isConsentChecked}
            label="I have reviewed this transcript or audio and confirm it is appropriate to send to my client."
            setIsChecked={handleCheckboxChange}
            isLabelOnRight
            stretch={false}
            checkboxStyle={checkboxStyle}
            checkComponent={<CheckV2 />}
            containerStyle={{ margin: 0 }}
          />
          <ErrorText isVisible={isError} text="Required" style={{ marginLeft: 27, marginTop: 0 }} />
        </View>
      )}
    </>
  );

  return (
    <>
      <Wrapper>
        <TextDS
          style={{ marginBottom: getSpacing('space150'), textAlign: 'center' }}
          variant="headingLg"
        >
          Preview Talkcast episode
        </TextDS>
        <TextDS
          variant="bodySm"
          style={{ marginBottom: getSpacing('space400'), textAlign: 'center' }}
        >
          Please review the audio episode by listening to it or reading the transcript. Use your
          clinical judgment to decide whether you want to send it to your client.
        </TextDS>
        <View row={isDesktop} style={{ marginBottom: 4, gap: 16 }} justify="center">
          <TranscriptBlock transcript={transcript} />
          <AudioBlock talktrackURL={talktrackURL} />
        </View>
        {!isMobile && checkBox}
      </Wrapper>

      <Separator />

      {isMobile && checkBox}
      <ActionWrapper row={!isMobile} justify="space-between" style={{ width: '100%' }}>
        <Tooltip
          tip="You’ve reached the limit for regenerating this episode."
          hide={canRegenerate}
          backgroundColor={colorRoles.surfaces.neutralBoldDefault}
          place="right"
          messageStyle={tooltipStyle}
          buttonStyle={{
            width: undefined,
            height: undefined,
            justifyContent: 'center',
          }}
        >
          <Button
            onPress={handleRegenerate}
            disabled={regenerateDisabled}
            variant="tertiary"
            text="Regenerate"
            Icon={() => (
              <ArrowRotateRight
                state={regenerateDisabled ? 'disabled' : 'default'}
                colorType="brand"
              />
            )}
          />
        </Tooltip>

        <ActionWrapper row={!isMobile}>
          <Button variant="secondary" onPress={onCloseModal} text="Maybe later" sizeDS="medium" />
          <Button
            onPress={handleSend}
            disabled={!isReadyToSend}
            text="Send audio to client"
            sizeDS="medium"
          />
        </ActionWrapper>
      </ActionWrapper>
    </>
  );
};

export default TalktrackPreviewScreen;
