import * as actionTypes from '../constants/TreatmentPlannerConstants';

const initialState = {
  currentTreatmentPlan: {},

  standardGoals: [],
  goals: [],

  standardObjectives: [],
  objectives: [],

  standardInterventions: [],
  interventions: [],

  archivedTreatmentPlans: [],

  archivedTreatmentPlan: {},
  presentingProblemsOptions: [],

  spinnerMessage: ' ',
  isUpdating: false,
  isError: false,
};

let currentTreatmentPlan;

export default function treatmentPlanner(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_GET_ARCHIVED_TREATMENT_PLANS:
      return {
        ...state,
        spinnerMessage: 'Loading archived treatment plans',
        isUpdating: true,
        isError: false,
      };

    case actionTypes.REQUEST_GET_ARCHIVED_TREATMENT_PLANS_ERROR:
      return {
        ...state,
        spinnerMessage: 'Something went wrong',
        isUpdating: false,
        isError: true,
      };

    case actionTypes.RECEIVE_GET_ARCHIVED_TREATMENT_PLANS:
      return {
        ...state,
        archivedTreatmentPlans: action.data,
        spinnerMessage: ' ',
        isUpdating: false,
        isError: false,
      };

    case actionTypes.REQUEST_GET_ARCHIVED_TREATMENT_PLAN:
      return {
        ...state,
        spinnerMessage: 'Loading archived treatment plan',
        isUpdating: true,
        isError: false,
      };

    case actionTypes.REQUEST_GET_ARCHIVED_TREATMENT_PLAN_ERROR:
      return {
        ...state,
        spinnerMessage: 'Something went wrong',
        isUpdating: false,
        isError: true,
      };

    case actionTypes.RECEIVE_GET_ARCHIVED_TREATMENT_PLAN:
      return {
        ...state,
        archivedTreatmentPlan: action.data,
        spinnerMessage: ' ',
        isUpdating: false,
        isError: false,
      };

    case actionTypes.REQUEST_GET_ROOM_PRESENTING_PROBLEMS:
      return {
        ...state,
        spinnerMessage: 'Loading presenting problems',
        isUpdating: true,
        isError: false,
      };

    case actionTypes.REQUEST_GET_ROOM_PRESENTING_PROBLEMS_ERROR:
      return {
        ...state,
        spinnerMessage: 'Something went wrong',
        isUpdating: false,
        isError: true,
      };

    case actionTypes.RECEIVE_GET_ROOM_PRESENTING_PROBLEMS:
      return {
        ...state,
        presentingProblemsOptions: action.presentingProblemsOptions,
        spinnerMessage: ' ',
        isUpdating: false,
        isError: false,
      };

    case actionTypes.REQUEST_GET_TREATMENT_PLAN:
      return {
        ...state,
        spinnerMessage: 'Loading treatment plan',
        isUpdating: true,
        isError: false,
      };

    case actionTypes.REQUEST_GET_TREATMENT_PLAN_ERROR:
      return {
        ...state,
        spinnerMessage: 'Something went wrong',
        isUpdating: false,
        isError: true,
      };

    case actionTypes.RECEIVE_GET_TREATMENT_PLAN:
      return {
        ...state,
        currentTreatmentPlan: action.data[0],
        spinnerMessage: ' ',
        isUpdating: false,
        isError: false,
      };

    case actionTypes.REQUEST_POST_TREATMENT_PLAN:
      return {
        ...state,
        spinnerMessage: 'Loading treatment plan',
        isUpdating: true,
        isError: false,
      };

    case actionTypes.REQUEST_POST_TREATMENT_PLAN_ERROR:
      return {
        ...state,
        spinnerMessage: 'Something went wrong',
        isUpdating: false,
        isError: true,
      };

    case actionTypes.RECEIVE_POST_TREATMENT_PLAN:
      return {
        ...state,
        currentTreatmentPlan: {
          ...action.data,
          presentingProblems: action.data.presentingProblems ? action.data.presentingProblems : [],
        },
        spinnerMessage: ' ',
        isUpdating: false,
        isError: false,
      };

    case actionTypes.REQUEST_PATCH_TREATMENT_PLAN:
      return {
        ...state,
        spinnerMessage: 'Loading treatment plan',
        isUpdating: true,
        isError: false,
      };

    case actionTypes.REQUEST_PATCH_TREATMENT_PLAN_ERROR:
      return {
        ...state,
        spinnerMessage: 'Something went wrong',
        isUpdating: false,
        isError: true,
      };

    case actionTypes.RECEIVE_PATCH_TREATMENT_PLAN:
      if (action.presentingProblems && action.presentingProblems.length) {
        currentTreatmentPlan = {
          ...state.currentTreatmentPlan,
          presentingProblems: action.presentingProblems,
        };
        return {
          ...state,
          currentTreatmentPlan,
          spinnerMessage: ' ',
          isUpdating: false,
          isError: false,
        };
      }
      return {
        ...state,
        ...action.data,
        spinnerMessage: ' ',
        isUpdating: false,
        isError: false,
      };

    case actionTypes.REQUEST_GET_STANDARD_GOALS:
      return {
        ...state,
        spinnerMessage: 'Loading goals',
        isUpdating: true,
        isError: false,
      };

    case actionTypes.REQUEST_GET_STANDARD_GOALS_ERROR:
      return {
        ...state,
        spinnerMessage: 'Something went wrong',
        isUpdating: false,
        isError: true,
      };

    case actionTypes.RECEIVE_GET_STANDARD_GOALS:
      return {
        ...state,
        standardGoals: state.standardGoals.concat(action.standardGoals),
        spinnerMessage: ' ',
        isUpdating: false,
        isError: false,
      };

    case actionTypes.REQUEST_GET_GOALS:
      return {
        ...state,
        spinnerMessage: 'Loading goals',
        isUpdating: true,
        isError: false,
      };

    case actionTypes.REQUEST_GET_GOALS_ERROR:
      return {
        ...state,
        spinnerMessage: 'Something went wrong',
        isUpdating: false,
        isError: true,
      };

    case actionTypes.RECEIVE_GET_GOALS:
      return {
        ...state,
        goals: action.data,
        spinnerMessage: ' ',
        isUpdating: false,
        isError: false,
      };

    case actionTypes.REQUEST_POST_GOALS:
      return {
        ...state,
        spinnerMessage: 'Updating goals',
        isUpdating: true,
        isError: false,
      };

    case actionTypes.REQUEST_POST_GOALS_ERROR:
      return {
        ...state,
        spinnerMessage: 'Something went wrong',
        isUpdating: false,
        isError: true,
      };

    case actionTypes.RECEIVE_POST_GOALS:
      return {
        ...state,
        goals: state.goals.concat(action.data),
        spinnerMessage: ' ',
        isUpdating: false,
        isError: false,
      };

    case actionTypes.REQUEST_PATCH_GOAL:
      return {
        ...state,
        spinnerMessage: 'Updating goals',
        isUpdating: true,
        isError: false,
      };

    case actionTypes.REQUEST_PATCH_GOAL_ERROR:
      return {
        ...state,
        spinnerMessage: 'Something went wrong',
        isUpdating: false,
        isError: true,
      };

    case actionTypes.RECEIVE_PATCH_GOAL:
      return {
        ...state,
        spinnerMessage: ' ',
        isUpdating: false,
        isError: false,
      };

    case actionTypes.REQUEST_DELETE_GOAL:
      return {
        ...state,
        spinnerMessage: 'Updating goals',
        isUpdating: true,
        isError: false,
      };

    case actionTypes.REQUEST_DELETE_GOAL_ERROR:
      return {
        ...state,
        spinnerMessage: 'Something went wrong',
        isUpdating: false,
        isError: true,
      };

    case actionTypes.RECEIVE_DELETE_GOAL:
      return {
        ...state,
        goals: state.goals.filter((goal) => goal.id !== action.goalID),
        spinnerMessage: ' ',
        isUpdating: false,
        isError: false,
      };

    case actionTypes.REQUEST_GET_STANDARD_OBJECTIVES:
      return {
        ...state,
        spinnerMessage: 'Loading objectives',
        isUpdating: true,
        isError: false,
      };

    case actionTypes.REQUEST_GET_STANDARD_OBJECTIVES_ERROR:
      return {
        ...state,
        spinnerMessage: 'Something went wrong',
        isUpdating: false,
        isError: true,
      };

    case actionTypes.RECEIVE_GET_STANDARD_OBJECTIVES:
      return {
        ...state,
        standardObjectives: state.standardObjectives.concat(action.standardObjectives),
        spinnerMessage: ' ',
        isUpdating: false,
        isError: false,
      };

    case actionTypes.REQUEST_GET_OBJECTIVES:
      return {
        ...state,
        spinnerMessage: 'Loading objectives',
        isUpdating: true,
        isError: false,
      };

    case actionTypes.REQUEST_GET_OBJECTIVES_ERROR:
      return {
        ...state,
        spinnerMessage: 'Something went wrong',
        isUpdating: false,
        isError: true,
      };

    case actionTypes.RECEIVE_GET_OBJECTIVES:
      return {
        ...state,
        objectives: action.data,
        spinnerMessage: ' ',
        isUpdating: false,
        isError: false,
      };

    case actionTypes.REQUEST_POST_OBJECTIVES:
      return {
        ...state,
        spinnerMessage: 'Updating objectives',
        isUpdating: true,
        isError: false,
      };

    case actionTypes.REQUEST_POST_OBJECTIVES_ERROR:
      return {
        ...state,
        spinnerMessage: 'Something went wrong',
        isUpdating: false,
        isError: true,
      };

    case actionTypes.RECEIVE_POST_OBJECTIVES:
      return {
        ...state,
        objectives: state.objectives.concat(action.data),
        spinnerMessage: ' ',
        isUpdating: false,
        isError: false,
      };

    case actionTypes.REQUEST_PATCH_OBJECTIVE:
      return {
        ...state,
        spinnerMessage: 'Updating objectives',
        isUpdating: true,
        isError: false,
      };

    case actionTypes.REQUEST_PATCH_OBJECTIVE_ERROR:
      return {
        ...state,
        spinnerMessage: 'Something went wrong',
        isUpdating: false,
        isError: true,
      };

    case actionTypes.RECEIVE_PATCH_OBJECTIVE:
      currentTreatmentPlan = Object.assign({}, state.currentTreatmentPlan);

      currentTreatmentPlan.objectives = currentTreatmentPlan.objectives.map((objective) =>
        objective.id === action.objectiveID
          ? { ...objective, completed: action.completed }
          : objective
      );

      return {
        ...state,
        currentTreatmentPlan,
        spinnerMessage: ' ',
        isUpdating: false,
        isError: false,
      };

    case actionTypes.REQUEST_DELETE_OBJECTIVE:
      return {
        ...state,
        spinnerMessage: 'Updating objectives',
        isUpdating: true,
        isError: false,
      };

    case actionTypes.REQUEST_DELETE_OBJECTIVE_ERROR:
      return {
        ...state,
        spinnerMessage: 'Something went wrong',
        isUpdating: false,
        isError: true,
      };

    case actionTypes.RECEIVE_DELETE_OBJECTIVE:
      return {
        ...state,
        objectives: state.objectives.filter((objective) => objective.id !== action.objectiveID),
        spinnerMessage: ' ',
        isUpdating: false,
        isError: false,
      };

    case actionTypes.REQUEST_GET_STANDARD_INTERVENTIONS:
      return {
        ...state,
        spinnerMessage: 'Loading interventions',
        isUpdating: true,
        isError: false,
      };

    case actionTypes.REQUEST_GET_STANDARD_INTERVENTIONS_ERROR:
      return {
        ...state,
        spinnerMessage: 'Something went wrong',
        isUpdating: false,
        isError: true,
      };

    case actionTypes.RECEIVE_GET_STANDARD_INTERVENTIONS:
      return {
        ...state,
        standardInterventions: state.standardInterventions.concat(action.standardInterventions),
        spinnerMessage: ' ',
        isUpdating: false,
        isError: false,
      };

    case actionTypes.REQUEST_GET_INTERVENTIONS:
      return {
        ...state,
        spinnerMessage: 'Loading interventions',
        isUpdating: true,
        isError: false,
      };

    case actionTypes.REQUEST_GET_INTERVENTIONS_ERROR:
      return {
        ...state,
        spinnerMessage: 'Something went wrong',
        isUpdating: false,
        isError: true,
      };

    case actionTypes.RECEIVE_GET_INTERVENTIONS:
      return {
        ...state,
        interventions: action.data,
        spinnerMessage: ' ',
        isUpdating: false,
        isError: false,
      };

    case actionTypes.REQUEST_POST_INTERVENTIONS:
      return {
        ...state,
        spinnerMessage: 'Updating interventions',
        isUpdating: true,
        isError: false,
      };

    case actionTypes.REQUEST_POST_INTERVENTIONS_ERROR:
      return {
        ...state,
        spinnerMessage: 'Something went wrong',
        isUpdating: false,
        isError: true,
      };

    case actionTypes.RECEIVE_POST_INTERVENTIONS:
      return {
        ...state,
        interventions: state.interventions.concat(action.data),
        spinnerMessage: ' ',
        isUpdating: false,
        isError: false,
      };

    case actionTypes.REQUEST_PATCH_INTERVENTION:
      return {
        ...state,
        spinnerMessage: 'Updating interventions',
        isUpdating: true,
        isError: false,
      };

    case actionTypes.REQUEST_PATCH_INTERVENTION_ERROR:
      return {
        ...state,
        spinnerMessage: 'Something went wrong',
        isUpdating: false,
        isError: true,
      };

    case actionTypes.RECEIVE_PATCH_INTERVENTION:
      currentTreatmentPlan = Object.assign({}, state.currentTreatmentPlan);

      currentTreatmentPlan.objectives = currentTreatmentPlan.objectives.map((objective) => {
        const interventions = objective.interventions.map((intervention) =>
          intervention.id === action.interventionID
            ? { ...intervention, completed: action.completed }
            : intervention
        );
        return {
          ...objective,
          interventions,
        };
      });

      return {
        ...state,
        currentTreatmentPlan,
        spinnerMessage: ' ',
        isUpdating: false,
        isError: false,
      };

    case actionTypes.REQUEST_DELETE_INTERVENTION:
      return {
        ...state,
        spinnerMessage: 'Updating interventions',
        isUpdating: true,
        isError: false,
      };

    case actionTypes.REQUEST_DELETE_INTERVENTION_ERROR:
      return {
        ...state,
        spinnerMessage: 'Something went wrong',
        isUpdating: false,
        isError: true,
      };

    case actionTypes.RECEIVE_DELETE_INTERVENTION:
      return {
        ...state,
        interventions: state.interventions.filter(
          (intervention) => intervention.id !== action.interventionID
        ),
        spinnerMessage: ' ',
        isUpdating: false,
        isError: false,
      };

    case actionTypes.CLEAN_CURRENT_TREATMENT_PLAN:
      return {
        ...state,
        currentTreatmentPlan: {},
        spinnerMessage: ' ',
        isUpdating: false,
        isError: false,
      };

    default:
      return state;
  }
}
