import { TextDS } from '@talkspace/react-toolkit';
import { formatSessionDate } from '../TasksV2/utils';

interface Props {
  startDate: Date;
  endDate?: Date | null;
  shouldDisplayRange: boolean;
}

const DateOfSessionCell = ({ startDate, endDate, shouldDisplayRange }: Props) => (
  <TextDS variant="bodySm" colorRole="textSubtle">
    {formatSessionDate({ startDate, endDate, shouldDisplayRange })}
  </TextDS>
);

export default DateOfSessionCell;
