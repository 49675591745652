export const notesListQueryKey = (roomID: string | number): Array<string> => [
  'room',
  roomID.toString(),
  'notes',
];

export const progressNoteHelperDataQueryKey = (roomID: string | number): Array<string> => [
  'room',
  roomID.toString(),
  'progress-notes',
  'helper-data',
];

export const progressNoteQueryKey = (
  clientUserID?: string | number,
  noteID?: string | number,
  roomID?: string | number
): Array<string> => [
  'rooms',
  roomID?.toString() || '',
  'clients',
  clientUserID?.toString() || '',
  'progress-notes',
  noteID?.toString() || '',
];

export const caseConsultationNoteQueryKey = (
  clientUserID?: string | number,
  noteID?: string | number
): Array<string> => [
  'clients',
  clientUserID?.toString() || '',
  'case-consultation-notes',
  noteID?.toString() || '',
];

export const collateralNoteQueryKey = (
  clientUserID?: string | number,
  noteID?: string | number
): Array<string> => [
  'clients',
  clientUserID?.toString() || '',
  'collateral-notes',
  noteID?.toString() || '',
];

export const dischargeNoteQueryKey = (
  clientUserID?: string | number,
  noteID?: string | number
): Array<string> => [
  'clients',
  clientUserID?.toString() || '',
  'discharge-notes',
  noteID?.toString() || '',
];

export const psychotherapyNoteQueryKey = (
  roomID: string | number,
  noteID?: string | number
): Array<string> => ['rooms', roomID.toString(), 'psychotherapy-notes', noteID?.toString() || ''];

export const psychiatryReferralNoteQueryKey = (
  roomID: string | number,
  noteID?: string | number
): Array<string> => [
  'rooms',
  roomID.toString(),
  'psychiatry-referral-notes',
  noteID?.toString() || '',
];

export const transferNoteQueryKey = (
  roomID: string | number,
  noteID?: string | number
): Array<string> => ['rooms', roomID.toString(), 'transfer-notes', noteID?.toString() || ''];

export const unsubmittedSessionsQueryKey = (roomID: string | number): Array<string> => [
  'rooms',
  roomID.toString(),
  'unsubmitted-sessions',
];

export const generateSessionSummaryQueryKey = (roomID: string): Array<string> => [
  'rooms',
  roomID.toString(),
  'generate-session-summary',
];

export const sessionMessagesQueryKey = (
  roomID: string | number,
  asyncSessionID?: string | number
): Array<string> => [
  'rooms',
  roomID.toString(),
  'session-messages',
  'async-sessions',
  asyncSessionID?.toString() || '',
];

export const supervisedNotesQueryKey = (therapistID: number) => [
  'supervised-notes',
  therapistID.toString(),
];
