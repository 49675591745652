import { styled, View } from '@talkspace/react-toolkit';

const Seperator = styled(View)(({ theme: { colors } }) => {
  return {
    height: 1,
    background: colors.catskillWhite,
    marginBottom: 10,
    marginTop: 10,
  };
});

export default Seperator;
