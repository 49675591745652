import {
  appearance,
  attitude,
  behavior,
  speech,
  affect,
  mood,
  thoughtProcesses,
  perception,
  insightOrJudgement,
} from '@talkspace/configs';
import { formatSelectOptionsFromConfig, OptionType } from '@talkspace/react-toolkit';
import { Modality } from '../../../utils';
import { ProgressNoteMentalStatusExamCreationAttributes } from '../../../types';
import {
  MentalExamCategoryErrors,
  MentalExamDescriptionErrors,
  mentalStatusExamCategories,
  MentalStatusExamCategory,
} from './types';

const appearanceOptions = formatSelectOptionsFromConfig(appearance);
const attitudeOptions = formatSelectOptionsFromConfig(attitude);
const behaviorOptions = formatSelectOptionsFromConfig(behavior);
const speechOptions = formatSelectOptionsFromConfig(speech);
const affectOptions = formatSelectOptionsFromConfig(affect).filter(
  (it) => it.value !== 'depressed' && it.value !== 'reactive_and_mood_congruent'
);
const moodOptions = formatSelectOptionsFromConfig(mood);
const thoughtProcessesOptions = formatSelectOptionsFromConfig(thoughtProcesses);
const perceptionOptions = formatSelectOptionsFromConfig(perception);
const insightOrJudgementOptions = formatSelectOptionsFromConfig(insightOrJudgement);

interface MentalStatusExamQuestionConfig {
  key: MentalStatusExamCategory;
  title: string;
  options: OptionType<string>[];
}

const isOptionAvailable = (modalityID: number | null, value: string): boolean => {
  if (modalityID === Modality.TEXT) {
    return true;
  }
  return value !== 'not_observed';
};

export const getQuestionsConfig = (modalityID: number | null): MentalStatusExamQuestionConfig[] => [
  {
    key: 'appearance',
    title: 'Appearance',
    options: appearanceOptions.filter((it) => isOptionAvailable(modalityID, it.value)),
  },
  { key: 'attitude', title: 'Attitude', options: attitudeOptions },
  {
    key: 'behavior',
    title: 'Behavior',
    options: behaviorOptions.filter((it) => isOptionAvailable(modalityID, it.value)),
  },
  {
    key: 'speech',
    title: 'Speech',
    options: speechOptions.filter((it) => isOptionAvailable(modalityID, it.value)),
  },
  {
    key: 'affect',
    title: 'Affect',
    options: affectOptions.filter((it) => isOptionAvailable(modalityID, it.value)),
  },
  { key: 'mood', title: 'Mood', options: moodOptions },
  { key: 'thoughtProcesses', title: 'Thought processes', options: thoughtProcessesOptions },
  { key: 'perception', title: 'Perception', options: perceptionOptions },
  {
    key: 'insightOrJudgement',
    title: 'Insight/judgement',
    options: insightOrJudgementOptions,
  },
];

export const defaultCategoryErrors: MentalExamCategoryErrors = {
  appearance: false,
  attitude: false,
  behavior: false,
  speech: false,
  affect: false,
  mood: false,
  thoughtProcesses: false,
  perception: false,
  insightOrJudgement: false,
};

export const validateCategories = (
  mentalStatusExam: ProgressNoteMentalStatusExamCreationAttributes | null
): MentalExamCategoryErrors => {
  if (!mentalStatusExam) {
    return defaultCategoryErrors;
  }

  return mentalStatusExamCategories.reduce<MentalExamCategoryErrors>((prev, acc) => {
    return {
      ...prev,
      [acc]: !mentalStatusExam[acc],
    };
  }, defaultCategoryErrors);
};

const MINIMUM_DESCRIPTION_LENGTH = 2;

export const defaultDescriptionErrors: MentalExamDescriptionErrors = {
  appearanceDescription: false,
  attitudeDescription: false,
  behaviorDescription: false,
  speechDescription: false,
  affectDescription: false,
  moodDescription: false,
  thoughtProcessesDescription: false,
  perceptionDescription: false,
  insightOrJudgementDescription: false,
};

export const validateOtherDescriptions = (
  mentalStatusExam: ProgressNoteMentalStatusExamCreationAttributes | null
): MentalExamDescriptionErrors => {
  if (!mentalStatusExam) {
    return defaultDescriptionErrors;
  }

  return mentalStatusExamCategories.reduce<MentalExamDescriptionErrors>((prev, acc) => {
    return {
      ...prev,
      [`${acc}Description`]:
        mentalStatusExam[acc] === 'other' &&
        (mentalStatusExam[`${acc}Description`]?.length || 0) < MINIMUM_DESCRIPTION_LENGTH,
    };
  }, defaultDescriptionErrors);
};
