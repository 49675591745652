import capitalize from 'lodash/capitalize';
import { FloatingMenuSimple } from '@talkspace/react-toolkit';
import { ArrowUpArrowDown } from '@talkspace/react-toolkit/src/designSystems/icons';
import { useSupervisedNotesTable } from './TableContext';
import { SORTABLES, SortBy } from './constants';

const getSortOptions = (onChange: (newValue: string) => void) =>
  SORTABLES.map((sortable) => {
    return {
      callback: () => onChange(sortable),
      optionLabel: capitalize(sortable),
      selectedLabel: `Sort: ${capitalize(sortable)}`,
      optionValue: sortable,
    };
  });

export default function SortMenu() {
  const { tableState, setTableState } = useSupervisedNotesTable();

  const handleSortChange = (value: SortBy) => {
    setTableState({ sortBy: value });
  };

  const options = getSortOptions(handleSortChange);

  return (
    <FloatingMenuSimple
      initialValue={tableState.sortBy}
      options={options}
      Icon={() => <ArrowUpArrowDown colorType="brand" />}
      hasCaretDown={false}
      buttonVariant="secondary"
      hideSelected={false}
      dataQa="sortMenu"
      buttonStyle={{ height: 40 }}
    />
  );
}
