import { Tag, TextDS, View } from '@talkspace/react-toolkit';

import { SupervisedNote } from '../../../hooks/notes/useQuerySupervisedNotes';

interface Props {
  status: SupervisedNote['status'];
  wordDuration?: number | null;
}

export default function StatusCell({ status, wordDuration }: Props) {
  return (
    <View row columnGap={12} align="center">
      <Tag
        text={status === 'needsRevision' ? 'Needs revision' : 'Needs approval'}
        color={status === 'needsRevision' ? 'criticalDefault' : 'successBoldDefault'}
      />

      {(wordDuration === 0 || Number(wordDuration) > 0) && (
        <TextDS variant="headingXs" colorRole="textSuccessDefault">
          {wordDuration} min
        </TextDS>
      )}
    </View>
  );
}
