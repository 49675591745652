import { useQuery } from 'react-query';
import apiWrapper from '../../utils/apiWrapper';
import apiHelper from '../../utils/api';
import { availableTalkTrack } from './queryKeys';

const POLLING_INTERVAL = 10 * 1000 * 60;

interface AvailableTalkTrackParams {
  roomID: string;
}

interface AvailableTalkTrackResponse {
  transcript: string;
  talktrackURL: string;
  roomID: number;
  talktrackID: number;
  liveCallID: number;
  status: 'error' | null;
  providerSuggestion: string | null;
  canRegenerate: boolean;
}

const fetchAvailableTalkTrack = (params: AvailableTalkTrackParams) => async () => {
  const { roomID } = params;
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/talktracks/get-available-talktrack`
  );
  return data.data;
};

const useQueryAvailableTalktrack = (params: AvailableTalkTrackParams) =>
  useQuery<AvailableTalkTrackResponse, Error>({
    queryKey: availableTalkTrack(params.roomID),
    queryFn: fetchAvailableTalkTrack(params),
    cacheTime: Infinity,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: Boolean(params.roomID),
    refetchInterval: POLLING_INTERVAL,
  });

export default useQueryAvailableTalktrack;
