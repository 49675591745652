import { styled, View } from '@talkspace/react-toolkit';

const TableContainer = styled(View)(({ theme: { colorRoles } }) => {
  return {
    background: colorRoles.surfaces.default,
    borderRadius: 7,
    border: `1px solid ${colorRoles.borders.borderDefault}`,
  };
});

export default TableContainer;
