import { Dispatch, createContext, useContext, useReducer, useMemo } from 'react';
import { ALL_ASSOCIATES_FILTER, SortBy } from './constants';
import useQuerySupervisedNotes, {
  SupervisedNote,
} from '../../../hooks/notes/useQuerySupervisedNotes';
import { getUserData } from '../../../utils/token';

const statusOrder: Record<SupervisedNote['status'], number> = {
  pendingReview: 0,
  needsRevision: 1,
};

const compareAssociate = (a: SupervisedNote, b: SupervisedNote) => {
  if (b.associate.name < a.associate.name) {
    return 1;
  }
  if (b.associate.name > a.associate.name) {
    return -1;
  }
  return 0;
};

const compareDatesOldestToNewest = (a: SupervisedNote, b: SupervisedNote) =>
  new Date(a.session.startDate).getTime() - new Date(b.session.startDate).getTime();

const compareDatesNewestToOldest = (a: SupervisedNote, b: SupervisedNote) =>
  new Date(b.session.startDate).getTime() - new Date(a.session.startDate).getTime();

const compareStatus = (a: SupervisedNote, b: SupervisedNote) => {
  const statusA = statusOrder[a.status] === undefined ? Infinity : statusOrder[a.status];
  const statusB = statusOrder[b.status] === undefined ? Infinity : statusOrder[b.status];

  if (statusA !== statusB) {
    return statusA - statusB;
  }

  return compareDatesOldestToNewest(a, b);
};

export const getSortedArray = (field: SortBy, array: SupervisedNote[]) => {
  switch (field) {
    case 'associate':
      return array.sort(compareAssociate);
    case 'oldest':
      return array.sort(compareDatesOldestToNewest);
    case 'newest':
      return array.sort(compareDatesNewestToOldest);
    case 'status':
      return array.sort(compareStatus);
    default:
      return array;
  }
};

interface SupervisedNotesTableState {
  filterByUserID: string;
  sortBy: SortBy;
  selectedNotes: SupervisedNote[];
  tableData: SupervisedNote[];
  areAllRowsSelected: boolean;
}

const initialState: SupervisedNotesTableState = {
  filterByUserID: ALL_ASSOCIATES_FILTER,
  sortBy: 'associate',
  selectedNotes: [],
  tableData: [],
  areAllRowsSelected: false,
};

const StateContext = createContext<SupervisedNotesTableState>(initialState);
const DispatchContext = createContext<Dispatch<Partial<SupervisedNotesTableState>>>(
  () => initialState
);

export function SupervisedNotesTableProvider({ children }) {
  const therapistID = getUserData().id;

  const { data: supervisedNotes = [] } = useQuerySupervisedNotes(therapistID);

  const [state, dispatch] = useReducer(
    (currState: SupervisedNotesTableState, newState: Partial<SupervisedNotesTableState>) => {
      return {
        ...currState,
        ...newState,
      };
    },
    { ...initialState, tableData: supervisedNotes || [] }
  );

  const filteredData = useMemo(
    () =>
      state.filterByUserID === ALL_ASSOCIATES_FILTER
        ? supervisedNotes
        : supervisedNotes.filter((it) => it.associate.id.toString() === state.filterByUserID),
    [state.filterByUserID, supervisedNotes]
  );

  const sortedData = useMemo(
    () => getSortedArray(state.sortBy, filteredData),
    [filteredData, state.sortBy]
  );

  const areAllRowsSelected = useMemo(
    () =>
      state.selectedNotes.length > 0 &&
      state.selectedNotes.length ===
        (state.tableData?.filter((it) => it.status === 'pendingReview')?.length || 0),
    [state.selectedNotes.length, state.tableData]
  );

  return (
    <StateContext.Provider value={{ ...state, tableData: sortedData, areAllRowsSelected }}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
}

export function useSupervisedNotesTable() {
  return { tableState: useContext(StateContext), setTableState: useContext(DispatchContext) };
}
