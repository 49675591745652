import { View, TextBox, getSpacing, Button } from '@talkspace/react-toolkit';
import { useCallback, useEffect, useState } from 'react';
import {
  useProgressNoteFormActions,
  useProgressNoteFormState,
} from '../context/TherapistProgressNoteFormContext';
import { useRouteMatch } from '@/core/routerLib';
import { Section } from '../context/types';
import useDidUpdateEffect from '../../../../../../hooks/useDidUpdateEffect';
import useQueryProgressNoteHelperData from '../../../../../../hooks/notes/useQueryProgressNoteHelperData';

const diagnosticIntakeFields = [
  {
    id: 'presentingProblems',
    label: 'Presenting problems',
    placeholderText:
      'Why are they seeking therapy? Use direct quotes and your assessment of the presenting issue',
  },
  {
    id: 'historyOfPresentingProblems',
    label: 'History of presenting problem',
    placeholderText:
      'Their experience with presenting problems and any past clinical course and treatment services',
  },
  {
    id: 'psychiatricHistory',
    label: 'Psychiatric history',
    placeholderText:
      'Symptoms, diagnoses, treatment services, hospitalizations, response to treatment, current and past medications and supplements prescribed, current and past service providers',
  },
  {
    id: 'medicalHistory',
    label: 'Medical history',
    placeholderText:
      'Current and past serious or chronic medical conditions, medications, supplements, surgeries, hospitalizations, providers, perception of medical community',
  },
  {
    id: 'familyHistory',
    label: 'Family history',
    placeholderText:
      'Family of origin, past and current dynamics, any known medical or mental health conditions, etc.',
  },
  {
    id: 'substanceUseHistory',
    label: 'Substance use history',
    placeholderText:
      'Use, types, frequency, amount, social context, triggers, treatment, client’s perception of use',
  },
  {
    id: 'psychosocialDevelopmentalHistory',
    label: 'Psychosocial and developmental history',
    placeholderText:
      'Social and environmental factors, developmental history, education and work history, sexual and relationship history',
  },
  {
    id: 'legalHistory',
    label: 'Legal history',
    placeholderText: 'Interactions with the court system; criminal or otherwise',
  },
  {
    id: 'traumaHistory',
    label: 'Trauma history',
    placeholderText: 'Traumatic experiences that have impacted client',
  },
];

interface DiagnosticSectionProps {
  nextSection: Section;
  isOpen?: boolean;
  isInitialSession: boolean;
  onOpen: () => void;
}

const DiagnosticSection = ({
  nextSection,
  isOpen = true,
  isInitialSession,
  onOpen,
}: DiagnosticSectionProps) => {
  const match = useRouteMatch<{ roomID: string; noteID?: string }>();
  const { data: helperData } = useQueryProgressNoteHelperData(match.params.roomID, true);
  const { diagnosticIntake, isSaveAsDraftPressed, currentSection, formMode } =
    useProgressNoteFormState();
  const { setFormState } = useProgressNoteFormActions();
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (helperData?.diagnosticIntake) {
      setFormState({ diagnosticIntake: helperData.diagnosticIntake });
    }
  }, [helperData, setFormState]);

  const handleConfirmPress = useCallback(() => {
    const isEmpty = diagnosticIntake
      ? Object.values(diagnosticIntake).some((value) => !value)
      : true;

    if (isEmpty && isInitialSession && !isSaveAsDraftPressed) {
      setFormState({ showGlobalError: true });
      setIsError(true);
      onOpen();
    }
  }, [diagnosticIntake, isSaveAsDraftPressed, isInitialSession, setFormState, onOpen]);

  useDidUpdateEffect(() => {
    if (currentSection === 'diagnosticIntake') {
      handleConfirmPress();
      setFormState({ currentSection: nextSection });
    }
  }, [currentSection]);

  if (!diagnosticIntake) return null;

  return (
    <View>
      {isOpen ? (
        <>
          {diagnosticIntakeFields.map((item) => (
            <TextBox
              containerStyle={{ marginTop: getSpacing('space150') }}
              key={item.id}
              text={diagnosticIntake?.[item.id]}
              label={item.label}
              onTextChange={(value) =>
                setFormState({
                  diagnosticIntake: { ...diagnosticIntake, [item.id]: value },
                })
              }
              placeholderText={item.placeholderText}
              tooltipText={item.placeholderText}
              isError={isError && !diagnosticIntake?.[item.id]}
              errorMessage="Required. If not reported, type “Nothing reported.”"
              disabled={formMode === 'view'}
            />
          ))}
        </>
      ) : (
        <View style={{ marginTop: 16 }}>
          <Button
            text={!isInitialSession ? 'Edit the evaluation' : 'Complete an evaluation'}
            variant="secondary"
            stretch
            onPress={onOpen}
          />
        </View>
      )}
    </View>
  );
};

export default DiagnosticSection;
