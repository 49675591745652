import {
  View,
  Checkbox,
  TextAreaRounded,
  EmotionStyle,
  ExtraTiny,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import IndentedView from '../../IndentedView';
import { getTextAreaContainerBorderStyle, getTextAreaStyle } from '../../utils';

// Psych MSE doesn't have "not_observed" option
const isOptionAvailable = (value: string): boolean => value !== 'not_observed';

interface Props<T extends string> {
  title: string;
  options: Record<T, string>;
  description: string | null;
  value: T | null;
  setIsChecked: (option: T, newValue: boolean) => void;
  setDescription: (newValue: string) => void;
  disabled: boolean;
  style?: EmotionStyle;
  isDescriptionError?: boolean;
}

const MentalStatusExamQuestion = <T extends string>({
  title,
  options,
  value,
  description,
  setIsChecked,
  setDescription,
  disabled,
  isDescriptionError = false,
  style = {},
}: Props<T>) => {
  const { colors } = useEmotionTheme();

  return (
    <View style={{ marginBottom: 15, ...style }}>
      <ExtraTiny style={{ marginBottom: 12 }}>{title}</ExtraTiny>
      {(Object.keys(options) as Array<T>).filter(isOptionAvailable).map((key) => (
        <View key={key} style={{ marginBottom: 12, color: 'blue', alignSelf: 'flex-start' }}>
          <Checkbox
            containerStyle={{ alignSelf: 'flex-start' }}
            isLabelOnRight
            label={options[key]}
            isChecked={key === value}
            setIsChecked={(newValue) => setIsChecked(key, newValue)}
            isDisabled={disabled}
            dataQa={`${options[key]}Checkbox`}
          />
        </View>
      ))}
      {value === 'other' && (
        <IndentedView>
          <TextAreaRounded
            autoSize
            style={{
              ...getTextAreaContainerBorderStyle(colors, isDescriptionError),
              marginBottom: 10,
            }}
            value={description || ''}
            onChangeText={setDescription}
            textAreaStyle={getTextAreaStyle(disabled)}
            disabled={disabled}
            placeholder="Add a description"
            hasLimit
            dataQa="mentalStatusTextArea"
          />
        </IndentedView>
      )}
    </View>
  );
};

export default MentalStatusExamQuestion;
