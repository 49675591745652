import {
  Card,
  ChevronRight,
  getSpacing,
  Headphones,
  TextDS,
  View,
  usePanelManagerActions,
} from '@talkspace/react-toolkit';
import { connect } from 'react-redux';
import CircleUnderlay from '@talkspace/react-toolkit/src/designSystems/components/CircleUnderlay';
import { Dispatch, SetStateAction } from 'react';
import { useParams } from '@/core/routerLib';
import useQueryAvailableTalktrack from '../../../../hooks/talkTrack/useQueryAvailableTalktrack';

interface TalktrackCardProps {
  dispatch: Dispatch<SetStateAction<{ type?: string; completedVideoCallID?: number }>>;
}

const TalktrackCard = ({ dispatch }: TalktrackCardProps) => {
  const { roomID } = useParams<{ roomID: string }>();
  const { data, isLoading } = useQueryAvailableTalktrack({ roomID });
  const { setIsLeftPanelOnTop, setIsLargeRightPanel, setIsRightPanelOpen } =
    usePanelManagerActions();

  const handleCardPress = () => {
    if (data) {
      dispatch({
        type: 'OPEN_POST_LVS_TALK_TRACK',
        completedVideoCallID: data.liveCallID,
      });
      setIsLeftPanelOnTop(false);
      setIsLargeRightPanel(true);
      setIsRightPanelOpen(true);
    }
  };

  if (isLoading || !data) {
    return null;
  }

  return (
    <View style={{ marginTop: getSpacing('space300') }}>
      <Card padding={0} onPress={handleCardPress}>
        <View row style={{ gap: 16, padding: '16px 6px 16px 8px' }}>
          <CircleUnderlay colorRole="decorativeGratitudeGreenTint">
            <Headphones />
          </CircleUnderlay>

          <View flex={1}>
            <View row align="start" style={{ gap: 8 }}>
              <TextDS style={{ marginBottom: 8 }} variant="headingMd">
                Talkcast episode available
              </TextDS>
              <ChevronRight colorType="brand" />
            </View>

            <TextDS variant="bodySm">
              Explore an AI-generated audio episode to keep your client engaged
            </TextDS>
          </View>
        </View>
      </Card>
    </View>
  );
};

const mapStateToProps = () => {
  return {};
};

const TsTalktrackCard = connect(mapStateToProps)(TalktrackCard);
export default TsTalktrackCard;
