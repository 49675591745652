import moment from 'moment';
import { Small, Tag, TextDS, TooltipV2, View } from '@talkspace/react-toolkit';
import CircleInfo from '@talkspace/react-toolkit/src/designSystems/icons/CircleInfo';
import { useState } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useHistory, useParams } from '@/core/routerLib';
import useQueryRiskIndications from '../../../../hooks/useQueryRiskIndications';
import { RiskIndicationSource, RiskIndicationType } from '../../../../types/clinical';
import { TextLink } from '../NotesTab/ProgressNoteForm/componentsV2/styles';

const riskLabels: Record<RiskIndicationType, string> = {
  'suicidal-ideation': 'Suicidal ideation',
  'suicidal-planning': 'Suicidal planning',
  'homicidal-ideation': 'Homicidal ideation',
  'homicidal-planning': 'Homicidal planning',
  'risk-mentioned': 'Risk mentioned',
};

const formatRiskSourceAndDate = (riskDate: string, source: RiskIndicationSource) => {
  const riskMoment = moment(riskDate);
  const currentYear = moment().year();

  const formattedDate =
    riskMoment.year() === currentYear
      ? riskMoment.format('MMM D')
      : riskMoment.format('MMM D, YYYY');

  if (source === 'intake') {
    return `${formattedDate} on intake`;
  }

  if (source === 'session') {
    return `${formattedDate} in the chat`;
  }

  return `${formattedDate} in a progress note`;
};

export default function RiskInfo() {
  const { roomID } = useParams<{ roomID: string }>();

  const { data: riskIndications = [] } = useQueryRiskIndications(Number(roomID));

  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const { riskAssessments } = useFlags();

  const history = useHistory();

  return (
    <View style={{ gap: 4 }}>
      <View row align="center" style={{ gap: 2 }}>
        <TextDS variant="headingMd">Risk</TextDS>
        <View
          row
          align="center"
          style={{ height: 40 }}
          onMouseEnter={() => setIsTooltipOpen(true)}
          onMouseLeave={() => setIsTooltipOpen(false)}
        >
          <TooltipV2
            iconComponent={<CircleInfo colorType="subtlest" />}
            circleStyle={{ height: 30, width: 30 }}
            styles={{ marginLeft: 9 }}
            shouldDisplayCloseButton={false}
            containerStyle={{ left: -50, width: 293, height: 152, top: 25 }}
            toolTipText={
              <Small variant="smallTSBlack" style={{ marginTop: 4 }}>
                Risk information is based on the client’s intake, messages sent in the chat, and
                information in progress notes.{' '}
                {riskAssessments && (
                  <View style={{ marginTop: 16 }}>
                    <TextDS variant="bodySm" style={{ marginTop: 4 }}>
                      View{' '}
                      <TextLink
                        onClick={() => {
                          history.push(`/room/${roomID}/clinical-tab`, {
                            section: 'risk-and-safety',
                          });
                        }}
                      >
                        Risk and Safety
                      </TextLink>{' '}
                      to conduct a risk assessment or create a safety plan.
                    </TextDS>
                  </View>
                )}
              </Small>
            }
            isTooltipOpen={isTooltipOpen}
          />
        </View>
      </View>

      <View style={{ gap: 4 }}>
        {riskIndications.length > 0 ? (
          riskIndications.map((riskIndication) => (
            <View row key={riskIndication.type} style={{ gap: 4 }}>
              <Tag text={riskLabels[riskIndication.type]} color="criticalDefault" />

              <TextDS variant="bodySm" colorRole="textSubtle">
                {formatRiskSourceAndDate(riskIndication.date, riskIndication.source)}
              </TextDS>
            </View>
          ))
        ) : (
          <TextDS variant="bodySm" colorRole="textSubtle">
            None indicated
          </TextDS>
        )}
      </View>
    </View>
  );
}
