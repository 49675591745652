import { Button, styled, View } from '@talkspace/react-toolkit';
import { useState } from 'react';

import { useSupervisedNotesTable } from './TableContext';
import BulkConfirmationDialog from './BulkConfirmationDialog';

const MainView = styled(View)(({ theme: { colorRoles } }) => {
  return {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 72,
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    padding: '32px 16px',
    borderTop: `1px solid ${colorRoles.borders.borderDefault}`,
  };
});

export default function BulkActionView() {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const { tableState } = useSupervisedNotesTable();

  if (tableState.selectedNotes.length === 0) {
    return null;
  }

  return (
    <>
      <MainView>
        <View>{tableState.selectedNotes.length} selected</View>

        <View>
          <Button
            text={tableState.areAllRowsSelected ? 'Approve all' : 'Approve selected'}
            dataQa="approveAllButton"
            onPress={() => setIsConfirmationModalOpen(true)}
            style={{ height: 40 }}
          />
        </View>
      </MainView>

      <BulkConfirmationDialog
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
      />
    </>
  );
}
