import { View, styled } from '@talkspace/react-toolkit';
import { formatSessionDate } from '../utils';

interface DateOfSessionCellProps {
  startDate: Date;
  endDate?: Date | null;
  shouldDisplayRange: boolean;
}

const Container = styled(View)(({ theme: { colors, window } }) => {
  const { isDesktop } = window;
  return {
    fontSize: isDesktop ? 14 : 15,
    fontWeight: isDesktop ? 400 : 500,
    color: isDesktop ? colors.purple600 : colors.black,
    height: isDesktop && 30,
    display: 'flex',
    justifyContent: 'center',
  };
});

const DateOfSessionCell = ({ startDate, endDate, shouldDisplayRange }: DateOfSessionCellProps) => (
  <Container>{formatSessionDate({ startDate, endDate, shouldDisplayRange })}</Container>
);

export default DateOfSessionCell;
