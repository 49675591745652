import { useQuery } from 'react-query';
import { LiveSessionModality } from 'ts-frontend/types';
import { AccountType } from 'ts-frontend/entities/Room';
import apiWrapper from '../../utils/apiWrapper';
import apiHelper from '../../utils/api';
import { supervisedNotesQueryKey } from './queryKeys';

export interface SupervisedNote {
  id: number;
  roomID: number;
  associate: { id: number; name: string };
  session: {
    startDate: string;
    endDate: string;
    modality: 'live' | 'messaging';
    liveSessionType?: LiveSessionModality;
    wordCount?: number | null;
    wordDuration?: number | null;
  };
  client: {
    id: number;
    name: string;
    preferredName: string;
    nickname: string;
    isTransferred: boolean;
    isDischarged: boolean;
  };
  accountType: AccountType;
  status: 'pendingReview' | 'needsRevision';
}

const fetchSupervisedNotes = (therapistID: number) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/therapists/${therapistID}/supervised-notes`
  );
  return data.data;
};

const ONE_MINUTE_IN_MS = 60 * 1000;

export default function useQuerySupervisedNotes(therapistID: number, enabled = true) {
  return useQuery<SupervisedNote[], Error>({
    queryKey: supervisedNotesQueryKey(therapistID),
    queryFn: fetchSupervisedNotes(therapistID),
    staleTime: 5 * ONE_MINUTE_IN_MS,
    cacheTime: 10 * ONE_MINUTE_IN_MS,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled,
  });
}
