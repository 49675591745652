import { useState, useEffect, FunctionComponent, Dispatch } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { Room, RoomStatus } from 'ts-frontend/entities/Room';
import { TherapistType } from 'ts-frontend/entities/Therapist';
import {
  View,
  usePanelManagerActions,
  EmotionThemeProvider,
  FeedbackModal as TalktrackFeedbackModal,
} from '@talkspace/react-toolkit';
import useTalktrackFeedbackReminder from 'ts-frontend/hooks/useTalktrackFeedbackReminder';
import useQueryProgressNoteHelperData from 'hooks/notes/useQueryProgressNoteHelperData';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { PromiseMessageTypeNames } from 'ts-promise-message';
import { useFlags } from 'launchDarkly/FlagsProvider';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import { useParams } from '@/core/routerLib';
import getAdminConfigValue from '../../../utils/adminConfig';
import CaseTab from './CaseTab/CaseTab';
import ClinicalTab from './ClinicalTab/ClinicalTab';
import NotesTab from './NotesTab/NotesTab';
import LVSTab from './LVSTab/LVSTab';
import styled from '../../../core/styled';
import './CrmMenu.css';
import useQueryNotes from '../../../hooks/notes/useQueryNotes';
import InsightsTab from './InsightsTab/InsightsTab';
import useSmartInsightsFeedbackReminder from '../../../hooks/useSmartInsightsFeedbackReminder';
import FeedbackModal from './InsightsTab/FeedbackModal';
import useCrmModalQueue, { StateAction } from './hooks/useCrmModalQueue';

import { feedbackTypes, feedbackCheckboxes, textAreaTitles } from '../Talktrack/utils';
import { getUserData } from '../../../utils/token';
import useQueryAvailableTalktrack from '../../../hooks/talkTrack/useQueryAvailableTalktrack';

const CrmRoom = styled(View)(({ theme: { colors } }) => {
  return {
    padding: 0,
    height: 'auto',
    backgroundColor: colors.white,
    alignItems: 'center',
    flex: 1,
  };
});

interface RoomStore extends Room {
  isPostLVSPromptOpen: boolean;
  isPostLVSTalkTrackOpen: boolean;
}

interface CrmMenuProps extends RouteComponentProps {
  isQuickmatch: boolean;
  therapistType: TherapistType;
  room: RoomStore;
  dispatch: Dispatch<any>;
}

const ROOM_STATUS_ACTIVE_LIST = [
  RoomStatus.ACTIVE,
  RoomStatus.PENDING_INACTIVE,
  RoomStatus.NOT_RENEWING,
  RoomStatus.CC_SUCCESS,
  RoomStatus.PAST_DUE,
  RoomStatus.FROZEN,
  RoomStatus.FREE_TRIAL_CHATBOT,
];

const CrmMenu: FunctionComponent<CrmMenuProps> = (props) => {
  const { match, location, isQuickmatch, therapistType, room, dispatch } = props;
  const { roomID } = useParams<{ roomID: string }>();
  const [isProviderScheduleBookingFlagOn, setProviderScheduleBookingFlag] = useState(false);
  const { setIsLeftPanelOnTop, setIsLargeRightPanel, setIsRightPanelOpen } =
    usePanelManagerActions();
  const { data: { hasHadFirstSession } = {} } = useQueryRoomDetails(roomID);
  const { data: talkTrackAvailable, refetch: refetchTalktrack } = useQueryAvailableTalktrack({
    roomID,
  });

  const { refetch } = useQueryProgressNoteHelperData(roomID, false);

  const { data: notesData } = useQueryNotes(roomID);

  const { nycTeensIntake } = useFlags();

  const { showReminder, dismissReminder, submitFeedback } = useSmartInsightsFeedbackReminder();

  const userID = getUserData()?.id;

  const { showTalktrackReminder, dismissTalktrackReminder, handleSubmitTalktrackFeedback } =
    useTalktrackFeedbackReminder('provider', userID);

  const handleNextAction = (nextAction: StateAction) => {
    dispatch(nextAction);
    dispatch({ type: 'CLOSE_STARRED_MESSAGES' });
    dispatch({ type: 'CLOSE_SHARED_FILES' });
    setIsLeftPanelOnTop(false);
    setIsLargeRightPanel(true);
    setIsRightPanelOpen(true);
  };

  const { addToQueue } = useCrmModalQueue({
    onNextAction: handleNextAction,
    isFirstModalOpen: room.isPostLVSTalkTrackOpen,
  });

  const openStarredMessages = () => {
    if (ReactFrameService.instance().isInFrame()) {
      ReactFrameService.instance().sendAsyncMessage(PromiseMessageTypeNames.loadStarredMessagesVC, {
        roomID: Number(roomID),
      });
      return;
    }
    dispatch({ type: 'CLOSE_SHARED_FILES' });
    dispatch({ type: 'OPEN_STARRED_MESSAGES' });
    setIsLeftPanelOnTop(false);
    setIsRightPanelOpen(true);
    setIsLargeRightPanel(true);
  };

  const openSharedFiles = () => {
    if (ReactFrameService.instance().isInFrame()) {
      ReactFrameService.instance().sendAsyncMessage(PromiseMessageTypeNames.loadSharedFilesVC, {
        roomID: Number(roomID),
      });
      return;
    }
    dispatch({ type: 'CLOSE_STARRED_MESSAGES' });
    dispatch({ type: 'OPEN_SHARED_FILES' });
    setIsLeftPanelOnTop(false);
    setIsRightPanelOpen(true);
    setIsLargeRightPanel(true);
  };

  useEffect(() => {
    const handleLiveSessionStarted = () => {
      refetch();
    };

    document.addEventListener('liveSessionStarted', handleLiveSessionStarted);

    return () => {
      document.removeEventListener('liveSessionStarted', handleLiveSessionStarted);
    };
  }, [refetch]);

  useEffect(() => {
    const handleVideoCallEnded = async (e: any) => {
      const { data } = await refetchTalktrack();

      if (e.detail.shouldOpenTalkTrack && data) {
        addToQueue({
          type: 'OPEN_POST_LVS_TALK_TRACK',
          completedVideoCallID: e.detail.videoCallID,
        });
      }
      if (e.detail.shouldOpenPostLVSPrompt) {
        addToQueue({
          type: 'OPEN_POST_LVS_PROMPT',
          completedVideoCallID: e.detail.videoCallID,
        });
      }
    };

    document.addEventListener('videoCallEnded', handleVideoCallEnded);

    return () => {
      document.removeEventListener('videoCallEnded', handleVideoCallEnded);
    };
  }, [addToQueue, refetchTalktrack, talkTrackAvailable]);

  useEffect(() => {
    let mounted = true;
    getAdminConfigValue('therapist_schedule_lvs')
      .then((flagValue) => {
        if (mounted) setProviderScheduleBookingFlag(!!flagValue);
      })
      .catch();
    return () => {
      mounted = false;
    };
  }, []);

  const shouldAllowBookings =
    ROOM_STATUS_ACTIVE_LIST.includes(room.status) ||
    room.isVideoOnly ||
    room.roomType === 'psychiatry_room';

  const { pathname } = location;
  // if the pathname and match.url are both room/:roomID forward to agenda tab
  if (pathname === match.url) {
    const isProviderPsych = therapistType === 'psychiatrist';
    const seperator = pathname.slice(-1) === '/' ? '' : '/';
    if (isProviderPsych) {
      return <Redirect to={`${pathname}${seperator}clinical-tab`} />;
    }

    return <Redirect to={`${pathname}${seperator}insights-tab`} />;
  }
  // remove trailing slashes
  if (pathname.substr(pathname.length - 1, pathname.length) === '/') {
    return <Redirect to={`${pathname.substr(0, pathname.length - 1)}`} />;
  }

  return (
    <>
      <CrmRoom>
        <Route path={`${match.path}/insights-tab`} component={InsightsTab} />
        <Route
          path={`${match.path}/case-tab`}
          render={() => (
            <CaseTab
              isQuickmatch={isQuickmatch}
              therapistType={therapistType}
              openStarredMessagesHandler={openStarredMessages}
              openSharedFilesHandler={openSharedFiles}
              isClientDischarged={notesData?.isClientDischarged}
            />
          )}
        />
        <Route
          path={`${match.path}/clinical-tab`}
          render={() => <ClinicalTab nycTeensIntake={nycTeensIntake} />}
        />
        <Route path={`${match.path}/notes-tab`} component={NotesTab} />
        <Route
          path={`${match.path}/lvs-tab`}
          render={() => (
            <LVSTab
              isProviderScheduleBookingFlagOn={isProviderScheduleBookingFlagOn}
              shouldAllowBookings={shouldAllowBookings}
              hasHadFirstSession={Boolean(hasHadFirstSession)}
            />
          )}
        />
      </CrmRoom>

      <EmotionThemeProvider version="2.0.0">
        <FeedbackModal
          isOpen={showReminder}
          onClose={dismissReminder}
          onSubmit={submitFeedback}
          initialType={null}
          title="How was your experience using Smart Insights?"
          subtitle="Your feedback helps us understand your needs."
          showModalTypes
        />

        <TalktrackFeedbackModal
          isOpen={showTalktrackReminder}
          onClose={dismissTalktrackReminder}
          onSubmit={handleSubmitTalktrackFeedback}
          title="What did you think of the Talkcast episode?"
          subtitle="Your feedback helps us improve"
          feedbackTypes={feedbackTypes}
          feedbackCheckboxes={feedbackCheckboxes}
          textAreaTitles={textAreaTitles}
        />
      </EmotionThemeProvider>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isQuickmatch: state.room.isQuickmatch,
    therapistType: state.availability.therapistType,
    room: state.room,
  };
};

const TsCrmMenu = connect(mapStateToProps)(CrmMenu);

export default TsCrmMenu;
