import { FunctionComponent } from 'react';
import { Standard, styled, TouchableView, View, TrashIcon, TextDS } from '@talkspace/react-toolkit';
import Footer from 'components/Reusable/Footer/Footer';
import AlertContainer from 'components/Reusable/AlertContainer/AlertContainer';
import { NoteError, NoteStatus } from './types';
import { useProgressNoteFormState } from './ProgressNoteForm/context/TherapistProgressNoteFormContext';

const FooterWrapper = styled(View)({
  justifyContent: 'space-between',
  flexDirection: 'row',
});

const SecondaryButton = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    color: colors.accessibilityGreenDark,
    fontWeight: 700,
    fontSize: 15,
  };
});

const CancelSaveButton = styled(TouchableView)({
  paddingTop: 5,
  paddingBottom: 5,
  alignItems: 'center',
  justifyContent: 'center',
  width: 49,
});

const CancelButtonText = styled(Standard)(({ theme: { colors } }) => {
  return {
    paddingTop: 5,
    paddingBottom: 5,
    color: colors.permaSilverSand,
    fontWeight: 700,
  };
});

const PrimaryButton = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    background: colors.accessibilityGreenDark,
    borderRadius: 8,
    height: 30,
    width: 'max-content',
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 30,
    paddingRight: 30,
    color: colors.white,
    fontWeight: 700,
    fontSize: 14,
    marginLeft: 20,
    lineHeight: '18px',
  };
});
const CloseButton = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    paddingTop: 5,
    paddingBottom: 5,
    color: colors.accessibilityGreenDark,
    fontWeight: 700,
    fontSize: 15,
  };
});

const getContainerStyle = () => {
  return {
    width: 355,
    border: 'none',
    paddingTop: 15,
    paddingLeft: 9,
    paddingRight: 19,
  };
};

interface Props {
  noteStatus?: NoteStatus;
  canDeleteDraft: boolean;
  isError: boolean;
  isLoading: boolean;
  showSpinner: boolean;
  spinnerMessage: string;
  editHidden?: boolean;
  handleCloseErrorBannerPress: () => void;
  handleDeleteDraftPress: () => void;
  handleSaveDraftPress: () => void;
  handleSubmitPress: () => void;
  handleCancelPress: () => void;
  handleSavePress: () => void;
  handleEditPress: () => void;
  handleErrorAlertPress?: () => void;
  showErrorAlert?: boolean;
  error?: NoteError;
  displaySaveDraft?: boolean;
  displayCloseButton?: boolean;
  displaySubmit?: boolean;
  handleClose?: () => void;
  handleNeedsRevisionPress?: () => void;
}

const NoteFormFooterV2: FunctionComponent<Props> = ({
  noteStatus,
  canDeleteDraft,
  isError,
  isLoading,
  showSpinner,
  spinnerMessage,
  editHidden,
  handleCloseErrorBannerPress,
  handleDeleteDraftPress,
  handleSaveDraftPress,
  handleSubmitPress,
  handleCancelPress,
  handleSavePress,
  handleEditPress,
  handleErrorAlertPress,
  showErrorAlert,
  error,
  displaySaveDraft = true,
  displayCloseButton = false,
  displaySubmit = true,
  handleClose,
  handleNeedsRevisionPress,
}) => {
  const { formMode, formVariant, showGlobalError } = useProgressNoteFormState();

  const containerStyle = getContainerStyle();

  const renderFooterChildren = () => [
    <FooterWrapper>
      {(() => {
        if (showErrorAlert) {
          return (
            <AlertContainer
              title={error?.title || 'Missing information'}
              childComponents={[<CloseButton onPress={handleErrorAlertPress}>Close</CloseButton>]}
              body={error?.message || 'Review and complete the required fields to continue'}
              containerStyle={containerStyle}
            />
          );
        }

        if (showGlobalError) {
          return (
            <AlertContainer
              title="Missing information"
              childComponents={[
                <CloseButton onPress={handleCloseErrorBannerPress} dataQa="closeButton">
                  Close
                </CloseButton>,
              ]}
              body="Review and complete the required fields to continue"
              containerStyle={containerStyle}
            />
          );
        }

        if (formVariant === 'supervisor') {
          if (noteStatus === 'needsRevision') {
            return (
              <View flex={1} align="center" justify="center">
                <TextDS variant="bodySm">Needs revision</TextDS>
              </View>
            );
          }

          if (noteStatus === 'pendingReview') {
            return (
              <View flex={1} row align="center" justify="end">
                <SecondaryButton key={2} onPress={handleNeedsRevisionPress}>
                  Needs revision
                </SecondaryButton>

                <PrimaryButton key={3} onPress={handleSubmitPress} dataQa="submitButton">
                  Approve
                </PrimaryButton>
              </View>
            );
          }

          return null;
        }

        if (
          formMode === 'create' ||
          noteStatus === 'draft' ||
          noteStatus === 'needsRevision' ||
          noteStatus === 'pendingReview'
        ) {
          return (
            <>
              {canDeleteDraft && (
                <TouchableView onPress={handleDeleteDraftPress} dataQa="deleteDraftButton">
                  <TrashIcon key={1} />
                </TouchableView>
              )}
              <View row align="center" justify="end" style={{ width: !canDeleteDraft && '100%' }}>
                {displaySaveDraft && (
                  <SecondaryButton key={2} onPress={handleSaveDraftPress} dataQa="saveDraftButton">
                    Save draft
                  </SecondaryButton>
                )}
                {displayCloseButton && (
                  <SecondaryButton key={2} onPress={handleClose && handleClose}>
                    Close
                  </SecondaryButton>
                )}
                {displaySubmit && (
                  <PrimaryButton key={3} onPress={handleSubmitPress} dataQa="submitButton">
                    {formVariant === 'associate' ? 'Submit for review' : 'Submit'}
                  </PrimaryButton>
                )}
              </View>
            </>
          );
        }

        if (noteStatus === 'submitted') {
          if (formMode === 'edit') {
            return (
              <>
                <View />
                <View row align="center">
                  <CancelSaveButton key={2} onPress={handleCancelPress} dataQa="cancelButton">
                    <CancelButtonText>Cancel</CancelButtonText>
                  </CancelSaveButton>
                  <PrimaryButton key={3} onPress={handleSavePress} dataQa="saveButton">
                    Save
                  </PrimaryButton>
                </View>
              </>
            );
          }

          if (formMode === 'view') {
            return (
              <>
                <View />
                <View row align="center">
                  <PrimaryButton onPress={handleEditPress} hide={editHidden} dataQa="editButton">
                    Edit
                  </PrimaryButton>
                </View>
              </>
            );
          }
        }
        return null;
      })()}
    </FooterWrapper>,
  ];

  return (
    <Footer
      childComponents={renderFooterChildren()}
      isError={isError}
      isUpdating={isLoading}
      showSpinner={showSpinner}
      spinnerMessage={spinnerMessage}
      footerChildStyle={{ width: '100%' }}
      footerStyle={{ height: showGlobalError || showErrorAlert ? 'auto' : undefined }}
    />
  );
};

export default NoteFormFooterV2;
