import {
  View,
  useWindowWidthState,
  Tag,
  styled,
  TextDS,
  EmotionTheme,
} from '@talkspace/react-toolkit';

import { SupervisedNote } from '../../../hooks/notes/useQuerySupervisedNotes';

const getMaxContainerWidth = (window: EmotionTheme['window']) => {
  const { isDesktop, isSmall } = window;

  if (isDesktop) {
    return 250;
  }

  if (isSmall) {
    return 100;
  }

  return 120;
};

const TitleView = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  gap: 4,
});

const Title = styled(TextDS)(({ theme: { window } }) => {
  return {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: getMaxContainerWidth(window),
  };
});

interface Props {
  client: SupervisedNote['client'];
}

export default function ClientCell({
  client: { name, preferredName, nickname, isDischarged, isTransferred },
}: Props) {
  const { isDesktop } = useWindowWidthState();

  let title: string;
  let subtitle: string;

  if (name) {
    title = name;
    subtitle = preferredName || nickname;
  } else {
    title = nickname;
    subtitle = preferredName;
  }

  const tags = (
    <>
      {isDischarged && <Tag text="Discharged" color="infoDefault" />}
      {isTransferred && <Tag text="Transferred" color="infoDefault" />}
    </>
  );

  if (isDesktop) {
    return (
      <View>
        <TitleView>
          <Title variant="headingSm">{title}</Title>
          {tags}
        </TitleView>

        <TextDS variant="bodyXs" colorRole="textSubtle">
          {subtitle || 'Pending client name'}
        </TextDS>
      </View>
    );
  }

  return (
    <View>
      <TitleView>
        <Title variant="bodySm" colorRole="textSubtle">
          {title}
        </Title>
        {tags}
      </TitleView>
    </View>
  );
}
