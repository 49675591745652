import { Spinner } from '@talkspace/react-toolkit';
import { FunctionComponent } from 'react';
import useQueryProgressNoteHelperData, {
  GetProgressNoteHelperDataResponse,
} from 'hooks/notes/useQueryProgressNoteHelperData';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import useQueryNotes from 'hooks/notes/useQueryNotes';
import useQueryProgressNote from 'hooks/notes/useQueryProgressNote';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { Route, Switch, useHistory, useRouteMatch } from '@/core/routerLib';
import ErrorScreen from '../ErrorScreen';
import DeleteProgressNoteDialog from '../DeleteProgressNoteDialog';
import SaveProgressNoteDialog from '../SaveProgressNoteDialog';
import { FormMode, ProgressNoteFormVariant } from '../types';
import { PsychProgressNoteFormProvider } from './context/PsychProgressNoteFormContext';
import { TherapyProgressNoteFormProvider } from './context/TherapistProgressNoteFormContext';
import ProgressNoteFormContainer from './components/ProgressNoteFormContainer';
import ProgressNoteFormContainerV2 from './componentsV2/ProgressNoteFormContainer';
import useQuerySessionServices from '../../../../../hooks/useQuerySessionServices';
import useQueryTherapistAvailability from '../../../../../hooks/availability/useQueryTherapistAvailability';
import { getUserData } from '../../../../../utils/token';
import useUnsubmittedSession from '../hooks/useUnsubmittedSession';
import SavePsychotherapyNoteDialog from '../SavePsychotherapyNoteDialog';
import { UnsubmittedSessionTask } from '../../../../../hooks/dashboard/useQueryTaskListV3';

interface Props {
  mode: FormMode;
}

const PsychProgressNoteForm: FunctionComponent<Props> = ({ mode }) => {
  const match = useRouteMatch<{ roomID: string; noteID: string }>();

  const {
    isLoading: isHelperDataLoading,
    isError: isHelperDataError,
    data: helperData,
  } = useQueryProgressNoteHelperData(match.params.roomID);
  const { data: { clientUserID } = {} } = useQueryRoomDetails(match.params.roomID);
  const {
    isLoading: isNotesLoading,
    isError: isNotesError,
    data: notesData,
  } = useQueryNotes(match.params.roomID);
  const {
    isLoading: isProgressNoteLoading,
    isError: isProgressNoteError,
    data: progressNoteData,
  } = useQueryProgressNote(clientUserID, match.params.noteID, mode === 'edit' || mode === 'view');
  const noteRoomID = progressNoteData?.roomID?.toString() || match.params.roomID;
  const { data: sessionServices = [] } = useQuerySessionServices(noteRoomID, clientUserID);
  const {
    isLoading: isBusinessLineLoading,
    isError: isBusinessLineError,
    data: businessLineData,
  } = useQueryBusinessLine(noteRoomID, clientUserID);

  if (isHelperDataLoading || isBusinessLineLoading || isNotesLoading || isProgressNoteLoading) {
    return <Spinner />;
  }

  if (isHelperDataError || isBusinessLineError || isNotesError || isProgressNoteError) {
    return <ErrorScreen message="Something went wrong" />;
  }

  return (
    <PsychProgressNoteFormProvider
      formMode={mode}
      businessLine={businessLineData!}
      helperData={helperData!}
      sessionServices={sessionServices!}
      currentSessionReport={notesData!.currentSessionReport}
      selectedNote={progressNoteData!}
    >
      <Switch>
        <Route path={`${match.path}/save-draft-dialog`} component={SavePsychotherapyNoteDialog} />
        <Route path={`${match.path}/delete-dialog`} component={DeleteProgressNoteDialog} />
        <Route path={`${match.path}`} component={ProgressNoteFormContainer} />
      </Switch>
    </PsychProgressNoteFormProvider>
  );
};

const getFormVariant = (
  helperData: GetProgressNoteHelperDataResponse,
  matchPath: string
): ProgressNoteFormVariant => {
  let variant: ProgressNoteFormVariant = 'default';

  if (helperData.isSupervised) {
    variant = 'associate';
  }

  if (matchPath?.includes('supervised-notes')) {
    variant = 'supervisor';
  }

  return variant;
};

const TherapistProgressNoteForm: FunctionComponent<Props> = ({ mode }) => {
  const match = useRouteMatch<{ roomID: string; noteID: string; clientUserID?: string }>();
  const history = useHistory();
  const {
    unsubmittedSession,
    unsubmittedFreeLiveCalls,
    unsubmittedSessionID,
    isLoading: isUnsubmittedSessionLoading,
    isError: isUnsubmittedSessionError,
  } = useUnsubmittedSession();
  const {
    isLoading: isHelperDataLoading,
    isError: isHelperDataError,
    data: helperData,
  } = useQueryProgressNoteHelperData(match.params.roomID);

  const { isTransferred } = (unsubmittedSession as UnsubmittedSessionTask) || {};
  const { data: roomDetailsData } = useQueryRoomDetails(
    match.params.roomID,
    isTransferred || match.path.includes('/tasks')
  );
  const clientUserID =
    unsubmittedSession?.userID ||
    roomDetailsData?.clientUserID ||
    (match.params?.clientUserID ? Number(match.params.clientUserID) : undefined);

  const {
    isLoading: isProgressNoteLoading,
    isError: isProgressNoteError,
    data: progressNoteData,
  } = useQueryProgressNote(clientUserID, match.params.noteID, mode === 'edit' || mode === 'view');

  if (!unsubmittedSession && unsubmittedSessionID) {
    return <Spinner />;
  }

  if (isHelperDataLoading || isProgressNoteLoading || isUnsubmittedSessionLoading) {
    return <Spinner />;
  }

  if (isHelperDataError || isProgressNoteError || isUnsubmittedSessionError) {
    return (
      <ErrorScreen
        message="Something went wrong"
        displayCloseButton={isTransferred}
        handleClose={() => isTransferred && history.push('/tasks')}
      />
    );
  }

  return (
    <TherapyProgressNoteFormProvider
      formMode={mode}
      helperData={helperData!}
      selectedNote={progressNoteData!}
      unsubmittedSession={unsubmittedSession}
      unsubmittedFreeLiveCalls={unsubmittedFreeLiveCalls}
      unsubmittedSessionID={unsubmittedSessionID}
      clientUserID={clientUserID}
      formVariant={getFormVariant(helperData!, match.path)}
    >
      <Switch>
        <Route path={`${match.path}/save-draft-dialog`} component={SaveProgressNoteDialog} />
        <Route path={`${match.path}/delete-dialog`} component={DeleteProgressNoteDialog} />
        <Route path={`${match.path}`} component={ProgressNoteFormContainerV2} />
      </Switch>
    </TherapyProgressNoteFormProvider>
  );
};

const Container: FunctionComponent<Props> = ({ mode }) => {
  const therapistID = getUserData()?.id;
  const match = useRouteMatch<{ roomID: string; noteID: string }>();
  const {
    data: therapistAvailability,
    isLoading: isTherapistAvailabilityLoading,
    isError: isTherapistAvailabilityError,
  } = useQueryTherapistAvailability(therapistID);

  const { data: roomDetailsData } = useQueryRoomDetails(
    match.params.roomID,
    match.path.includes('/tasks')
  );
  const { clientUserID } = roomDetailsData || {};

  const {
    isLoading: isProgressNoteLoading,
    isError: isProgressNoteError,
    data: progressNoteData,
  } = useQueryProgressNote(clientUserID, match.params.noteID, mode === 'edit' || mode === 'view');

  if (isTherapistAvailabilityLoading || isProgressNoteLoading) {
    return <Spinner />;
  }

  if (isTherapistAvailabilityError || isProgressNoteError) {
    return <ErrorScreen message="Something went wrong" />;
  }

  if (
    therapistAvailability?.therapistType === 'psychiatrist' ||
    progressNoteData?.serviceType === 'psychiatry' ||
    progressNoteData?.psychiatryPlan
  ) {
    return <PsychProgressNoteForm mode={mode} />;
  }

  return <TherapistProgressNoteForm mode={mode} />;
};

export default Container;
