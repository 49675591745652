import { View, styled, EmotionStyle, TextDS, TouchableView } from '@talkspace/react-toolkit';
import { ChevronDown, ChevronUp } from '@talkspace/react-toolkit/src/designSystems/icons';
import { ReactNode } from 'react';

interface SectionContainerProps {
  title?: string;
  subtitle?: string | ReactNode;
  children: ReactNode;
  containerStyle?: EmotionStyle;
  tooltip?: ReactNode;
  isOpen?: boolean;
  onAccordionPress?: () => void;
}

const Container = styled(View)<{ containerStyle?: EmotionStyle }>(({ containerStyle }) => {
  return {
    paddingRight: 13,
    paddingLeft: 13,
    width: '100%',
    ...containerStyle,
  };
});

const SectionContainer = ({
  children,
  title,
  subtitle,
  containerStyle,
  tooltip,
  isOpen,
  onAccordionPress,
}: SectionContainerProps) => (
  <Container containerStyle={containerStyle}>
    {title && (
      <TouchableView
        onPress={onAccordionPress && onAccordionPress}
        row
        align="center"
        justify="space-between"
        style={{ marginBottom: 8 }}
      >
        <TextDS variant="headingMd">{title}</TextDS>
        {onAccordionPress && <>{isOpen ? <ChevronUp /> : <ChevronDown />}</>}
      </TouchableView>
    )}
    {subtitle && (
      <TextDS variant="bodySm" inline>
        {subtitle} {tooltip}
      </TextDS>
    )}
    {children}
  </Container>
);

export default SectionContainer;
