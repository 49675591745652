import {
  Button,
  getSpacing,
  TextDS,
  useWindowWidthState,
  View,
  styled,
} from '@talkspace/react-toolkit';

import { WebPageError } from '@talkspace/react-toolkit/src/designSystems/illustrations';
import { ArrowRotateRight } from '@talkspace/react-toolkit/src/designSystems/icons';

const Wrapper = styled(View)(() => {
  return {
    padding: '0 8px',
    textAlign: 'center',
    flexDirection: 'column',
    flex: 1,
    maxWidth: 670,
    alignSelf: 'center',
    alignItems: 'center',
    marginTop: 128,
  };
});

interface Props {
  onTryAgain: () => void;
}

const ErrorScreen = ({ onTryAgain }: Props) => {
  const { isMobile } = useWindowWidthState();

  return (
    <Wrapper>
      <WebPageError />
      <TextDS style={{ marginBottom: getSpacing('space150') }} variant="headingLg">
        Something went wrong
      </TextDS>
      <TextDS variant="bodySm" style={{ marginBottom: getSpacing('space400') }}>
        We weren't able to send the episode to your client. Please refresh the screen to try again.
      </TextDS>

      <Button
        Icon={() => <ArrowRotateRight colorType="inverse" />}
        stretch={isMobile}
        onPress={onTryAgain}
        text="Try again"
        style={{ marginTop: getSpacing('space500') }}
      />
    </Wrapper>
  );
};

export default ErrorScreen;
