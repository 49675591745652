import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import getParamByName from '@/utils/queryString';
import {
  editTreatmentPlan,
  getStandardInterventions,
  getInterventions,
  saveInterventions,
  removeIntervention,
  editIntervention,
} from '../../../../../actions/TreatmentPlannerActions';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import Footer from '../../../../Reusable/Footer/Footer';
import Button from '../../../../Reusable/Buttons/Button';
import './TreatmentPlannerInterventions.css';
import CheckList from '../../../../Reusable/ItemList/CheckList';
import AlertContainer from '../../../../Reusable/AlertContainer/AlertContainer';
import { doActions, getPreviousItems, getCombinedItems } from './TreatmentPlannerOperationHelper';

class TreatmentPlannerInterventions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCard: false,
      combinedInterventions: [],
      previousInterventions: [],
      currentInterventions: [],
    };

    this.checkboxHandler = this.checkboxHandler.bind(this);
    this.textAreaChangeHandler = this.textAreaChangeHandler.bind(this);
    this.adderChangeHandler = this.adderChangeHandler.bind(this);
    this.setCardState = this.setCardState.bind(this);
    this.nextHandler = this.nextHandler.bind(this);
    this.postAction = getParamByName('postAction');
    this.handleNavigation = this.handleNavigation.bind(this);
    if (!props.currentTreatmentPlan.id) {
      props.history.push(
        this.postAction === 'notes'
          ? `/room/${this.props.roomID}/notes-tab`
          : `/room/${this.props.roomID}/clinical-tab`
      );
    }
  }

  handleNavigation() {
    const { history } = this.props;
    if (history.location?.state?.postAction) {
      history.push(history.location.state.postAction);
    } else if (this.postAction === 'notes') {
      history.push(`/room/${this.props.roomID}/notes-tab`);
    } else {
      history.push(`/room/${this.props.roomID}/clinical-tab`);
    }
  }

  getFooterChildren() {
    const {
      props: {
        history,
        roomID,
        currentTreatmentPlan: { id: treatmentPlanID },
      },
    } = this;

    return this.state.showCard
      ? [
          <AlertContainer
            key={4}
            title="No interventions selected"
            childComponents={[
              <Button
                key={1}
                title="Continue"
                isSecondary={false}
                dataQa="noInterventionsSelectedContinueButton"
                clickHandler={() => {
                  this.setState({ showCard: false });
                  doActions(
                    treatmentPlanID,
                    this.state.combinedInterventions,
                    this.state.previousInterventions,
                    (treatmentPlanID, itemsToAdd) =>
                      this.props.saveInterventions(
                        treatmentPlanID,
                        itemsToAdd,
                        this.props.objectives
                      ),
                    this.props.removeIntervention,
                    this.props.editIntervention
                  ).then((data) => {
                    this.props.editTreatmentPlan(treatmentPlanID, { committed: true });
                    this.handleNavigation();
                  });
                }}
              />,
              <Button
                title="Cancel"
                key={2}
                isSecondary
                clickHandler={() => this.setState({ showCard: false })}
                dataQa="noInterventionsSelectedCancelButton"
              />,
            ]}
            body="You can select interventions by checking the ones listed above. By clicking continue no interventions will be saved to this treatment plan. You can add interventions later by editing the treatment plan."
            containerStyle={{
              width: 355,
              border: 'none',
            }}
          />,
        ]
      : [
          <Button
            title="Cancel"
            isSecondary
            key={1}
            clickHandler={() => history.goBack()}
            dataQa="treatmentPlanInterventionsCancelButton"
          />,
          <Button
            title="Done"
            isSecondary={false}
            key={2}
            clickHandler={this.nextHandler}
            dataQa="treatmentPlanInterventionsDoneButton"
          />,
        ];
  }

  getFooterComponent() {
    const { isUpdating, isError, spinnerMessage } = this.props;
    const footerStyle = this.state.showCard
      ? {
          height: 186,
          flexDirection: 'column-reverse',
          paddingTop: 3,
        }
      : {};
    return (
      <Footer
        key={4}
        childComponents={[...this.getFooterChildren()]}
        isError={isError}
        isUpdating={isUpdating}
        showSpinner={this.state.showSpinner}
        spinnerMessage={spinnerMessage}
        footerStyle={footerStyle}
      />
    );
  }

  getViewContent() {
    const {
      props: {
        history,
        roomID,
        currentTreatmentPlan: { id: treatmentPlanID },
      },
    } = this;

    const getObjectiveID = (presentingProblemInterventionID) => {
      const intervention = this.props.standardInterventions.filter(
        (standardIntervention) => standardIntervention.id === presentingProblemInterventionID
      )[0];
      return intervention?.objectiveID || null;
    };

    return (
      <form>
        <div key={1} className="treatment-planner-intro-text">
          Please select interventions from the list for each presenting problem.
        </div>
        <div key={3} className="treatment-plan-checklist-container">
          {!!this.state.combinedInterventions &&
            this.props.treatmentPlanPresentingProblems.map((presentingProblem, key) => {
              const objectives = this.props.objectives.filter(
                (objective) => objective.presentingProblemID === presentingProblem.id
              );
              return objectives.map((objective) => {
                const interventions = this.state.combinedInterventions.filter((intervention) => {
                  if (presentingProblem.id === intervention.presentingProblemID) {
                    if (objective.standard === true && intervention.standard === true) {
                      const objectiveID = getObjectiveID(
                        intervention.presentingProblemInterventionID
                      );
                      return (
                        (objective.presentingProblemObjectiveID &&
                          !intervention.presentingProblemInterventionID &&
                          intervention.objectiveID === objective.presentingProblemObjectiveID) ||
                        (objective.presentingProblemObjectiveID &&
                          intervention.presentingProblemInterventionID &&
                          ((objectiveID &&
                            objectiveID === objective.presentingProblemObjectiveID) ||
                            objective.id === intervention.treatmentPlanObjectiveID))
                      );
                    }
                    if (objective.standard === true && intervention.standard === false) {
                      return (
                        (!!objective.presentingProblemObjectiveID &&
                          !intervention.presentingProblemInterventionID &&
                          intervention.treatmentPlanObjectiveID === objective.id) ||
                        intervention.objectiveID === objective.presentingProblemObjectiveID
                      );
                    }
                    if (objective.standard === false && intervention.standard === false) {
                      return (
                        !objective.presentingProblemObjectiveID &&
                        !intervention.presentingProblemInterventionID &&
                        (intervention.treatmentPlanObjectiveID === objective.id ||
                          intervention.objectiveID === objective.id)
                      );
                    }
                    return false;
                  }
                  return undefined;
                });

                return (
                  <div key={`${key}${objective.id}`}>
                    <div className="interventions-objective">{objective.description}</div>
                    <CheckList
                      listTitle={`${presentingProblem.text} interventions`}
                      checkboxChangeHandler={this.checkboxHandler}
                      textAreaChangeHandler={this.textAreaChangeHandler}
                      displayField="description"
                      items={interventions}
                      adderChangeHandler={this.adderChangeHandler}
                      itemInit={{
                        objectiveID: objective.presentingProblemObjectiveID,
                        treatmentPlanObjectiveID: objective.id,
                        presentingProblemID: presentingProblem.id,
                        standard: false,
                        checked: false,
                      }}
                      editorPlaceholder="Edit intervention"
                      adderPlaceholder="Add intervention"
                      unCheckEmpty
                    />
                  </div>
                );
              });
            })}
        </div>
      </form>
    );
  }

  setCardState() {
    const activeInterventions = this.state.combinedInterventions.filter(
      (intervention) => intervention.checked
    );
    if (!!activeInterventions && !!activeInterventions.length) {
      this.setState({ showCard: false });
    }
  }

  UNSAFE_componentWillMount() {
    const {
      props: { objectives, treatmentPlanPresentingProblems, currentTreatmentPlan },
    } = this;
    if (currentTreatmentPlan.id) {
      Promise.all([
        ...treatmentPlanPresentingProblems.map((presentingProblem) => {
          const objectiveIDS = objectives
            .filter((objective) => objective.presentingProblemID === presentingProblem.id)
            .map((objective) => objective.presentingProblemObjectiveID);
          if (objectiveIDS.length)
            return this.props.getStandardInterventions(presentingProblem.id, objectiveIDS);
          return undefined;
        }),
        this.props.getInterventions(this.props.currentTreatmentPlan.id),
      ]).then(() => {
        const standardInterventions = this.props.standardInterventions.map(
          ({ id, presentingProblemID, description, objectiveID, isNewVersion }) => {
            return {
              id,
              presentingProblemID,
              description,
              objectiveID,
              tagText: isNewVersion ? 'NEW' : null,
              checked: false,
              standard: true,
            };
          }
        );

        const treatmentPlanInterventions = this.props.interventions.map(
          ({
            treatmentPlanID,
            id,
            description,
            presentingProblemID,
            standard,
            objectiveID,
            presentingProblemInterventionID,
          }) => {
            return {
              treatmentPlanID,
              id,
              description,
              treatmentPlanObjectiveID: objectiveID,
              presentingProblemID,
              standard,
              checked: true,
              presentingProblemInterventionID,
            };
          }
        );

        const combinedInterventions = getCombinedItems(
          treatmentPlanInterventions,
          standardInterventions,
          'description'
        );

        const previousInterventions = getPreviousItems(combinedInterventions);

        this.setState({
          combinedInterventions,
          previousInterventions,
        });
      });
    }
  }

  adderChangeHandler(event, item) {
    this.setState(
      {
        combinedInterventions: this.state.combinedInterventions.concat([item]),
      },
      this.setCardState
    );
  }

  checkboxHandler(event, intervention) {
    const newCombinedInterventions = this.state.combinedInterventions.map((combinedIntervention) =>
      ((intervention.id !== undefined && intervention.id === combinedIntervention.id) ||
        (intervention.itemIdx !== undefined &&
          intervention.itemIdx === combinedIntervention.itemIdx)) &&
      intervention.description === combinedIntervention.description
        ? intervention
        : combinedIntervention
    );
    this.setState(
      {
        combinedInterventions: newCombinedInterventions,
      },
      this.setCardState
    );
  }

  nextHandler() {
    const {
      roomID,
      currentTreatmentPlan: { id: treatmentPlanID },
      history,
    } = this.props;

    const activeInterventions = this.state.combinedInterventions.filter(
      (intervention) => intervention.checked
    );

    if (!!activeInterventions && !!activeInterventions.length) {
      doActions(
        treatmentPlanID,
        this.state.combinedInterventions,
        this.state.previousInterventions,
        (treatmentPlanID, itemsToAdd) =>
          this.props.saveInterventions(treatmentPlanID, itemsToAdd, this.props.objectives),
        this.props.removeIntervention,
        this.props.editIntervention
      ).then((data) => {
        this.props.editTreatmentPlan(treatmentPlanID, { committed: true });
        this.handleNavigation();
      });
    } else {
      this.setState({ showCard: true });
    }
  }

  textAreaChangeHandler(event, item) {
    const combinedInterventions = this.state.combinedInterventions.map((i) => {
      if (
        ((item.id !== undefined && i.id === item.id) ||
          (item.itemIdx !== undefined && i.itemIdx === item.itemIdx)) &&
        i.standard === false &&
        i.standard === item.standard
      )
        return item;
      return i;
    });
    this.setState(
      {
        combinedInterventions,
      },
      this.setCardState
    );
  }

  render() {
    return (
      <Submenu
        bodyStyle={{
          paddingRight: 35,
          paddingLeft: 35,
        }}
        childComponents={[this.getViewContent()]}
        title="Interventions"
        footerComponent={this.getFooterComponent()}
        prevRoute="treatment-planner-objectives"
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roomID: state.room.roomID,
    currentTreatmentPlan: state.treatmentPlanner.currentTreatmentPlan,
    standardInterventions: state.treatmentPlanner.standardInterventions,
    interventions: state.treatmentPlanner.interventions,
    objectives: state.treatmentPlanner.objectives,
    treatmentPlanPresentingProblems: state.treatmentPlanner.currentTreatmentPlan.presentingProblems,
    treatmentPlanInterventions: state.treatmentPlanner.treatmentPlanInterventions,
    showSpinner: state.treatmentPlanner.isUpdating,
    spinnerMessage: state.treatmentPlanner.spinnerMessage,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editTreatmentPlan,
      getStandardInterventions,
      getInterventions,
      removeIntervention,
      saveInterventions,
      editIntervention,
    },
    dispatch
  );

const TreatmentPlannerInterventionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TreatmentPlannerInterventions);

export default withRouter(TreatmentPlannerInterventionsContainer);
