import {
  mentalStatusExamDict,
  ProgressNoteMentalStatusExamCreationAttributes,
} from '../../../types';

export type MentalStatusExamCategory = keyof Pick<
  ProgressNoteMentalStatusExamCreationAttributes,
  keyof typeof mentalStatusExamDict
>;

export type MentalStatusExamOtherDescription = `${MentalStatusExamCategory}Description`;

export type MentalExamCategoryErrors = Record<MentalStatusExamCategory, boolean>;

export type MentalExamDescriptionErrors = Record<MentalStatusExamOtherDescription, boolean>;

export const mentalStatusExamCategories = Object.keys(
  mentalStatusExamDict
) as MentalStatusExamCategory[];
