import { Component } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import signOut from '@talkspace/auth/signOut';
import {
  useWindowWidthState,
  Small,
  View,
  CaretDown,
  useEmotionTheme,
  ExtraHuge,
  CloseButton,
  Switch,
} from '@talkspace/react-toolkit';
import {
  addPushNotificationPermissionListener,
  promptPushNotificationPermissionOrSettings,
  pushNotificationSettings,
} from 'ts-ionic/plugins/pushNotifications';
import { AppDetails, NativeFeaturesEnum, shouldShowNativeFeature } from 'ts-ionic';
import styled from '@/core/styled/styled';
import { setTrackerUserID } from '@/utils/analytics/eventTracker';
import apiHelper from '../../../utils/api';
import apiWrapper from '../../../utils/apiWrapper';
import { getAvailability, putAvailability } from '../../../actions/AvailabilityActions';
import LogoutModal from '../LogoutModal/LogoutModal';
import SocketService from '../../../utils/socket/SocketService';
import { therapistImageEndpoint } from '../../../utils/resourceHelper';
import { openZendesk, ZendeskPath } from '../../../utils/ssoHelper';
import 'react-toastify/dist/ReactToastify.min.css';
import { removeTokenFromLocalStore, getUserData } from '../../../utils/token';
import BellIcon from '../../Icons/BellIcon';
import newChatDing from './new-chat-message.mp3';
import storage from '../../../modules/core/storage';
import { invalidateTokenAndRedirect } from '../../../modules/auth/auth';
import { tokenIsValid } from '../../../utils/tokenIsValid';
import { isIonic } from '@/auth/reactFrame/helpers';
import { detectEnv } from '@/utils/configs';

const ProfileButtonContainer = styled(View)({
  flexDirection: 'row',
  color: '#67758f',
  cursor: 'pointer',
  alignItems: 'center',
});

const Seperator = styled(View)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      display: !isMobile && 'none',
      height: 1,
      width: '100%',
      background: colors.permaCornflowerBlueSolid,
    };
  }
);

// helpers
const availabilityColorByValue = {
  1: '#0FC1A7',
  4: '#F5A623',
  2: '#FF0075',
};
const isMobileMode = () => window.innerWidth < 768;
const dropdowns = ['profile', 'availability'];

const DEBUG = detectEnv() !== 'prod';

// TODO components, the larger of which to be factored into their own files
const Collapsible = ({ collapsed, children }) =>
  collapsed ? null : <div className="collapsible">{children}</div>;
const NavItem = ({ style, children }) => (
  <div
    style={{ ...(isMobileMode() && { fontSize: 16, fontWeight: 500, ...style }) }}
    className="button"
  >
    {children}
  </div>
);
const Dot = ({ color }) => <div style={{ background: color }} className="dot" />;
const DropdownMenu = ({ children, className }) => (
  <div className={!isMobileMode() ? `dropdown-menu ${className}` : ''}>{children}</div>
);
const DropdownLink = ({ to, children, style, onClick, dataQa }) => (
  <div style={style} className="my-account-link" onMouseDown={onClick} data-qa={dataQa}>
    {children}
  </div>
);

const AvailabilityButton = ({
  hidden,
  availabilityValue,
  availabilityOptions,
  onClick,
  onBlur,
  parentThis,
}) => {
  const availability =
    availabilityValue && availabilityOptions.find((a) => a.value === availabilityValue);
  return hidden || !availability ? null : (
    <div
      className="availability-button"
      onClick={onClick}
      onBlur={onBlur}
      style={{ width: availability.name.length * 9 + 40 }}
      tabIndex={0}
      // eslint-disable-next-line no-param-reassign
      ref={(c) => (parentThis.availability = c)}
    >
      <span className="availability-btn-span">{availability.name}</span>
      <Dot color={availabilityColorByValue[availability.value]} />
    </div>
  );
};
const ProfileButton = ({ onClick, getProfileName, onBlur, parentThis }) => (
  <ProfileButtonContainer
    data-qa="accountMenuBtn"
    onClick={onClick}
    onBlur={onBlur}
    tabIndex={0}
    style={{ outline: 'none' }}
    // eslint-disable-next-line no-param-reassign
    ref={(c) => (parentThis.profile = c)}
  >
    <Small style={{ marginRight: 4, color: '#67758F' }}>{getProfileName()}</Small>
    <CaretDown />
  </ProfileButtonContainer>
);

const AvailabilityOption = ({ availability, updateAvailability }) => (
  <NavItem>
    <DropdownLink role="button" onClick={() => updateAvailability(availability)}>
      {availability.name}
      <Dot color={availabilityColorByValue[availability.value]} />
    </DropdownLink>
  </NavItem>
);
const MobileNavbar = ({ onBack }) => {
  const { isMobile } = useWindowWidthState();
  if (!isMobile) return null;
  return (
    <div className="mobile-navbar">
      <div onClick={onBack} style={{ display: 'flex', padding: 10, justifyContent: 'flex-end' }}>
        <CloseButton />
      </div>
    </div>
  );
};

class SessionNavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timerReset: false,
      soundNotification: false,
      dropdownsDisplayed: {
        profile: false,
        availability: false,
      },
      intervalID: null,
      grantedPushPermission: false,
    };
    this.disableSoundNotificationUpdate = false;

    setTrackerUserID(getUserData().id);
  }

  componentDidMount() {
    this.startTokenIntervalCheck();
    SocketService.instance().on('availability_change', () => {
      this.props.getAvailability(getUserData().id);
    });
    SocketService.instance().on('newMessageHeader', this.playSoundNotification);
    SocketService.instance().on('newMessageHeader', this.emitReceiveAck);
    SocketService.instance().on('room_change', this.redirectToDashboard);
    SocketService.instance().on('userSettings', this.updateUserSettings);
    SocketService.instance().on('refetchSessionStatus', this.handleRefetchSessionStatus);
    this.pushNotificationListener = addPushNotificationPermissionListener(
      (grantedPushPermission) => {
        this.setState({ grantedPushPermission });
      }
    );
  }

  componentWillUnmount() {
    this.removeTokenIntervalCheck();
    SocketService.instance().off('availability_change');
    SocketService.instance().off('newMessageHeader', this.playSoundNotification);
    SocketService.instance().off('newMessageHeader', this.emitReceiveAck);
    SocketService.instance().off('room_change', this.redirectToDashboard);
    SocketService.instance().off('userSettings', this.updateUserSettings);
    SocketService.instance().off('refetchSessionStatus', this.handleRefetchSessionStatus);
    this.pushNotificationListener?.remove();
  }

  onBlurFactory = (component) => () =>
    !isMobileMode() &&
    this.setState(({ dropdownsDisplayed }) => {
      return {
        dropdownsDisplayed: {
          ...dropdownsDisplayed,
          [component]: false,
        },
      };
    });

  onClickFactory = (component) => () =>
    this.setState(
      ({ dropdownsDisplayed }) => {
        return {
          dropdownsDisplayed: {
            ...dropdowns.map((c) => {
              return { [c]: false };
            }),
            [component]: !dropdownsDisplayed[component],
          },
        };
      },
      () => this.state.dropdownsDisplayed[component] && this[component].focus()
    );

  getLogoutHandler = (shouldForgetDevice) => () => {
    invalidateTokenAndRedirect({ shouldForgetDevice });
    signOut();
  };

  getProfileImg() {
    const userId = getUserData().id;
    const img = `${therapistImageEndpoint()}/${userId}.jpg`;
    return img;
  }

  getProfileName() {
    const user = getUserData();
    return `${user.firstName} ${user.lastName}`;
  }

  getSoundNotificationStatus() {
    const userID = getUserData().id;
    return apiWrapper
      .get(`${apiHelper().apiEndpoint}/v3/users/${userID}/user-settings`)
      .then((response) => {
        this.updateSoundNotification(response.data.data.soundNotification);
      })
      .catch((err) => {
        toast(
          <div className="toaster toaster-error">Could not load sound notification status!</div>,
          {
            autoClose: 3000,
          }
        );
      });
  }

  UNSAFE_componentWillMount() {
    this.props.getAvailability(getUserData().id);
    this.getSoundNotificationStatus();
  }

  UNSAFE_componentWillUpdate({
    requestAvailabilityError,
    updateAvailabilityError,
    isUpdatingAvailabilityPut,
    availability,
  }) {
    if (requestAvailabilityError || updateAvailabilityError) {
      toast(
        <div className="toaster toaster-error">{`Could not ${
          requestAvailabilityError ? 'get' : 'update'
        } availability`}</div>,
        {
          autoClose: 3000,
        }
      );
    } else if (!isUpdatingAvailabilityPut && this.props.isUpdatingAvailabilityPut) {
      toast(<div className="toaster toaster-success">Status updated.</div>, {
        autoClose: 3000,
      });
    }
  }

  emitReceiveAck = (data) => {
    if (data.isClientMessage) {
      SocketService.instance()
        .emit('receiveAck', {
          token: storage.getItem('id_token'),
          roomId: data.roomId,
          ackId: data.messageId,
        })
        .catch(() => undefined);
    }
  };

  goToZendesk = () =>
    openZendesk(ZendeskPath.KNOWLEDGE_BASE).catch(() => {
      toast(<div className="toaster toaster-error">Server Error!</div>, {
        autoClose: 3000,
      });
    });

  isTherapistInRoom = () => {
    const location = this.props.location.pathname;
    return location == '/room';
  };

  playSoundNotification = (data) => {
    if (this.state.soundNotification && data.isClientMessage) {
      new Audio(newChatDing).play();
    }
  };

  redirectToDashboard = (data) => {
    if (
      data.message.isTherapistSwitch &&
      data.message.private_talk_id == this.props.match.params.roomID
    ) {
      window.location.pathname = '/rooms';
    }
  };

  removeTokenIntervalCheck = () => {
    clearInterval(this.state.tokenCheckIntervalID);
    this.setState({ tokenCheckIntervalID: undefined });
  };

  resetTimer = () => {
    if (this.logoutModal) {
      this.logoutModal.resetCountdown();
    }
  };

  startTokenIntervalCheck = () => {
    const tokenCheckIntervalID = setInterval(() => {
      if (!tokenIsValid()) {
        removeTokenFromLocalStore();
        window.location = apiHelper().homePageEndpoint;
      }
    }, 5000);
    this.setState({ tokenCheckIntervalID });
  };

  updateAvailability = (availability) => {
    this.props.putAvailability(getUserData().id, availability.value);
    this.setState({ dropdownsDisplayed: { availability: false } });
  };

  updateSoundNotification = (mode) => {
    this.setState({
      soundNotification: mode,
    });
  };

  updateSoundNotificationStatus = (e) => {
    e.persist();
    e.preventDefault();

    if (isIonic()) return this.promptPushNotificationPermission();
    if (this.disableSoundNotificationUpdate) {
      return undefined;
    }

    this.disableSoundNotificationUpdate = true;

    const userID = getUserData().id;

    const cachedSoundNotification = !this.state.soundNotification;

    return apiWrapper
      .patch(`${apiHelper().apiEndpoint}/v3/users/${userID}/user-settings`, {
        soundNotification: cachedSoundNotification,
      })
      .then((response) => {
        this.updateSoundNotification(cachedSoundNotification);

        this.disableSoundNotificationUpdate = false;
      })
      .catch((err) => {
        toast(
          <div className="toaster toaster-error">Could not update sound notification status!</div>,
          {
            autoClose: 3000,
          }
        );
      });
  };

  promptPushNotificationPermission = async () => {
    if (!this.state.grantedPushPermission) {
      promptPushNotificationPermissionOrSettings();
    } else {
      pushNotificationSettings();
    }
  };

  updateUserSettings = (userSettings) => {
    if (userSettings.soundNotification !== undefined)
      this.updateSoundNotification(userSettings.soundNotification);
  };

  handleRefetchSessionStatus = () => {
    const refetchSessionStatusEvent = new CustomEvent('refetchSessionStatus', {
      detail: {},
    });
    window.dispatchEvent(refetchSessionStatusEvent);
  };

  render() {
    const { availability, availabilityOptions, history, hidden } = this.props;
    return (
      <div className="therapist-navbar navbar-right" style={{ display: hidden ? 'none' : 'flex' }}>
        <AvailabilityButton
          availabilityValue={availability}
          availabilityOptions={availabilityOptions}
          onClick={this.onClickFactory('availability')}
          onBlur={this.onBlurFactory('availability')}
          hidden
          parentThis={this}
        />
        <ProfileButton
          getProfileImg={this.getProfileImg}
          getProfileName={this.getProfileName}
          onClick={this.onClickFactory('profile')}
          onBlur={this.onBlurFactory('profile')}
          parentThis={this}
        />
        <Collapsible collapsed={!this.state.dropdownsDisplayed.availability}>
          <div className="mobile-wrapper">
            <DropdownMenu className="availability-dropdown">
              <div className="mobile-header">Availability</div>
              <AvailabilityOption
                availability={availabilityOptions[0]}
                updateAvailability={this.updateAvailability}
              />
              <AvailabilityOption
                availability={availabilityOptions[2]}
                updateAvailability={this.updateAvailability}
              />
              <AvailabilityOption
                availability={availabilityOptions[1]}
                updateAvailability={this.updateAvailability}
              />
            </DropdownMenu>
          </div>
          <MobileNavbar
            onBack={() => this.setState({ dropdownsDisplayed: { availability: false } })}
          />
        </Collapsible>
        <Collapsible collapsed={!this.state.dropdownsDisplayed.profile}>
          <div className="mobile-wrapper">
            <DropdownMenu>
              {isMobileMode() && (
                <>
                  <ExtraHuge style={{ padding: 20 }}>Settings</ExtraHuge>
                  <Seperator />
                </>
              )}

              <NavItem style={{ padding: '12px 20px' }}>
                <DropdownLink
                  dataQa="accountMenuAccountLink"
                  role="button"
                  onClick={() => history.push('/my-account')}
                >
                  Account
                </DropdownLink>
              </NavItem>
              <Seperator />
              <NavItem style={{ padding: '12px 20px' }}>
                <DropdownLink
                  dataQa="accountMenuPerformanceAndReviewsLink"
                  onClick={() => history.push('/performance')}
                >
                  Performance & reviews
                </DropdownLink>
              </NavItem>
              <Seperator />
              <NavItem eventKey="1" style={{ padding: '12px 20px' }}>
                <DropdownLink
                  dataQa="accountMenuNotificationSoundLink"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    ...(isIonic() && { background: 'unset', paddingRight: 0 }),
                  }}
                  onClick={this.updateSoundNotificationStatus}
                >
                  {(() => {
                    const isOn = !isIonic()
                      ? this.state.soundNotification
                      : this.state.grantedPushPermission;
                    return (
                      <>
                        {isIonic() ? (
                          <View
                            row
                            justify="space-between"
                            flex={1}
                            style={{ alignItems: 'center' }}
                          >
                            <div>Push notifications</div>
                            <Switch isOn={isOn} />
                          </View>
                        ) : (
                          <>
                            <div style={{ display: 'flex', flex: 3 }}>Notification sound</div>
                            <ButtonGroup style={{ display: 'flex' }}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                {isOn ? 'ON' : 'OFF'}
                              </div>
                              <BellIcon
                                active={isOn}
                                style={{ marginLeft: 3, position: 'relative', top: 3 }}
                              />
                            </ButtonGroup>
                          </>
                        )}
                      </>
                    );
                  })()}
                </DropdownLink>
              </NavItem>
              <Seperator />
              {isIonic() && (
                <>
                  <NavItem style={{ padding: '12px 20px' }}>
                    <DropdownLink onClick={() => history.push('/passcode/edit')}>
                      Change passcode
                    </DropdownLink>
                  </NavItem>
                  <Seperator />
                </>
              )}
              <NavItem style={{ padding: '12px 20px' }}>
                <DropdownLink
                  dataQa="accountMenuHelpCenterLink"
                  role="button"
                  onClick={this.goToZendesk}
                >
                  Help Center
                </DropdownLink>
              </NavItem>
              <Seperator />
              <NavItem style={{ padding: '12px 20px' }}>
                <DropdownLink dataQa="accountMenuLogOutLink" onClick={this.getLogoutHandler(false)}>
                  Log out
                </DropdownLink>
              </NavItem>
              {isMobileMode() && <Seperator />}
              {shouldShowNativeFeature(NativeFeaturesEnum.APP_DETAILS) && (
                <AppDetails showLessData={!DEBUG} />
              )}
            </DropdownMenu>
          </div>
          <MobileNavbar onBack={() => this.setState({ dropdownsDisplayed: { profile: false } })} />
        </Collapsible>
        <ToastContainer
          closeButton={false}
          position={toast.POSITION.BOTTOM_RIGHT}
          hideProgressBar
        />
        {!isIonic() && (
          <LogoutModal
            logout={this.getLogoutHandler(false)}
            ref={(modal) => {
              this.logoutModal = modal;
            }}
          />
        )}
      </div>
    );
  }
}

SessionNavBar.propTypes = {
  availability: PropTypes.number,
  id: PropTypes.number,
  isLocked: PropTypes.bool,
  availabilityNameMapping: PropTypes.object.isRequired,
  availabilityOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  requestAvailabilityError: PropTypes.bool.isRequired,
  updateAvailabilityError: PropTypes.bool.isRequired,
  isUpdatingAvailabilityPut: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    availability: state.availability.availability,
    id: state.availability.id,
    isLocked: state.availability.isLocked,
    availabilityNameMapping: state.availability.availabilityNameMapping,
    availabilityOptions: state.availability.availabilityOptions,
    requestAvailabilityError: state.availability.requestAvailabilityError,
    updateAvailabilityError: state.availability.updateAvailabilityError,
    isUpdatingAvailabilityPut: state.availability.isUpdatingAvailabilityPut,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getAvailability, putAvailability }, dispatch);
const TherapistNavBar = connect(mapStateToProps, mapDispatchToProps)(SessionNavBar);

export default withRouter(TherapistNavBar);
