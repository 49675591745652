import { useState, MouseEvent } from 'react';
import {
  View,
  useWindowWidthState,
  Checkbox,
  TextDS,
  TooltipV2,
  Tooltip,
  useEmotionTheme,
  AnimatedSwipeDownModal,
  styled,
} from '@talkspace/react-toolkit';
import { AddressCard } from '@talkspace/react-toolkit/src/designSystems/icons';
import { CircleQuestionMark } from '@talkspace/react-toolkit/src/components/icons';
import IconButton from '@talkspace/react-toolkit/src/designSystems/components/IconButton';
import { isIonic } from '@/auth/reactFrame/helpers';
import { useHistory } from '@/core/routerLib';
import { SupervisedNote } from '../../../hooks/notes/useQuerySupervisedNotes';
import ModalityCell, { getIcon } from '../TasksV2/components/ModalityCell';
import TableCTAButton from './TableCTAButton';
import StatusCell from './StatusCell';
import { useSupervisedNotesTable } from './TableContext';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import TableContainer from './TableContainer';
import CellView from './CellView';
import ClientCell from './ClientCell';
import { ProgressNoteFormContainerLocationState } from '../../Room/CRMContainer/NotesTab/types';
import IDView from '../CaseLoadMenu/IDView';
import MessagingTooltip from './MessagingTooltip';
import DateOfSessionCell from './DateOfSessionCell';

type ColumnName =
  | 'associate'
  | 'serviceStartDate'
  | 'nickName'
  | 'sessionModality'
  | 'accountType'
  | 'status'
  | 'actions';

const columns: Record<ColumnName, { label: string; width: number | string }> = {
  associate: {
    label: 'Associate',
    width: 160,
  },
  serviceStartDate: {
    label: 'Date of session',
    width: 130,
  },
  nickName: {
    label: 'Client',
    width: 200,
  },
  sessionModality: {
    label: 'Modality',
    width: 130,
  },
  accountType: {
    label: 'Plan type',
    width: 120,
  },
  status: {
    label: 'Status',
    width: 280,
  },
  actions: {
    label: '',
    width: 130,
  },
};

const TooltipContentView = styled(View)({
  borderRadius: 8,
  background: 'white',
  boxShadow: ' 0px 7px 16px rgba(0, 29, 47, 0.3875)',
  padding: 16,
  gap: 16,
  position: 'absolute',
  top: 0,
  zIndex: 100,
  right: 0,
  minWidth: 354,
});

export default function TableView() {
  const history = useHistory();

  const { colorRoles } = useEmotionTheme();

  const { isDesktop, isTablet, isExtraLarge } = useWindowWidthState();

  const [hoveredNoteID, setHoveredNoteID] = useState(0);

  const { tableState, setTableState } = useSupervisedNotesTable();

  const [isMessagingTooltipOpen, setIsMessagingTooltipOpen] = useState(false);

  const handleSelectRowPress = (note: SupervisedNote) => {
    if (note.status === 'needsRevision') {
      return;
    }

    const newValues = tableState.selectedNotes.find((it) => it.id === note.id)
      ? tableState.selectedNotes.filter((it) => it.id !== note.id)
      : [...tableState.selectedNotes, note];

    setTableState({ selectedNotes: newValues });
  };

  const handleSelectAllPress = (value: boolean) => {
    if (!value) {
      setTableState({ selectedNotes: [] });
      return;
    }

    setTableState({
      selectedNotes: tableState.tableData.filter((it) => it.status === 'pendingReview'),
    });
  };

  const handleCTAPress = (e: MouseEvent, row: SupervisedNote) => {
    e.stopPropagation();

    const query = new URLSearchParams(isIonic() ? { noHeader: 'true' } : {});

    const url = `supervised-notes/rooms/${row.roomID}/clients/${row.client.id}/progress-notes/${
      row.id
    }/view?${query.toString()}`;

    history.push(url, {
      source: 'supervised_notes',
      postActionRoute: 'supervised-notes',
    } as ProgressNoteFormContainerLocationState);
  };

  const renderHeader = () => (
    <TableHeader>
      <View style={{ width: 'calc(100% - 550px)' }} row>
        {Object.keys(columns).map((columnName) => (
          <View key={columnName} style={{ minWidth: columns[columnName]?.width }}>
            {columnName === 'associate' ? (
              <View row>
                <Checkbox
                  label=""
                  isChecked={tableState.areAllRowsSelected}
                  setIsChecked={handleSelectAllPress}
                  dataQa="toggleAllSelection"
                  isDisabled={
                    tableState.tableData?.length === 0 ||
                    tableState.tableData?.every((it) => it.status === 'needsRevision')
                  }
                  containerStyle={{ margin: 0, padding: 0, gap: 0 }}
                  checkboxStyle={{ marginLeft: 0, marginRight: 12 }}
                />

                <TextDS variant="headingSm">{columns[columnName].label}</TextDS>
              </View>
            ) : (
              <TextDS variant="headingSm">{columns[columnName].label}</TextDS>
            )}
          </View>
        ))}
      </View>
    </TableHeader>
  );

  const renderDesktopView = () => (
    <View>
      {tableState.tableData.map((row) => (
        <TableRow
          key={row.id}
          isHovering={hoveredNoteID === row.id}
          onPress={() => handleSelectRowPress(row)}
          onMouseEnter={() => setHoveredNoteID(row.id)}
          onMouseLeave={() => setHoveredNoteID(0)}
        >
          <View row style={{ width: 'calc(100% - 550px)' }}>
            <CellView style={{ width: columns.associate.width }}>
              <Checkbox
                label={row.associate.name}
                isChecked={!!tableState.selectedNotes.find((it) => it.id === row.id)}
                setIsChecked={() => handleSelectRowPress(row)}
                dataQa={`toggleAssociateSelection-${row.associate.id}`}
                isDisabled={row.status === 'needsRevision'}
                isLabelOnRight
                containerStyle={{ margin: 0, padding: 0, gap: 0 }}
                checkboxStyle={{ marginLeft: 0, marginRight: 12 }}
              />
            </CellView>

            <CellView style={{ width: columns.serviceStartDate.width }}>
              <DateOfSessionCell
                startDate={new Date(row.session.startDate)}
                endDate={new Date(row.session.endDate)}
                shouldDisplayRange={row.session.modality === 'messaging'}
              />
            </CellView>

            <CellView style={{ width: columns.nickName.width }}>
              <ClientCell client={row.client} />
            </CellView>

            <CellView style={{ width: columns.sessionModality.width }}>
              <ModalityCell
                sessionModality={row.session.modality}
                liveSessionType={row.session.liveSessionType}
              />
            </CellView>

            <CellView style={{ width: columns.accountType.width }}>
              <TextDS variant="bodySm" colorRole="textSubtle">
                {row.accountType.toUpperCase()}
              </TextDS>
            </CellView>

            <CellView style={{ width: columns.status.width }}>
              <StatusCell
                status={row.status}
                wordDuration={row.accountType === 'bh' ? row.session.wordDuration : null}
              />
            </CellView>
          </View>

          <View row align="center" columnGap={12} style={{ position: 'relative' }}>
            <View
              onMouseEnter={() => setIsMessagingTooltipOpen(true)}
              onMouseLeave={() => setIsMessagingTooltipOpen(false)}
            >
              {row.id === hoveredNoteID &&
                row.accountType === 'bh' &&
                row.session.modality === 'messaging' && (
                  <TooltipV2
                    containerStyle={{ left: -140, top: 20 }}
                    isTooltipOpen={isMessagingTooltipOpen}
                    toolTipText={
                      <TooltipContentView>
                        <MessagingTooltip />
                      </TooltipContentView>
                    }
                  />
                )}
            </View>

            <Tooltip tip="View client ID" place="top">
              <IDView
                pseudonym={row.client.nickname}
                roomID={row.roomID}
                userID={row.client.id}
                isHovering={row.id === hoveredNoteID}
                customIcon={<AddressCard size="major" colorType="brand" />}
                floatingMenuStyle={{ left: 'auto', right: 124, top: 35 }}
              />
            </Tooltip>

            <TableCTAButton onPress={(e) => handleCTAPress(e, row)} status={row.status} />
          </View>
        </TableRow>
      ))}
    </View>
  );

  const renderMobileView = () => (
    <>
      {tableState.tableData.map((row) => (
        <TableRow key={row.id} isHovering={hoveredNoteID === row.id} style={{ padding: 16 }}>
          <View style={{ marginTop: 5, marginRight: 10 }}>
            <Checkbox
              label=""
              isChecked={!!tableState.selectedNotes.find((it) => it.id === row.id)}
              setIsChecked={() => handleSelectRowPress(row)}
              dataQa={`toggleAssociateSelection-${row.associate.id}`}
              isDisabled={row.status === 'needsRevision'}
              containerStyle={{ margin: 0, padding: 0, gap: 0 }}
            />
          </View>

          <View style={{ flex: 1, gap: 4 }}>
            <View row flex={1} justify="space-between" align="center">
              <TextDS variant="headingSm">{row.associate.name}</TextDS>

              <TableCTAButton onPress={(e) => handleCTAPress(e, row)} status={row.status} />
            </View>

            <View row align="center" columnGap={8}>
              {getIcon({
                sessionModality: row.session.modality,
                liveSessionType: row.session.liveSessionType,
              })}

              <DateOfSessionCell
                startDate={new Date(row.session.startDate)}
                endDate={new Date(row.session.endDate)}
                shouldDisplayRange={row.session.modality === 'messaging'}
              />
            </View>

            <ModalityCell
              sessionModality={row.session.modality}
              liveSessionType={row.session.liveSessionType}
            />

            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <ClientCell client={row.client} />

              <View style={{ margin: '0 5px' }}>·</View>

              <TextDS variant="bodySm" colorRole="textSubtle">
                {row.accountType.toUpperCase()}
              </TextDS>
            </View>

            <View row flex={1} justify="space-between">
              <View row columnGap={4} align="center">
                <StatusCell
                  status={row.status}
                  wordDuration={row.accountType === 'bh' ? row.session.wordDuration : null}
                />

                {row.accountType === 'bh' && row.session.modality === 'messaging' && (
                  <IconButton
                    dataQa="messagingTooltipButton"
                    Icon={<CircleQuestionMark color={colorRoles.icons.iconSubtlestDefault} />}
                    onPress={() => setIsMessagingTooltipOpen((prev) => !prev)}
                  />
                )}
              </View>

              <IDView
                pseudonym={row.client.nickname}
                roomID={row.roomID}
                userID={row.client.id}
                isHovering
                customIcon={<AddressCard size="major" colorType="brand" />}
                floatingMenuStyle={{ left: 'auto', right: 20, top: 150 }}
              />
            </View>
          </View>
        </TableRow>
      ))}
    </>
  );

  const renderTabletView = () => (
    <>
      {tableState.tableData.map((row) => (
        <TableRow
          key={row.id}
          isHovering={hoveredNoteID === row.id}
          style={{ padding: 16, justifyContent: 'space-between' }}
        >
          <CellView style={{ justifyContent: 'start', flex: 2 }}>
            <Checkbox
              label={row.associate.name}
              isChecked={!!tableState.selectedNotes.find((it) => it.id === row.id)}
              setIsChecked={() => handleSelectRowPress(row)}
              dataQa={`toggleAssociateSelection-${row.associate.id}`}
              isDisabled={row.status === 'needsRevision'}
              isLabelOnRight
              containerStyle={{ margin: 0, padding: 0, gap: 0 }}
              checkboxStyle={{ marginLeft: 0, marginRight: 12 }}
            />
          </CellView>

          <CellView style={{ flex: 2 }}>
            <View row align="center" columnGap={8}>
              {getIcon({
                sessionModality: row.session.modality,
                liveSessionType: row.session.liveSessionType,
              })}

              <DateOfSessionCell
                startDate={new Date(row.session.startDate)}
                endDate={new Date(row.session.endDate)}
                shouldDisplayRange={row.session.modality === 'messaging'}
              />
            </View>

            <ModalityCell
              sessionModality={row.session.modality}
              liveSessionType={row.session.liveSessionType}
            />

            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <ClientCell client={row.client} />

              <View style={{ margin: '0 5px' }}>·</View>

              <TextDS variant="bodySm" colorRole="textSubtle">
                {row.accountType.toUpperCase()}
              </TextDS>
            </View>
          </CellView>

          <CellView style={{ flex: 3 }}>
            <View row columnGap={4} align="center">
              <StatusCell
                status={row.status}
                wordDuration={row.accountType === 'bh' ? row.session.wordDuration : null}
              />

              {row.accountType === 'bh' && row.session.modality === 'messaging' && (
                <View
                  onMouseEnter={() => setIsMessagingTooltipOpen(true)}
                  onMouseLeave={() => setIsMessagingTooltipOpen(false)}
                >
                  <TooltipV2
                    containerStyle={{ left: -140, top: 20 }}
                    isTooltipOpen={isMessagingTooltipOpen}
                    toolTipText={
                      <TooltipContentView>
                        <MessagingTooltip />
                      </TooltipContentView>
                    }
                  />
                </View>
              )}
            </View>
          </CellView>

          <CellView style={{ flex: 2 }}>
            <View row align="center" columnGap={16} style={{ position: 'relative' }}>
              <Tooltip tip="View client ID" place="top">
                <IDView
                  pseudonym={row.client.nickname}
                  roomID={row.roomID}
                  userID={row.client.id}
                  isHovering
                  customIcon={<AddressCard size="major" colorType="brand" />}
                  floatingMenuStyle={{ left: 'auto', right: 134, top: 30 }}
                />
              </Tooltip>

              <TableCTAButton onPress={(e) => handleCTAPress(e, row)} status={row.status} />
            </View>
          </CellView>
        </TableRow>
      ))}
    </>
  );

  return (
    <TableContainer>
      {(() => {
        if (isTablet || isExtraLarge) {
          return renderTabletView();
        }

        if (isDesktop) {
          return (
            <>
              {renderHeader()}
              {renderDesktopView()}
            </>
          );
        }

        return (
          <>
            {renderMobileView()}

            <AnimatedSwipeDownModal
              isOpen={isMessagingTooltipOpen}
              onClose={() => setIsMessagingTooltipOpen(false)}
              containerStyles={{ gap: 16 }}
              closeModalText="close"
            >
              <MessagingTooltip />
            </AnimatedSwipeDownModal>
          </>
        );
      })()}
    </TableContainer>
  );
}
