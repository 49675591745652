import { MouseEventHandler } from 'react';
import { Button, useWindowWidthState } from '@talkspace/react-toolkit';
import { SupervisedNote } from '../../../hooks/notes/useQuerySupervisedNotes';

interface Props {
  onPress: MouseEventHandler<HTMLButtonElement>;
  status: SupervisedNote['status'];
}

export default function TableCTAButton({ onPress, status }: Props) {
  const { isMobile } = useWindowWidthState();

  return (
    <Button
      onPress={onPress}
      dataQa="reviewNoteButton"
      variant={status === 'needsRevision' ? 'secondary' : 'primary'}
      text={status === 'needsRevision' ? 'View' : 'Review'}
      sizeDS="slim"
      fixedWidth
      style={{ width: isMobile ? 104 : 124, height: 32 }}
    />
  );
}
