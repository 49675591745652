import {
  Button,
  getSpacing,
  Headphones as HeadphonesIcon,
  TextAreaRounded,
  TextDS,
  View,
  useEmotionTheme,
  styled,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { useState } from 'react';
import { useParams } from '@/core/routerLib';
import ErrorText from '../components/ErrorText';
import { ActionWrapper, Separator } from './TalktrackPreviewScreen';

const textAreaPlaceholder = `Examples:
“How to care for yourself through a big transition.”
“Managing triggers during the holidays.”
“How to cope with the grief of a beloved pet.”`;

const Wrapper = styled(View)(() => {
  return {
    padding: '0 8px',
    textAlign: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flex: 1,
    maxWidth: 670,
    alignSelf: 'center',
  };
});

const placeHolderStyle = {
  fontSize: 14,
  margin: 12,
};

interface Props {
  suggestionInput: string | null;
  isOptional: boolean;
  onSuggestionInputChange: (val: string) => void;
  onPreviewEpisode: () => void;
  onCloseModal: () => void;
}

const TalktrackSuggestionScreen = ({
  suggestionInput,
  isOptional,
  onSuggestionInputChange,
  onPreviewEpisode,
  onCloseModal,
}: Props) => {
  const { roomID } = useParams<{ roomID: string }>();
  const { data: roomDetailsData, isLoading } = useQueryRoomDetails(roomID);
  const { colorRoles } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();

  const [isError, setIsError] = useState(false);

  const handlePreviewEpisodePress = () => {
    if (suggestionInput || isOptional) {
      onPreviewEpisode();
    } else {
      setIsError(true);
    }
  };

  const handleSuggestionInputChange = (val: string) => {
    setIsError(false);
    onSuggestionInputChange(val);
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Wrapper>
        <View>
          <HeadphonesIcon style={{ alignSelf: 'center' }} />
          <TextDS style={{ marginBottom: getSpacing('space150') }} variant="headingLg">
            Want to generate a Talkcast episode for {roomDetailsData?.customerName}?
          </TextDS>
          <TextDS variant="bodySm" style={{ marginBottom: getSpacing('space400') }}>
            Talkcast uses HIPAA-compliant technology to create a 3-5 minute personalized audio track
            based on your session transcript, helping your client stay engaged between sessions. You
            can personalize it further by adding a specific topic to include.
          </TextDS>
          <View row justify="space-between" style={{ marginBottom: 4 }}>
            <TextDS variant="bodySm">Make sure the episode talks about...</TextDS>
            {isOptional && <TextDS variant="bodySm">(optional)</TextDS>}
          </View>
          <TextAreaRounded
            style={{
              textAlign: 'left',
              borderColor: isError && colorRoles.surfaces.criticalBoldDefault,
            }}
            onChangeText={handleSuggestionInputChange}
            value={suggestionInput || ''}
            placeholder={textAreaPlaceholder}
            textAreaStyle={placeHolderStyle}
          />
          <ErrorText isVisible={isError} text="Episode topic is required" />
        </View>
      </Wrapper>

      <Separator />

      <ActionWrapper justify="end" row={!isMobile}>
        <Button variant="secondary" onPress={onCloseModal} text="Maybe later" sizeDS="medium" />
        <Button onPress={handlePreviewEpisodePress} text="Preview episode" sizeDS="medium" />
      </ActionWrapper>
    </>
  );
};

export default TalktrackSuggestionScreen;
