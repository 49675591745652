import { EmotionThemeProvider, Select, useEmotionTheme, View } from '@talkspace/react-toolkit';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import Footer from 'components/Reusable/Footer/Footer';
import Button from '@talkspace/react-toolkit/src/components/Button';
import useQueryQuestionnaires from 'ts-frontend/hooks/questionnaires/useQueryQuestionnaires';
import { QuestionnaireAnswerData } from 'ts-frontend/hooks/questionnaires/types';
import useQueryQuestionnaireQuestions, {
  Question,
} from 'ts-frontend/hooks/questionnaires/useQueryQuestionnaireQuestions';
import useMutationCreateQuestionnaire from 'ts-frontend/hooks/questionnaires/useMutationCreateQuestionnaire';
import _ from 'lodash';
import { useHistory, useLocation, useParams } from '../../../../../core/routerLib';
import Submenu from '../../../../Reusable/Submenu';
import { RiskAndSafetyDialogLocationState } from './types';
import { getUserData } from '../../../../../utils/token';
import { safetyPlanDeepCompare } from './utils';

type SelectedOptions = Array<{ label: string; value: string }>;

const getMenuPlacement = (top: any) => {
  const spaceBelow = window.innerHeight - top;

  return spaceBelow < 300 ? 'top' : 'bottom';
};

interface SafetyPlanQuestionParams {
  question: Question;
  parseQuestionAnswers: (question: Question) => SelectedOptions;
  updateQuestionAnswerByQuestionID: ({
    selectedOptions,
    id,
  }: {
    selectedOptions: SelectedOptions;
    id: number;
  }) => void;
}

const SafetyPlanQuestion = ({
  question,
  parseQuestionAnswers,
  updateQuestionAnswerByQuestionID,
}: SafetyPlanQuestionParams) => {
  const questionRef = useRef<HTMLDivElement>(null);

  const [menuPlacement, setMenuPlacement] = useState<'bottom' | 'top'>('bottom');

  const handleScroll = useCallback(() => {
    const { top } = questionRef?.current?.getBoundingClientRect() || {
      top: 0,
    };
    setMenuPlacement(getMenuPlacement(top));
  }, []);

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll, {
      capture: true,
      passive: true,
    });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <View ref={questionRef} key={question.order} style={{ marginBottom: 10 }}>
      <Select
        dataQa={`safetyPlanSelectQuestion${question.order}`}
        isMulti
        placeholder={`${question.text}`}
        isSearchable
        isCreatable
        value={parseQuestionAnswers(question)} // need to deparse
        options={question.questionnaireQuestionOptions.map((option) => {
          return { label: option.text, value: option.id.toString() };
        })}
        onChange={(selectedOptions) => {
          updateQuestionAnswerByQuestionID({
            selectedOptions: selectedOptions as SelectedOptions,
            id: question.id,
          });
        }}
        menuPlacement={menuPlacement}
      />
      <View style={{ marginTop: 10, fontSize: 12 }}>{`${question.description}`}</View>
    </View>
  );
};

const SafetyPlanForm = () => {
  const { roomID } = useParams<{ roomID: string }>();
  const location = useLocation<RiskAndSafetyDialogLocationState | undefined>();
  const history = useHistory();

  const { data: safetyPlanQuestions } = useQueryQuestionnaireQuestions({
    roomID: Number(roomID),
    questionnaireType: 'safety_plan',
  });

  const { data: safetyPlan } = useQueryQuestionnaires({
    roomID: Number(roomID),
    questionnaireTypes: ['safety_plan'],
  });

  const [answersChanged, setAnswersChanged] = useState(false);

  const [answers, setAnswers] = useState<Array<QuestionnaireAnswerData>>([]);
  const [initialAnswers, setInitialAnswers] = useState<Array<QuestionnaireAnswerData>>([]);

  const isNotFirstSafetyPlan = useMemo(
    () =>
      safetyPlan?.[0] &&
      safetyPlan[0].firstInstanceCreatedAt &&
      safetyPlan[0].createdAt &&
      safetyPlan[0].firstInstanceCreatedAt !== safetyPlan[0]?.createdAt,
    [safetyPlan]
  );

  useEffect(() => {
    const isAnswersChanged = !safetyPlanDeepCompare(
      JSON.parse(JSON.stringify(initialAnswers)),
      JSON.parse(JSON.stringify(answers))
    );
    answers && initialAnswers && isAnswersChanged
      ? setAnswersChanged(true)
      : setAnswersChanged(false);
  }, [answers, initialAnswers]);

  useEffect(() => {
    if (location?.state?.answers) {
      setAnswers(location.state.answers);
    } else if (safetyPlan?.length) {
      const formattedAnswers = safetyPlan
        .find((it) => it.type === 'safety_plan')
        ?.questionnaireAnswers.map((answer) => {
          return {
            questionID: answer.questionnaireQuestionID,
            options: answer?.questionnaireQuestionOptions?.map((option) => option.id) || [],
            textOptions: answer?.textOptions || [],
          };
        });
      setAnswers(formattedAnswers || []);

      const formattedAnswersClone = formattedAnswers ? _.cloneDeep(formattedAnswers) : [];
      setInitialAnswers(formattedAnswersClone);
    }
  }, [location?.state?.answers, safetyPlan]);

  const handleBackButtonPressed = useCallback(() => {
    answersChanged
      ? history.push(`/room/${roomID}/risk-and-safety/leave`, {
          questionnaireType: 'safety_plan',
          title: 'Stanley-Brown Safety Plan',
          answers,
        })
      : history.push(`/room/${roomID}/clinical-tab`, { section: 'risk-and-safety' });
  }, [answers, answersChanged, history, roomID]);

  const parseQuestionAnswers = useCallback(
    (question: Question) => {
      const answer = answers.find(({ questionID }) => question.id === questionID);
      if (answer) {
        return [
          ...(answer.options?.map((option) => {
            const optionWithText = question.questionnaireQuestionOptions.find(
              (it) => it.id === option
            );
            return {
              label: optionWithText?.text || '',
              value: option.toString(),
            };
          }) || []),
          ...(answer.textOptions?.map((textOption) => {
            return {
              label: textOption,
              value: textOption,
            };
          }) || []),
        ];
      }
      return [];
    },
    [answers]
  );

  const updateQuestionAnswerByQuestionID = useCallback(
    ({ selectedOptions, id }: { selectedOptions: SelectedOptions; id: number }) => {
      const builtInOptions = selectedOptions?.filter((option) => !isNaN(Number(option.value)));
      const createdOptions = selectedOptions?.filter((option) => isNaN(Number(option.value)));

      const answersClone = [...answers];
      const answer = answersClone?.find(({ questionID }) => id === questionID);

      if (!answer) {
        setAnswers([
          ...answers,
          {
            questionID: id,
            options:
              (builtInOptions as SelectedOptions)?.map((option) => Number(option.value)) || [],
            textOptions: (createdOptions as SelectedOptions)?.map((option) => option.value) || [],
          },
        ]);
      } else if (selectedOptions === null) {
        setAnswers(answersClone.filter(({ questionID }) => id !== questionID));
      } else {
        answer.options =
          (builtInOptions as SelectedOptions)?.map((option) => Number(option.value)) || [];
        answer.textOptions =
          (createdOptions as SelectedOptions)?.map((option) => option.value) || [];

        setAnswers(answersClone);
      }
    },
    [answers]
  );
  const { mutate: createQuestionnaire } = useMutationCreateQuestionnaire();

  const therapistID = getUserData().id;

  const { colors } = useEmotionTheme();

  const renderFooter = useCallback(
    () => (
      <Footer
        childComponents={[
          <View style={{ justifyContent: 'end', padding: 15 }}>
            <EmotionThemeProvider version="2.0.0">
              <Button
                dataQa="saveSafetyPlanButton"
                disabled={!answersChanged}
                variant="tertiary"
                text="Save"
                style={{ color: colors.permaBlueStoneNew, fontWeight: 600, fontSize: 16 }}
                onPress={() => {
                  createQuestionnaire(
                    {
                      questionnaireType: 'safety_plan',
                      roomID: Number(roomID),
                      userID: therapistID,
                      answers: (() => {
                        if (!safetyPlanQuestions) {
                          return [];
                        }
                        const newAnswers = answers.filter((it) => {
                          const answersExistInQuestions = safetyPlanQuestions
                            .map((question) => question.id)
                            .includes(it.questionID);
                          return answersExistInQuestions;
                        });
                        return newAnswers;
                      })(),
                    },
                    {
                      onSuccess: () => {
                        history.push(`/room/${roomID}/clinical-tab`, {
                          section: 'risk-and-safety',
                        });
                      },
                    }
                  );
                }}
              />
            </EmotionThemeProvider>
          </View>,
        ]}
        isError={false}
        isUpdating={false}
        showSpinner={false}
        spinnerMessage=""
      />
    ),
    [
      answers,
      answersChanged,
      colors.permaBlueStoneNew,
      createQuestionnaire,
      history,
      roomID,
      safetyPlanQuestions,
      therapistID,
    ]
  );

  const getSubmenuChildComponent = useCallback(
    () => (
      <View style={{ color: colors.permaSlateGrayApprox, fontSize: 14 }}>
        {!!(safetyPlan && safetyPlan.length > 0) && !!isNotFirstSafetyPlan && (
          <View style={{ alignSelf: 'center' }}>
            {safetyPlan &&
              safetyPlan?.[0]?.createdAt &&
              `Created ${moment(safetyPlan[0]?.firstInstanceCreatedAt).format('MMM DD, YYYY')}`}
          </View>
        )}
        <View style={{ alignSelf: 'center' }}>
          {safetyPlan &&
            safetyPlan?.[0]?.createdAt &&
            `${isNotFirstSafetyPlan ? 'Edited' : ''} ${moment(safetyPlan[0].createdAt).format(
              isNotFirstSafetyPlan ? 'MMM DD' : 'MMM DD, YYYY'
            )}`}
        </View>
        {safetyPlanQuestions
          ?.sort((a, b) => a.order - b.order)
          ?.map((question, i) => (
            <SafetyPlanQuestion
              updateQuestionAnswerByQuestionID={updateQuestionAnswerByQuestionID}
              parseQuestionAnswers={parseQuestionAnswers}
              key={question.order}
              question={question}
            />
          ))}
      </View>
    ),
    [
      colors.permaSlateGrayApprox,
      isNotFirstSafetyPlan,
      parseQuestionAnswers,
      safetyPlan,
      safetyPlanQuestions,
      updateQuestionAnswerByQuestionID,
    ]
  );

  return (
    <View style={{ height: '100%' }}>
      <Submenu
        title="CSSR Screener Form"
        titleComponent={<View>Stanley-Brown Safety Plan</View>}
        childComponents={[getSubmenuChildComponent()]}
        bodyStyle={{ padding: 10 }}
        childComponentsWrapperStyle={{ height: '100%' }}
        footerComponent={renderFooter()}
        onBackAlt={() => {
          handleBackButtonPressed();
        }}
      />
    </View>
  );
};

export default SafetyPlanForm;
