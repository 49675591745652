import React, { useEffect, useMemo, useRef, useState } from 'react';
import { OptionsType, OptionTypeBase } from 'react-select';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  AccordionContainer,
  Select,
  TextInput,
  Tiny,
  Standard,
  styled,
  View,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import isEqual from 'lodash/isEqual';
import differenceWith from 'lodash/differenceWith';
import { checkIfShouldBeSearchable, yesNoOptions } from 'utils/optionsHelpers';
import { withBusinessLine } from 'ts-frontend/hoc';
import { BusinessLineQueryResponse } from 'ts-frontend/hooks/useQueryBusinessLine';
import { suicideIdeationOrPlanning, homicidalIdeationOrPlanning } from '@talkspace/configs';
import { useHistory, useLocation, useParams } from '@/core/routerLib';
import {
  getClinicalInformation as getClinicalInformationAction,
  setClinicalInformation as setClinicalInformationAction,
  getPresentingProblems as getPresentingProblemsAction,
  postPresentingProblems as postPresentingProblemsAction,
  deletePresentingProblems as deletePresentingProblemsAction,
  getConditions as getConditionsAction,
  postConditions as postConditionsAction,
  deleteConditions as deleteConditionsAction,
  getPhysicalConditions as getPhysicalConditionsAction,
  postPhysicalConditions as postPhysicalConditionsAction,
  deletePhysicalConditions as deletePhysicalConditionsAction,
  getCurrentSurvey as getCurrentSurveyAction,
} from '../../../../../actions/ClinicalInformationActions';
import { getCustomerInformation as getCustomerInformationAction } from '../../../../../actions/CustomerInformationActions';
import UserBadge from '../../../../Reusable/UserBadge/UserBadge';
import SocketService from '../../../../../utils/socket/SocketService';
import { getOptionsByField } from '../../../../../utils/tsConfigsValues';
import { journeyItemsHaveToDo } from '../../../../../utils/journeyHelper';
import RiskContainer from './RiskContainer';
import RiskAssessmentLink from '../../../../Reusable/RiskAssessmentLink';
import useQueryParticipants from '../../../../../hooks/useQueryParticipants';
import useQueryRoomPresentingProblems, {
  RoomPresentingProblem,
} from '../../../../../hooks/useQueryRoomPresentingProblems';

import './ClinicalInformation.css';
import { Participant } from '../../../../../types/participant';
import useQueryConditions from '../../../../../hooks/useQueryConditions';

interface OptionType extends OptionTypeBase {
  value: string;
  label: string;
}

const adapterPresentingProblemOptions = (
  options: RoomPresentingProblem[]
): OptionsType<OptionType> =>
  options.map(({ id, therapistFacingText }) => {
    return {
      value: id.toString(),
      label: therapistFacingText,
    };
  });

function diffArrayOfObjects(newArr, oldArr) {
  const diff = newArr.length - oldArr.length;
  const valueArr = differenceWith(diff > 0 ? newArr : oldArr, diff > 0 ? oldArr : newArr, isEqual);
  return { diff, value: valueArr.length ? valueArr[0] : null };
}

const StyledText = styled(Standard)(({ theme: { colors } }) => {
  return {
    color: colors.permaRiverBed,
    marginTop: 9,
    marginBottom: 15,
    fontSize: 14,
  };
});

interface LocationState {
  focusElement?: string;
  selectedClientID?: number;
  [key: string]: any;
}

interface Props {
  clinicalInformation: Record<string, any>;
  clientUserID: number;
  journeyItems: Record<string, any>[];
  businessLineData: BusinessLineQueryResponse;
  getClinicalInformation: (clientUserID: number) => void;
  setClinicalInformation: (clientUserID: number, value: any) => void;
  getCustomerInformation: (clientUserID: number) => void;
  getPresentingProblems: (clientUserID: number) => void;
  postPresentingProblems: (clientUserID: number, value: any, presentingProblems: any[]) => void;
  deletePresentingProblems: (clientUserID: number, value: any, presentingProblems: any[]) => void;
  getConditions: (clientUserID: number) => void;
  postConditions: (
    clientID: number,
    option: OptionType,
    conditions: any[],
    participants: Participant[]
  ) => void;
  deleteConditions: (
    clientID: number,
    { id: number },
    otherConditions: any[],
    participants: Participant[]
  ) => void;
  getPhysicalConditions: (clientUserID: number) => void;
  postPhysicalConditions: (clientUserID: number, value: any, psychicalConditions: any[]) => void;
  deletePhysicalConditions: (clientUserID: number, value: any, psychicalConditions: any[]) => void;
  getCurrentSurvey: (roomID: number) => void;
}

const ClinicalInformation = ({
  clinicalInformation,
  clientUserID,
  journeyItems,
  businessLineData,
  getClinicalInformation,
  setClinicalInformation,
  getCustomerInformation,
  getPresentingProblems,
  postPresentingProblems,
  deletePresentingProblems,
  getConditions,
  postConditions,
  deleteConditions,
  getPhysicalConditions,
  postPhysicalConditions,
  deletePhysicalConditions,
  getCurrentSurvey,
}: Props) => {
  const viewRef = useRef<HTMLDivElement>(null);

  const { isMobile } = useWindowWidthState();
  const { data: conditionsList = [] } = useQueryConditions();
  const [hasSelectedBillable, setHasSelectedBillable] = useState(
    clinicalInformation?.conditions.some((item) => item.isBillable) || false
  );
  const conditionsNotBillableIDs = useMemo(
    () => conditionsList.filter((item) => !item.isBillable).map((item) => item.id),
    [conditionsList]
  );

  const { roomID } = useParams<{ roomID: string }>();
  const location = useLocation<LocationState>();
  const history = useHistory();

  const { data: participants = [] } = useQueryParticipants(Number(roomID));
  const { data: roomsPresentingProblems = [] } = useQueryRoomPresentingProblems(Number(roomID));

  const presentingProblemsRef = useRef<HTMLInputElement | null>(null);
  const diagnosisRef = useRef<HTMLInputElement | null>(null);
  const acuityRef = useRef<HTMLInputElement | null>(null);
  const stageOfChangeRef = useRef<HTMLInputElement | null>(null);
  const physicalConditionsRef = useRef<HTMLInputElement | null>(null);
  const sleepQualityRef = useRef<HTMLInputElement | null>(null);
  const sleepAidUseRef = useRef<HTMLInputElement | null>(null);
  const socialSupportSystemRef = useRef<HTMLInputElement | null>(null);
  const experiencingSchoolCommunityRef = useRef<HTMLInputElement | null>(null);
  const experiencingSocialMediaRef = useRef<HTMLInputElement | null>(null);
  const previousMentalHealthTreatmentRef = useRef<HTMLInputElement | null>(null);
  const previousMentalHealthIssuesRef = useRef<HTMLInputElement | null>(null);
  const hospitalizationHistoryRef = useRef<HTMLInputElement | null>(null);
  const previousRelativesMentalHealthIssuesRef = useRef<HTMLInputElement | null>(null);
  const traumaticExperienceRef = useRef<HTMLInputElement | null>(null);
  const traumaticFlashbacksRef = useRef<HTMLInputElement | null>(null);
  const guiltRef = useRef<HTMLInputElement | null>(null);
  const isolationRef = useRef<HTMLInputElement | null>(null);

  const refsMap = useMemo(() => {
    return {
      presentingProblems: presentingProblemsRef,
      diagnosis: diagnosisRef,
      acuity: acuityRef,
      stageOfChange: stageOfChangeRef,
      physicalConditions: physicalConditionsRef,
      sleepQuality: sleepQualityRef,
      sleepAidUse: sleepAidUseRef,
      socialSupportSystem: socialSupportSystemRef,
      experiencingSchoolCommunity: experiencingSchoolCommunityRef,
      experiencingSocialMedia: experiencingSocialMediaRef,
      previousMentalHealthTreatment: previousMentalHealthTreatmentRef,
      previousMentalHealthIssues: previousMentalHealthIssuesRef,
      hospitalizationHistory: hospitalizationHistoryRef,
      previousRelativesMentalHealthIssues: previousRelativesMentalHealthIssuesRef,
      traumaticExperience: traumaticExperienceRef,
      traumaticFlashbacks: traumaticFlashbacksRef,
      guilt: guiltRef,
      isolation: isolationRef,
    };
  }, []);

  const reloadCustomerInformation = (userID: number) => {
    getClinicalInformation(userID);
    getConditions(userID);
    getPhysicalConditions(userID);
  };

  const changeSelectedClient = (selectedUserID: number) => {
    if (selectedUserID !== clientUserID) {
      getCustomerInformation(clientUserID);
      reloadCustomerInformation(clientUserID);
      getPresentingProblems(clientUserID);
      getCurrentSurvey(Number(roomID));
    }
  };

  const focusComponentInLocationState = () => {
    if (!location.state) {
      return;
    }

    const { focusElement, selectedClientID, ...otherState } = location.state;
    if (selectedClientID) {
      changeSelectedClient(selectedClientID);
      history.replace({ pathname: location.pathname, state: { focusElement, ...otherState } });
    }

    setTimeout(() => {
      const ref = refsMap[focusElement as keyof typeof refsMap];
      if (ref?.current?.focus) {
        ref.current.focus();
        history.replace({ pathname: location.pathname, state: { ...otherState } });
      }
    }, (selectedClientID ? 1000 : 0) + 1000);
  };

  useEffect(() => {
    focusComponentInLocationState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const scrollToElem = params.get('container') === 'clinical-information';

    setTimeout(() => {
      if (scrollToElem && viewRef.current) {
        viewRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 600);
  }, []);

  useEffect(() => {
    if (clientUserID && roomID) {
      reloadCustomerInformation(clientUserID);
      getPresentingProblems(clientUserID);
      getCurrentSurvey(Number(roomID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientUserID, roomID]);

  useEffect(() => {
    const handleSubmittedIntakeSurveyEvent = (data) => {
      if (Number(data.roomID) !== Number(roomID)) {
        return;
      }

      reloadCustomerInformation(clientUserID);
      getPresentingProblems(clientUserID);
    };

    SocketService.instance().on('submittedIntakeSurvey', handleSubmittedIntakeSurveyEvent);

    return () => {
      SocketService.instance().off('submittedIntakeSurvey', handleSubmittedIntakeSurveyEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientUserID, roomID]);

  const createHandleSelectChange =
    (field, isMulti = false) =>
    (value) => {
      setClinicalInformation(clientUserID, { [field]: isMulti ? value || [] : value });
    };

  const createUserButtons = () => (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {participants.map((customer) => (
        <UserBadge
          key={`user-badge-${customer.userID}`}
          onClick={() => changeSelectedClient(customer.userID)}
          preferredName={customer.displayName}
          active={customer.userID === clientUserID}
        />
      ))}
    </div>
  );

  const handleConditionChange = (options) => {
    const newConditions = options || [];
    const hasBillable = newConditions?.some(
      (item) => !conditionsNotBillableIDs.includes(item.value)
    );
    const { diff, value } = diffArrayOfObjects(newConditions, clinicalInformation.conditions);
    if (!hasBillable && newConditions.length) {
      deleteConditions(clientUserID, value, [], participants);
      newConditions.forEach((condition) =>
        deleteConditions(clientUserID, condition, [], participants)
      );
    } else if (diff > 0) {
      postConditions(clientUserID, value, newConditions, participants);
    } else if (diff < 0) {
      deleteConditions(clientUserID, value, newConditions, participants);
    }
    setHasSelectedBillable(hasBillable);
  };

  const handlePhysicalConditionChange = (options) => {
    const newPhysicalConditions = options || [];
    const { diff, value } = diffArrayOfObjects(
      newPhysicalConditions,
      clinicalInformation.physicalConditions
    );
    if (diff > 0) {
      postPhysicalConditions(clientUserID, value, newPhysicalConditions);
    } else if (diff < 0) {
      deletePhysicalConditions(clientUserID, value, newPhysicalConditions);
    }
  };

  const handlePresentingProblemChange = (options) => {
    const newPresentingProblems = options || [];
    const { diff, value } = diffArrayOfObjects(
      newPresentingProblems,
      clinicalInformation.presentingProblems
    );
    if (value) {
      if (diff > 0) {
        postPresentingProblems(clientUserID, value, newPresentingProblems);
      } else if (diff < 0) {
        deletePresentingProblems(clientUserID, value, newPresentingProblems);
      }
    }
  };

  const {
    presentingProblems,
    treatmentGoal,
    conditions,
    physicalConditions,
    stageOfChange,
    acuity,
    sleepQuality,
    sleepAidUse,
    socialSupportSystem,
    experiencingSchoolCommunity,
    experiencingSocialMedia,
    hospitalizationHistory,
    traumaticExperience,
    traumaticFlashbacks,
    guilt,
    isolation,
    suicidePlanning,
    suicideIdeation,
    homicidalIdeation,
    homicidalPlanning,
    angerAggression,
    previousRelativesMentalHealthIssues,
    previousMentalHealthIssues,
    previousMentalHealthTreatment,
    collectedAt,
    nycTeensIntake,
  } = clinicalInformation;

  const hasMissingConditionsAlert = journeyItemsHaveToDo(journeyItems);
  const isConditionsMissing = hasMissingConditionsAlert && conditions.length === 0;
  const showUserButtons = !!participants && !!participants.length && participants.length > 1;
  const disabledValues = hasSelectedBillable ? null : conditionsNotBillableIDs;

  return (
    <AccordionContainer
      title="Clinical Information"
      open={false}
      dataQa="clinicalInformationAccordion"
    >
      {showUserButtons && createUserButtons()}
      {roomsPresentingProblems.length && (
        <Select
          placeholder="Presenting problems"
          options={adapterPresentingProblemOptions(roomsPresentingProblems)}
          value={presentingProblems}
          refCallback={(ref) => {
            presentingProblemsRef.current = ref;
          }}
          onChange={handlePresentingProblemChange}
          isMulti
        />
      )}
      <TextInput
        value={treatmentGoal}
        placeholder="Treatment goals"
        multiline
        disabled
        disabledNumberOfLines={6}
      />
      <Select
        key={JSON.stringify({ disabledValues })}
        placeholder="Diagnosis"
        options={conditionsList}
        value={conditions}
        refCallback={(ref) => {
          diagnosisRef.current = ref;
        }}
        onChange={handleConditionChange}
        isValid={!isConditionsMissing}
        isOptionDisabled={(option) => {
          if (!disabledValues?.length) return false;
          return option.value ? disabledValues.includes(Number(option.value)) : false;
        }}
        isSearchable
        isMulti
      />
      {!hasSelectedBillable && businessLineData && !businessLineData.isEAP && (
        <Tiny>Non-billable diagnoses are disabled until a billable diagnosis is selected.</Tiny>
      )}
      {businessLineData?.isEAP && <Tiny>Diagnoses are optional for clients on EAP plans.</Tiny>}
      <Select
        placeholder="Acuity"
        options={getOptionsByField('acuity')}
        value={acuity}
        refCallback={(ref) => {
          acuityRef.current = ref;
        }}
        onChange={createHandleSelectChange('acuity')}
        showIndicatorBeforeClick={isMobile}
        isSearchable={checkIfShouldBeSearchable('acuity')}
      />
      <Select
        placeholder="Stage of change"
        options={getOptionsByField('stageOfChange')}
        value={stageOfChange}
        refCallback={(ref) => {
          stageOfChangeRef.current = ref;
        }}
        onChange={createHandleSelectChange('stageOfChange')}
        showIndicatorBeforeClick={isMobile}
        isSearchable={checkIfShouldBeSearchable('stageOfChange')}
      />
      <Select
        placeholder="Physical conditions"
        options={getOptionsByField('physicalConditions')}
        value={physicalConditions}
        refCallback={(ref) => {
          physicalConditionsRef.current = ref;
        }}
        onChange={handlePhysicalConditionChange}
        isSearchable={checkIfShouldBeSearchable('physicalConditions')}
        isMulti
      />
      <Select
        placeholder="Quality of sleep"
        options={getOptionsByField('sleepQuality')}
        value={sleepQuality}
        refCallback={(ref) => {
          sleepQualityRef.current = ref;
        }}
        onChange={createHandleSelectChange('sleepQuality')}
        isSearchable={checkIfShouldBeSearchable('sleepQuality')}
        showIndicatorBeforeClick={isMobile}
      />
      <Select
        placeholder="Use of sleeping aids"
        options={getOptionsByField('sleepAidUse')}
        value={sleepAidUse}
        refCallback={(ref) => {
          sleepAidUseRef.current = ref;
        }}
        onChange={createHandleSelectChange('sleepAidUse')}
        showIndicatorBeforeClick={isMobile}
        isSearchable={checkIfShouldBeSearchable('sleepAidUse')}
      />
      {nycTeensIntake && (
        <>
          <Select
            placeholder="Social support system"
            options={getOptionsByField('socialSupportSystem')}
            value={socialSupportSystem}
            refCallback={(ref) => {
              socialSupportSystemRef.current = ref;
            }}
            onChange={createHandleSelectChange('socialSupportSystem')}
            showIndicatorBeforeClick={isMobile}
            isSearchable={checkIfShouldBeSearchable('socialSupportSystem')}
          />
          <Select
            placeholder="Experience at school or community"
            options={getOptionsByField('experiencingSchoolCommunity')}
            value={experiencingSchoolCommunity}
            refCallback={(ref) => {
              experiencingSchoolCommunityRef.current = ref;
            }}
            onChange={createHandleSelectChange('experiencingSchoolCommunity', true)}
            isSearchable={checkIfShouldBeSearchable('experiencingSchoolCommunity')}
            isMulti
          />
        </>
      )}
      <Select
        placeholder="Experience with social media"
        options={getOptionsByField('experiencingSocialMedia')}
        value={experiencingSocialMedia}
        refCallback={(ref) => {
          experiencingSocialMediaRef.current = ref;
        }}
        onChange={createHandleSelectChange('experiencingSocialMedia', true)}
        isSearchable={checkIfShouldBeSearchable('experiencingSocialMedia')}
        isMulti
      />
      <Select
        placeholder="Previous mental health experience"
        options={getOptionsByField('previousMentalHealthTreatment')}
        value={previousMentalHealthTreatment}
        refCallback={(ref) => {
          previousMentalHealthTreatmentRef.current = ref;
        }}
        onChange={createHandleSelectChange('previousMentalHealthTreatment')}
        showIndicatorBeforeClick={isMobile}
        isSearchable={checkIfShouldBeSearchable('previousMentalHealthTreatment')}
      />
      <Select
        placeholder="Previously diagnosed mental health conditions"
        options={getOptionsByField('previousMentalHealthIssues')}
        value={previousMentalHealthIssues}
        refCallback={(ref) => {
          previousMentalHealthIssuesRef.current = ref;
        }}
        onChange={createHandleSelectChange('previousMentalHealthIssues', true)}
        isSearchable={checkIfShouldBeSearchable('previousMentalHealthIssues')}
        placeholderIsTwoLines
        isMulti
      />
      <Select
        placeholder="Previously hospitalized (last 3 years)"
        options={getOptionsByField('hospitalizationHistory')}
        value={hospitalizationHistory}
        refCallback={(ref) => {
          hospitalizationHistoryRef.current = ref;
        }}
        onChange={createHandleSelectChange('hospitalizationHistory')}
        isSearchable={checkIfShouldBeSearchable('hospitalizationHistory')}
        showIndicatorBeforeClick={isMobile}
      />
      <Select
        placeholder="Immediate relatives diagnosed with mental health conditions"
        options={getOptionsByField('previousRelativesMentalHealthIssues')}
        value={previousRelativesMentalHealthIssues}
        refCallback={(ref) => {
          previousRelativesMentalHealthIssuesRef.current = ref;
        }}
        onChange={createHandleSelectChange('previousRelativesMentalHealthIssues', true)}
        isSearchable={checkIfShouldBeSearchable('previousRelativesMentalHealthIssues')}
        placeholderIsTwoLines
        isMulti
      />
      <Select
        placeholder="Experience with traumatic event"
        options={yesNoOptions as OptionsType<any>}
        value={traumaticExperience}
        refCallback={(ref) => {
          traumaticExperienceRef.current = ref;
        }}
        onChange={createHandleSelectChange('traumaticExperience')}
        showIndicatorBeforeClick={isMobile}
        isSearchable={false}
      />
      <Select
        placeholder="Struggle with situation, reminder, dreams or thoughts"
        options={yesNoOptions as OptionsType<any>}
        value={traumaticFlashbacks}
        refCallback={(ref) => {
          traumaticFlashbacksRef.current = ref;
        }}
        onChange={createHandleSelectChange('traumaticFlashbacks')}
        showIndicatorBeforeClick={isMobile}
        isSearchable={false}
        placeholderIsTwoLines
      />
      <Select
        placeholder="Guilt or self-blame"
        options={yesNoOptions as OptionsType<any>}
        value={guilt}
        refCallback={(ref) => {
          guiltRef.current = ref;
        }}
        onChange={createHandleSelectChange('guilt')}
        showIndicatorBeforeClick={isMobile}
        isSearchable={false}
      />
      <Select
        placeholder="Distance or indifference from people or activities"
        options={yesNoOptions as OptionsType<any>}
        value={isolation}
        refCallback={(ref) => {
          isolationRef.current = ref;
        }}
        onChange={createHandleSelectChange('isolation')}
        showIndicatorBeforeClick={isMobile}
        isSearchable={false}
        placeholderIsTwoLines
      />

      <RiskContainer
        label="Suicidal ideation"
        value={suicideIdeation?.label}
        collectedAt={collectedAt}
        displayReminderIcon={
          suicideIdeation?.label === suicideIdeationOrPlanning.yes_in_the_past_30_days
        }
      />
      <RiskContainer
        label="Suicidal planning"
        value={suicidePlanning?.label}
        collectedAt={collectedAt}
        displayReminderIcon={
          suicidePlanning?.label === suicideIdeationOrPlanning.yes_in_the_past_30_days
        }
      />
      {nycTeensIntake && (
        <>
          <RiskContainer
            label="Homicidal ideation"
            value={homicidalIdeation?.label}
            collectedAt={collectedAt}
            displayReminderIcon={
              homicidalIdeation?.label === homicidalIdeationOrPlanning.yes_in_the_past_30_days
            }
          />
          <RiskContainer
            label="Homicidal planning"
            value={homicidalPlanning?.label}
            collectedAt={collectedAt}
            displayReminderIcon={
              homicidalPlanning?.label === homicidalIdeationOrPlanning.yes_in_the_past_30_days
            }
          />
          <RiskContainer
            label="Excessive anger or violence"
            value={angerAggression?.value ? 'Yes' : 'No'}
            collectedAt={collectedAt}
            displayReminderIcon={false}
          />
        </>
      )}
      <View ref={viewRef} />
      {nycTeensIntake ? (
        <StyledText>
          If the responses require a risk assessment, you can use our
          <RiskAssessmentLink label="risk assessment resources." nycTeensIntakeLink />
        </StyledText>
      ) : (
        <StyledText>
          If the responses require a risk assessment, you can use the
          <RiskAssessmentLink label="C-SSRS assessment." nycTeensIntakeLink={false} />
        </StyledText>
      )}
    </AccordionContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    clinicalInformation: state.clinicalInformation,
    clientUserID: state.customerInformation.clientUserID,
    journeyItems: state.journey.journeyItems,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getClinicalInformation: getClinicalInformationAction,
      setClinicalInformation: setClinicalInformationAction,
      getCustomerInformation: getCustomerInformationAction,
      getPresentingProblems: getPresentingProblemsAction,
      postPresentingProblems: postPresentingProblemsAction,
      deletePresentingProblems: deletePresentingProblemsAction,
      getConditions: getConditionsAction,
      postConditions: postConditionsAction,
      deleteConditions: deleteConditionsAction,
      getPhysicalConditions: getPhysicalConditionsAction,
      postPhysicalConditions: postPhysicalConditionsAction,
      deletePhysicalConditions: deletePhysicalConditionsAction,
      getCurrentSurvey: getCurrentSurveyAction,
    },
    dispatch
  );

const ClinicalInformationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClinicalInformation);

export default withBusinessLine(ClinicalInformationContainer);
