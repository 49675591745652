import { FC, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Select, { ValueType, Styles } from 'react-select';
import { formatRange } from '@fullcalendar/react';
import {
  View,
  OptionType,
  Spinner,
  ExtraHuge,
  ResponsiveLayoutWithHeader,
  Small,
  PanelHeader,
  TabContainer,
  TabContent,
  useEmotionTheme,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { A11yState } from '@talkspace/react-toolkit/src/hooks/a11yContextProvider';
import moment from 'moment-timezone';
import Analytics from 'components/PaymentReports/Analytics';
import { useTSAdminConfig } from 'ts-frontend/hooks/useTSAdminConfig';
import { AdminConfigName } from 'ts-frontend/types';
import styled from '@/core/styled';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import { useLocation } from '@/core/routerLib';
import { getNavBarHeight } from '../Reusable/NavBar/NavBar';
import PaymentReportsHeader from './PaymentReportsHeader';
import PaymentReportsTable from './PaymentReportsTable';
import EmptyStateIcon from './EmptyStateIcon';
import { NOT_AVAILABLE } from './AccordionListItem';
import {
  TransformedPaymentReportRow,
  TransformedPaymentReportWithFormattedTextRow,
  LongTermBonusInterface,
} from './types';
import { getAvailability } from '../../actions/AvailabilityActions';
import { getUserData } from '../../utils/token';
import TabGraphWidget from './TabGraphWidget';
import IncreaseBonusSection from './IncreaseBonusSection';
import BonusModal from './BonusModal';
import useQueryPaymentReports from './hooks/useQueryPaymentReports';
import useQueryPaymentReportDates from './hooks/useQueryPaymentReportDates';

moment.tz.add('America/New_York|EST EDT|50 40|0101|1Lz50 1zb0 Op0');

const customSelectStyles: Partial<Styles<OptionType<Date | undefined>, boolean>> = {
  option: (provided) => {
    return {
      ...provided,
      fontFamily: "'Roboto', sans-serif",
      fontSize: 16,
      fontWeight: 500,
    };
  },
  container: (provided) => {
    return {
      ...provided,
      width: 217,
      alignSelf: 'center',
      position: 'relative',
      bottom: 25,
    };
  },
  control: (provided) => {
    return {
      ...provided,
      fontFamily: "'Roboto', sans-serif",
      borderRadius: 9,
      padding: 6,
      fontSize: 16,
      fontWeight: 500,
    };
  },
};

const getCustomSelectStylesV2 = (
  colors: A11yState['colors']
): Partial<Styles<OptionType<Date | undefined>, boolean>> => {
  return {
    option: (provided, { isSelected }) => {
      return {
        ...provided,
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: 700,
        color: colors.TSBlack,
        height: 42,
        background: isSelected ? colors.whiteLilac : colors.permaPhantomWhite,
        '&:hover': { background: colors.deepTeal, color: colors.permaPhantomWhite },
      };
    },
    container: (provided) => {
      return {
        ...provided,
        bottom: 40,
        color: colors.permaBlueStoneNew,
        width: 246,
      };
    },
    menuList: (provided) => {
      return {
        ...provided,
        maxHeight: 210,
        overflowY: 'auto', // Display scrollbar only when needed
      };
    },
    control: (provided) => {
      return {
        ...provided,
        fontFamily: 'Roboto',
        borderRadius: 5,
        borderColor: colors.permaBlueStoneNew,
        height: 36,
        width: 160,
        fontSize: 14,
        fontWeight: 500,
        color: colors.permaBlueStoneNew,
        '&:hover': { color: colors.permaBlueStoneNew, background: colors.aliceBlue },
        '&:focus-within': { color: colors.permaBlueStoneNew, background: colors.permaSolitude },
      };
    },
    singleValue: (provided) => {
      return {
        ...provided,
        color: colors.permaBlueStoneNew,
        fontWeight: 700,
      };
    },
    dropdownIndicator: (provided) => {
      return {
        ...provided,
        color: colors.permaBlueStoneNew,
        fontWeight: 700,
        '&:hover': { color: colors.permaBlueStoneNew },
      };
    },
  };
};

interface Props {
  getAvailability: (userID: number) => void;
  therapistID: number;
  therapistType: string;
  isW2: number;
}

const LONG_TERM_BONUS_DISABLE_DATE = new Date('2023-04-01');

const formatDate = (date: Date) => moment(date).format('MMM D, YYYY');
const formatWordcount = (wordcount: number) => wordcount.toLocaleString();
const formatHeading = (date: Date | undefined) =>
  `${moment(date).format('MMMM')} ${moment(date).format('YYYY')}`;
const formatDateLabel = (date: Date) =>
  `${moment(date).format('YYYY')} - ${moment(date).format('MMMM')}`;
const formatDateLabelV2 = (date: Date) =>
  `${moment(date).format('MMMM')} ${moment(date).format('YYYY')}`;
const formatDateLabelAccordingToIcpBonusFlag = ({ icpBonusFlag, date }) =>
  icpBonusFlag ? formatDateLabelV2(date) : formatDateLabel(date);
const formatTherapistEarnings = (netTherapistEarning: number) => {
  const isNegative = netTherapistEarning < 0;
  return `${isNegative ? '- ' : ''}$${Math.abs(netTherapistEarning).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};
const isBiMonthlyPeriod = (date?: Date, cutoffDate?: Date | null) =>
  !!(date && cutoffDate && date.getTime() >= cutoffDate.getTime());
const getStyles = (width: number) => {
  switch (true) {
    case width <= 750:
      return 'isMobile';
    case width > 750 && width <= 1024:
      return 'isLarge';
    case width > 1024:
      return 'isDesktop';
    default:
      return 'isDesktop';
  }
};
const getContainerStyles = (width: number) => {
  switch (getStyles(width)) {
    case 'isMobile':
      return { width: '100%' };
    case 'isLarge':
      return { width: 699 };
    case 'isDesktop':
      return { width: 968 };
    default:
      return undefined as never;
  }
};

const PaymentReportsContainerWrapper = styled(View)<{ isIcpBonusVisualization: boolean }>(
  ({ isIcpBonusVisualization, theme: { window } }) => {
    const { isMobile, isLarge, isDesktop } = window;
    const conditionalStyle = isIcpBonusVisualization
      ? {}
      : { height: `calc(100vh - 68px)`, overflowY: 'scroll' };
    return {
      overflowX: 'hidden',

      ...(isMobile && {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 30,
        paddingBottom: 30,
      }),
      ...((isLarge || isDesktop) && {
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 40,
        paddingBottom: 40,
      }),
      ...conditionalStyle,
    };
  }
);

const PaymentReportsEmptyStateWrapper = styled(View)({
  textAlign: 'center',
  margin: '0 auto',
  marginTop: 187,
  alignItems: 'center',
  '@media(max-width:400px)': {
    width: 293,
  },
});

const GraphWidgetWrapper = styled(View)<{
  maxWidth: number | string;
}>(({ maxWidth }) => {
  return {
    marginTop: 50,
    width: '100%',
    alignItems: 'center',
    maxWidth,
    alignSelf: 'center',
  };
});

const DropDownWrapper = styled(View)({
  width: '100%',
  alignItems: 'left',
  zIndex: 2,
});

const transformPaymentReports =
  (newOpenItems: { [key: string]: boolean }) => (acc, currentValue) => {
    const {
      roomID,
      userNickname,
      netTherapistEarning,
      clientWordsCapped,
      therapistWordsCapped,
      startDate,
      endDate,
      longTerm,
      payoutItemType,
    } = currentValue;
    let detailDateString = '';
    if (startDate)
      detailDateString = endDate
        ? `${formatDate(startDate)} -\n${formatDate(endDate)}`
        : formatDate(startDate);
    const formattedNetTherapistEarning =
      netTherapistEarning !== null && netTherapistEarning !== undefined
        ? formatTherapistEarnings(netTherapistEarning)
        : NOT_AVAILABLE;

    const isManualAdjustment = payoutItemType === 'manual_adjustment';
    // Manual adjustments must be aggregated into same bucket:
    const groupID = isManualAdjustment ? 'manual-adjustment' : roomID;

    if (!acc[groupID]) {
      acc[groupID] = {
        roomID,
        userNickname,
        longTerm,
        clientWordsCapped,
        therapistWordsCapped,
        netTherapistEarning,
        rows: [
          {
            ...currentValue,
            detailDateString,
            netTherapistEarning: formattedNetTherapistEarning,
          },
        ],
      };
      // eslint-disable-next-line no-param-reassign
      newOpenItems[roomID] = false;
    } else {
      const previousClientWordsCapped = acc[groupID].clientWordsCapped || 0;
      const currentClientWordsCapped = clientWordsCapped || 0;
      const updatedClientWordsCapped = currentClientWordsCapped + previousClientWordsCapped;

      acc[groupID].clientWordsCapped = updatedClientWordsCapped;

      const previousTherapistWordsCapped = acc[groupID].therapistWordsCapped || 0;
      const currentTherapistWordsCapped = therapistWordsCapped || 0;
      const updatedTherapistWordsCapped =
        currentTherapistWordsCapped + previousTherapistWordsCapped;
      acc[groupID].therapistWordsCapped = updatedTherapistWordsCapped;

      const previousTherapistEarning = acc[groupID].netTherapistEarning || 0;
      const updatedNetTherapistEarning = netTherapistEarning + previousTherapistEarning;
      acc[groupID].netTherapistEarning = updatedNetTherapistEarning;

      acc[groupID].rows.push({
        ...currentValue,
        detailDateString,
        netTherapistEarning: formattedNetTherapistEarning,
      });
    }
    return acc;
  };

const formatReportText = (row: TransformedPaymentReportRow) => {
  const { netTherapistEarning, clientWordsCapped, therapistWordsCapped } = row;
  return {
    ...row,
    netTherapistEarning: formatTherapistEarnings(netTherapistEarning || 0),
    clientWordsCapped: clientWordsCapped ? formatWordcount(clientWordsCapped) : NOT_AVAILABLE,
    therapistWordsCapped: therapistWordsCapped
      ? formatWordcount(therapistWordsCapped)
      : NOT_AVAILABLE,
  };
};

const sortRows = (a, b) => {
  const aNickname = a.userNickname;
  const bNickname = b.userNickname;
  if (!aNickname && bNickname) return 1;
  if (aNickname && !bNickname) return -1;
  if (!aNickname && !bNickname) return 0;

  return aNickname.toLowerCase().localeCompare(bNickname.toLowerCase());
};

const PaymentReportsEmptyState: FunctionComponent = () => (
  <PaymentReportsEmptyStateWrapper>
    <EmptyStateIcon />
    <ExtraHuge style={{ marginTop: 25 }}>You don’t have any payment reports yet</ExtraHuge>
    <Small style={{ marginTop: 10 }}>
      New payment reports become available on the 15th of the month.
    </Small>
  </PaymentReportsEmptyStateWrapper>
);

const PaymentReportsContainer: FunctionComponent<Props> = ({
  getAvailability: getAvailabilityAction,
  therapistID,
  therapistType,
  isW2,
}) => {
  const { colors } = useEmotionTheme();
  const { isMobile, isDesktop, width: currentWindowWidth } = useWindowWidthState();
  const location = useLocation();

  const isICP = isW2 === 0 && (therapistType === 'primary' || therapistType === 'psychiatrist');

  const [dropdownValue, setDropdownValue] = useState<OptionType<Date | undefined>>({
    value: undefined,
    label: '',
  });
  const [grandTotal, setGrandTotal] = useState<string | undefined>(undefined);
  const [earnings1st, setEarnings1st] = useState<string | undefined>(undefined);
  const [earnings2nd, setEarnings2nd] = useState<string | undefined>(undefined);
  const [firstHalfMonthStatement, setFirstHalfMonthStatement] = useState<string | undefined>(
    undefined
  );
  const [secondHalfMonthStatement, setSecondHalfMonthStatement] = useState<string | undefined>(
    undefined
  );
  const [yearStatement, setYearStatement] = useState<string | undefined>(undefined);
  const [lastUpdated, setLastUpdated] = useState<string | undefined>(undefined);
  const [longTermBonus, setLongTermBonus] = useState<LongTermBonusInterface | null>(null);
  const [openItems, setOpenItems] = useState<{ [key: number]: boolean }>({});
  const [transformedPaymentReports, setTransformedPaymentReports] = useState<
    TransformedPaymentReportWithFormattedTextRow[]
  >([]);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [openItems2nd, setOpenItems2nd] = useState<{ [key: number]: boolean }>({});
  const [isExpanded2nd, setIsExpanded2nd] = useState<boolean>(false);
  const [transformedPaymentReports2nd, setTransformedPaymentReports2nd] = useState<
    TransformedPaymentReportWithFormattedTextRow[]
  >([]);
  const [shouldShowEmptyState, setShouldShowEmptyState] = useState<boolean>(false);
  const [monthHasProvisionalPeriod, setMonthHasProvisionalPeriod] = useState<boolean>(false);

  const [month, setMonth] = useState<number | null>(null);
  const [year, setYear] = useState<number | null>(null);
  const isInFrame = ReactFrameService.instance().isInFrame();

  const { data: biMonthlyPayoutConfig, isLoading: isLoadingBiMonthlyPayoutConfig } =
    useTSAdminConfig(AdminConfigName.BI_MONTHLY_PAYOUT, true);

  const { data: consolidatedICPRatesConfig, isLoading: isLoadingConsolidatedICPRatesConfig } =
    useTSAdminConfig(AdminConfigName.CONSOLIDATED_ICP_RATES, true);

  const isSelectedPayoutBeforeConsolidatedICPRatesCutoffDate =
    consolidatedICPRatesConfig?.cutoffDate &&
    dropdownValue.value &&
    new Date(dropdownValue.value) < new Date(consolidatedICPRatesConfig.cutoffDate);

  const icpBonusVisualizationSwitch = therapistType !== 'psychiatrist' && isW2 === 0;
  const icpBonusVisualizationSwitchWithCutoff =
    icpBonusVisualizationSwitch && isSelectedPayoutBeforeConsolidatedICPRatesCutoffDate;

  const { data: paymentReportDates, isLoading: isLoadingPaymentReportDates } =
    useQueryPaymentReportDates(therapistID, !!therapistID && isICP);

  const { data: paymentReports, isLoading: isLoadingPaymentReports } = useQueryPaymentReports(
    therapistID,
    dropdownValue.value,
    !!therapistID && isICP && !!dropdownValue.value
  );

  useEffect(() => {
    Analytics.trackBonusVizPageLoadEvent();
  }, []);

  useEffect(() => {
    if (therapistType === undefined) {
      getAvailabilityAction(getUserData().id);
    }
  }, [getAvailabilityAction, therapistType]);

  useEffect(() => {
    if (dropdownValue.value === undefined && paymentReportDates?.length) {
      setDropdownValue({
        value: paymentReportDates[0],
        label: formatDateLabelAccordingToIcpBonusFlag({
          icpBonusFlag: icpBonusVisualizationSwitch,
          date: paymentReportDates[0],
        }),
      });
    }
  }, [dropdownValue.value, icpBonusVisualizationSwitch, paymentReportDates]);

  useEffect(() => {
    const selectedYear = moment(dropdownValue.value).year();
    const selectedMonth = moment(dropdownValue.value).month();
    setYear(selectedYear);
    setMonth(selectedMonth);
    Analytics.trackBonusVizDropdownMonthChangedEvent({
      selectedYear,
      selectedMonth: selectedMonth + 1,
    });
  }, [dropdownValue.value]);

  useEffect(() => {
    if (paymentReportDates && !paymentReportDates.length) {
      setShouldShowEmptyState(true);
    }
  }, [paymentReportDates]);

  useEffect(() => {
    if (dropdownValue.value !== undefined && therapistID) {
      setTransformedPaymentReports([]);
      setLongTermBonus(null);
      setGrandTotal(undefined);
      setEarnings1st(undefined);
      setEarnings2nd(undefined);
    }
  }, [therapistID, dropdownValue, isICP]);

  const isBiMonthly = isBiMonthlyPeriod(
    dropdownValue.value && new Date(dropdownValue.value),
    biMonthlyPayoutConfig?.cutoffDate && new Date(biMonthlyPayoutConfig.cutoffDate)
  );

  useEffect(() => {
    if (isLoadingBiMonthlyPayoutConfig || isLoadingConsolidatedICPRatesConfig) {
      return;
    }
    if (!paymentReports) {
      return;
    }
    if (paymentReports.rows) {
      const firstHalfMonthRows = isBiMonthly
        ? paymentReports.rows.filter((value) =>
            moment.utc(value.payoutPeriod).isSame(moment.utc(dropdownValue.value).startOf('month'))
          )
        : paymentReports.rows;
      const secondHalfMonthRows = isBiMonthly
        ? paymentReports.rows.filter((value) =>
            moment.utc(value.payoutPeriod).isSame(moment.utc(dropdownValue.value).set('date', 16))
          )
        : [];
      secondHalfMonthRows.sort((a, b) => (a.startDate < b.startDate ? -1 : 1));
      firstHalfMonthRows.sort((a, b) => (a.startDate < b.startDate ? -1 : 1));
      const newOpenItems = {};
      const newOpenItems2 = {};
      const newTransformedPaymentReports: TransformedPaymentReportRow[] = Object.values(
        firstHalfMonthRows.reduce(
          transformPaymentReports(newOpenItems),
          {} as { [key: number]: TransformedPaymentReportRow }
        )
      );
      const transformedPaymentReportsWithFormattedText: TransformedPaymentReportWithFormattedTextRow[] =
        newTransformedPaymentReports.map(formatReportText).sort(sortRows);

      setTransformedPaymentReports(transformedPaymentReportsWithFormattedText);
      setOpenItems(newOpenItems);
      let newGrandTotal = paymentReports.rows.reduce(
        (total, item) => item.netTherapistEarning + total,
        0
      );
      if (paymentReports.longTermBonus) {
        const {
          longTermBonus: { earnings },
        } = paymentReports;
        newGrandTotal += Number(earnings);
      }
      const grandTotalRounded = Math.round(newGrandTotal * 100) / 100;
      setGrandTotal(formatTherapistEarnings(grandTotalRounded));

      const earnings1Total = firstHalfMonthRows.reduce(
        (total, item) => item.netTherapistEarning + total,
        0
      );
      const earnings1stRounded = Math.round(earnings1Total * 100) / 100;
      setEarnings1st(formatTherapistEarnings(earnings1stRounded));
      if (isBiMonthly) {
        const newTransformedPaymentReports2: TransformedPaymentReportRow[] = Object.values(
          secondHalfMonthRows.reduce(
            transformPaymentReports(newOpenItems2),
            {} as { [key: number]: TransformedPaymentReportRow }
          )
        );
        const transformedPaymentReportsWithFormattedText2: TransformedPaymentReportWithFormattedTextRow[] =
          newTransformedPaymentReports2.map(formatReportText).sort(sortRows);
        setTransformedPaymentReports2nd(transformedPaymentReportsWithFormattedText2);

        const earnings2Total = secondHalfMonthRows.reduce(
          (total, item) => item.netTherapistEarning + total,
          0
        );
        const earnings2ndRounded = Math.round(earnings2Total * 100) / 100;
        setEarnings2nd(formatTherapistEarnings(earnings2ndRounded));
        setOpenItems2nd(newOpenItems2);
      }
    }
    if (paymentReports.longTermBonus) {
      setLongTermBonus(paymentReports.longTermBonus);
    }
    if (paymentReports) {
      if (paymentReports.lastUpdated) {
        const lastUpdatedStr = moment
          .utc(paymentReports.lastUpdated)
          .tz('America/New_York')
          .format('h:mmA z, MMM DD, YYYY');
        setLastUpdated(lastUpdatedStr);
      }
      if (paymentReports.provisionalPeriods?.length > 0) {
        setMonthHasProvisionalPeriod(true);
      } else {
        const now = moment.utc().startOf('month');
        const payoutDate = moment.utc(dropdownValue.value).startOf('month');
        if (
          now.isSame(payoutDate) &&
          (!transformedPaymentReports?.length || !transformedPaymentReports2nd?.length)
        ) {
          setMonthHasProvisionalPeriod(true);
        } else {
          setMonthHasProvisionalPeriod(false);
        }
      }
    }
    const endDate = isBiMonthly
      ? moment.utc(dropdownValue.value).set('date', 15)
      : moment.utc(dropdownValue.value).endOf('month');

    setYearStatement(
      formatRange(moment.utc(dropdownValue.value).startOf('month').toDate(), endDate.toDate(), {
        year: 'numeric',
      })
    );
    setFirstHalfMonthStatement(
      formatRange(moment.utc(dropdownValue.value).startOf('month').toDate(), endDate.toDate(), {
        month: 'long',
        day: 'numeric',
        timeZone: 'utc',
      })
    );
    setSecondHalfMonthStatement(
      formatRange(
        moment.utc(dropdownValue.value).set('date', 16).toDate(),
        moment.utc(dropdownValue.value).endOf('month').toDate(),
        {
          month: 'long',
          day: 'numeric',
          timeZone: 'utc',
        }
      )
    );
  }, [
    isLoadingBiMonthlyPayoutConfig,
    isLoadingConsolidatedICPRatesConfig,
    isBiMonthly,
    paymentReports,
    dropdownValue.value,
    transformedPaymentReports.length,
    transformedPaymentReports2nd?.length,
  ]);

  const { value: dropdownValueLabel } = dropdownValue;
  const handleDropdownChange = (value: ValueType<OptionType<Date | undefined>, boolean>) => {
    setDropdownValue(value as OptionType<Date>);
    setIsExpanded(false);
    setOpenItems([]);
  };

  const handleAccordionClick = (roomID: number) => {
    if (openItems) {
      setOpenItems({ ...openItems, [roomID]: !openItems[roomID] });
      document.getElementById(`${roomID || 'payment-adjustment'}-toggle-button`)?.blur();
    }
  };

  const handleAccordionClick2nd = (roomID: number) => {
    if (openItems2nd) {
      setOpenItems2nd({ ...openItems2nd, [roomID]: !openItems2nd[roomID] });
      document.getElementById(`${roomID || 'payment-adjustment'}-toggle-button`)?.blur();
    }
  };

  const setAllItems = (items: { [key: string]: boolean }, value: boolean) =>
    Object.keys(items).reduce((acc, key) => {
      acc[key] = value;
      return acc;
    }, {});

  const handleExpandAllClick = () => {
    setOpenItems(setAllItems(openItems, true));
    setIsExpanded(true);
  };

  const handleCollapseAllClick = () => {
    setOpenItems(setAllItems(openItems, false));
    setIsExpanded(false);
  };

  const handleExpandAllClick2nd = () => {
    setOpenItems2nd(setAllItems(openItems2nd, true));
    setIsExpanded2nd(true);
  };

  const handleCollapseAllClick2nd = () => {
    setOpenItems2nd(setAllItems(openItems2nd, false));
    setIsExpanded2nd(false);
  };

  const getOptions = (array: Array<Date>) =>
    array?.map((item) => {
      return {
        value: item,
        label: formatDateLabelAccordingToIcpBonusFlag({
          icpBonusFlag: icpBonusVisualizationSwitch,
          date: item,
        }),
      };
    });

  const containerStyles = getContainerStyles(currentWindowWidth);

  const hideHeader = Boolean(new URLSearchParams(location.search).get('noHeader'));
  const isLongTermBonusDisabled = Boolean(
    dropdownValue.value && new Date(dropdownValue.value) >= LONG_TERM_BONUS_DISABLE_DATE
  );

  const zendeskLink =
    therapistType === 'psychiatrist'
      ? '/hc/en-us/articles/16196409219099'
      : '/hc/en-us/articles/360056628992';

  const Header: FC = useCallback(
    ({ children }) => {
      if (hideHeader && isInFrame) {
        return (
          <ResponsiveLayoutWithHeader
            headerHeight={getNavBarHeight()}
            renderHeader={() => (
              <PanelHeader
                isMobile={isMobile}
                title="Payment Reports"
                onRightPress={() => ReactFrameService.instance().closePopup()}
              />
            )}
          >
            {children}
          </ResponsiveLayoutWithHeader>
        );
      }
      return <>{children}</>;
    },
    [hideHeader, isInFrame, isMobile]
  );

  const [isBonusModalVisible, setIsBonusModalVisible] = useState<boolean>(false);

  const isLoading = isLoadingPaymentReports || isLoadingPaymentReportDates;

  return (
    <Header>
      {shouldShowEmptyState || !isICP ? (
        <PaymentReportsEmptyState />
      ) : (
        <PaymentReportsContainerWrapper isIcpBonusVisualization={icpBonusVisualizationSwitch}>
          {!isLoading &&
            !shouldShowEmptyState &&
            icpBonusVisualizationSwitch &&
            year &&
            month !== null && (
              <>
                <BonusModal
                  year={year}
                  month={month}
                  onBackdropPress={() => {
                    setIsBonusModalVisible(false);
                  }}
                  hide={!isBonusModalVisible}
                />
                <GraphWidgetWrapper maxWidth={containerStyles.width}>
                  <DropDownWrapper>
                    <Select
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      isOpen={false}
                      options={getOptions(paymentReportDates || [])}
                      value={dropdownValue}
                      onChange={handleDropdownChange}
                      styles={getCustomSelectStylesV2(colors)}
                    />
                  </DropDownWrapper>
                  {icpBonusVisualizationSwitchWithCutoff && (
                    <>
                      <TabGraphWidget
                        setIsBonusModalVisible={setIsBonusModalVisible}
                        year={year}
                        month={month}
                      />
                      <IncreaseBonusSection year={year} month={month} />
                    </>
                  )}
                </GraphWidgetWrapper>
              </>
            )}
          <View
            style={{
              border: icpBonusVisualizationSwitch ? '' : '1px solid #E7EAEC',
              width: '100%',
              maxWidth: containerStyles.width,
              marginLeft: 20,
              marginRight: 20,
              marginTop: 40,
              marginBottom: 50,
              borderRadius: 7,
              paddingBottom: isMobile ? 25 : 115,
              alignSelf: 'center',
            }}
          >
            {!icpBonusVisualizationSwitch && (
              <Select
                components={{
                  IndicatorSeparator: () => null,
                }}
                isOpen={false}
                options={getOptions(paymentReportDates || [])}
                value={dropdownValue}
                onChange={handleDropdownChange}
                styles={customSelectStyles}
              />
            )}

            {isLoading || grandTotal === undefined ? (
              <View style={containerStyles}>
                <Spinner
                  isLoading
                  containerStyle={{
                    marginBottom: '30%',
                    transform: 'scale(3)',
                    ...(isInFrame &&
                      hideHeader && {
                        flex: 1,
                        marginTop: 0,
                        marignBottom: 0,
                      }),
                  }}
                />
              </View>
            ) : (
              <>
                {icpBonusVisualizationSwitchWithCutoff ? (
                  <View style={{ fontSize: 18, fontWeight: 700, marginBottom: 12 }}>
                    Earnings by pay period
                  </View>
                ) : (
                  <PaymentReportsHeader
                    isProvisional={monthHasProvisionalPeriod}
                    headingText={formatHeading(dropdownValueLabel)}
                    grandTotal={grandTotal}
                    longTermBonusText={longTermBonus?.text}
                    isLongTermBonusDisabled={isLongTermBonusDisabled}
                    isMobile={isMobile}
                    isDesktop={isDesktop}
                    lastUpdated={lastUpdated}
                    zendeskLink={zendeskLink}
                  />
                )}

                <TabContainer
                  tabContainerStyle={{
                    borderBottom: `1px solid ${colors.altoGrey}`,
                  }}
                  tabStyle={{
                    marginRight: 0,
                    fontWeight: 600,
                    color: colors.slateGrey,
                    fontSize: 15,
                    paddingLeft: isMobile ? 20 : 10,
                    paddingRight: isMobile ? 20 : 10,
                  }}
                  activeTabStyle={{
                    fontWeight: 600,
                    color: colors.permaBlueStoneNew,
                    borderBottom: `solid ${colors.permaBlueStoneNew} 4px`,
                  }}
                  tabContentWrapperStyle={{
                    marginTop: 20,
                    padding: 10,
                    border: icpBonusVisualizationSwitch ? '1px solid #D1D6E1' : '',
                    borderRadius: icpBonusVisualizationSwitch ? 7 : 0,
                  }}
                  tabHoverStyle={{ backgroundColor: '#EFF3FA' }}
                >
                  <TabContent
                    title={
                      icpBonusVisualizationSwitch
                        ? `${firstHalfMonthStatement}${isMobile ? '' : ' earnings'}`
                        : `Statement: ${firstHalfMonthStatement}`
                    }
                    hide={false}
                  >
                    <PaymentReportsTable
                      provisionalPeriods={paymentReports?.provisionalPeriods}
                      isLongTermBonusDisabled={isLongTermBonusDisabled}
                      payoutDate={dropdownValue.value}
                      paymentReports={transformedPaymentReports}
                      therapistEarnings={
                        longTermBonus ? formatTherapistEarnings(longTermBonus.earnings) : undefined
                      }
                      grandTotal={earnings1st}
                      activeRooms={longTermBonus?.activeRooms}
                      handleAccordionClick={handleAccordionClick}
                      openItems={openItems}
                      isExpanded={isExpanded}
                      handleExpandAllClick={handleExpandAllClick}
                      handleCollapseAllClick={handleCollapseAllClick}
                      isPsych={therapistType === 'psychiatrist'}
                      statementPeriod={firstHalfMonthStatement}
                      yearStatement={yearStatement}
                      zendeskLink={zendeskLink}
                    />
                  </TabContent>
                  {isBiMonthly && (
                    <TabContent
                      title={
                        icpBonusVisualizationSwitch
                          ? `${secondHalfMonthStatement}${isMobile ? '' : ' earnings'}`
                          : `Statement: ${secondHalfMonthStatement}`
                      }
                      hide={false}
                    >
                      <PaymentReportsTable
                        provisionalPeriods={paymentReports?.provisionalPeriods}
                        isLongTermBonusDisabled={isLongTermBonusDisabled}
                        isSecondHalf
                        payoutDate={dropdownValue.value}
                        paymentReports={transformedPaymentReports2nd}
                        handleAccordionClick={handleAccordionClick2nd}
                        grandTotal={earnings2nd}
                        openItems={openItems2nd}
                        isExpanded={isExpanded2nd}
                        handleExpandAllClick={handleExpandAllClick2nd}
                        handleCollapseAllClick={handleCollapseAllClick2nd}
                        isPsych={therapistType === 'psychiatrist'}
                        statementPeriod={secondHalfMonthStatement}
                        yearStatement={yearStatement}
                        zendeskLink={zendeskLink}
                      />
                    </TabContent>
                  )}
                </TabContainer>
              </>
            )}
          </View>
        </PaymentReportsContainerWrapper>
      )}
    </Header>
  );
};
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getAvailability,
    },
    dispatch
  );
const mapStateToProps = (state) => {
  return {
    therapistID: state.availability.id,
    therapistType: state.availability.therapistType,
    isW2: state.availability.isW2,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentReportsContainer);
