import { View, styled } from '@talkspace/react-toolkit';
import { InRoomSchedulingProvider } from 'inRoomScheduling';
import { useLocation } from '@/core/routerLib';
import ProgressNoteForm from '../../Room/CRMContainer/NotesTab/ProgressNoteForm';
import { FormMode } from '../../Room/CRMContainer/NotesTab/types';

const Wrapper = styled(View)({
  width: '100%',
  height: '100%',
  position: 'fixed',
  background: 'rgba(104, 118, 141, 0.5)',
  top: 0,
  left: 0,
});

const InnerContainer = styled(View)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      width: isMobile ? '100%' : 375,
      height: '100dvh',
      background: colors.white,
    };
  }
);

const getFormMode = (pathname: string): FormMode => {
  let mode: FormMode = 'create';
  if (pathname.includes('/edit')) {
    mode = 'edit';
  }
  if (pathname.includes('/view')) {
    mode = 'view';
  }
  return mode;
};

const ProgressNoteModal = () => {
  const location = useLocation();

  const mode = getFormMode(location.pathname);

  if (!location.pathname.includes('/progress-notes')) {
    return null;
  }

  return (
    <Wrapper>
      <InnerContainer>
        <InRoomSchedulingProvider>
          <ProgressNoteForm mode={mode} />
        </InRoomSchedulingProvider>
      </InnerContainer>
    </Wrapper>
  );
};

export default ProgressNoteModal;
