import { useState, useRef, useCallback, useEffect } from 'react';
import { usePanelManagerState } from '@talkspace/react-toolkit';

export interface StateAction {
  type: string;
  completedVideoCallID: string;
}

interface UseCrmModalQueueProps {
  isFirstModalOpen: boolean;
  onNextAction: (nextAction: StateAction) => void;
}

export default function useCrmModalQueue({
  isFirstModalOpen,
  onNextAction,
}: UseCrmModalQueueProps) {
  const [actionQueue, setActionQueue] = useState<Array<StateAction>>([]);
  const processedActionsRef = useRef<Set<string>>(new Set<string>());

  const { isLargeRightPanel } = usePanelManagerState();

  const addToQueue = (action: StateAction) => {
    const isAlreadyProcessed = processedActionsRef.current.has(action.type);
    if (!isAlreadyProcessed) {
      setActionQueue((prevQueue) => {
        const isAlreadyQueued = prevQueue.some((item) => item.type === action.type);
        if (!isAlreadyQueued) {
          return [...prevQueue, action];
        }
        return prevQueue;
      });
    }
  };

  const processQueue = useCallback(() => {
    if (actionQueue.length > 0) {
      const nextAction = actionQueue[0];
      processedActionsRef.current.add(nextAction.type);
      setActionQueue((prevQueue) => prevQueue.slice(1));
      onNextAction(nextAction);
    }
  }, [actionQueue, onNextAction]);

  useEffect(() => {
    if (!isFirstModalOpen && actionQueue.length && !isLargeRightPanel) {
      processQueue();
    }
  }, [processQueue, isFirstModalOpen, actionQueue.length, isLargeRightPanel]);

  return { addToQueue };
}
