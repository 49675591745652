/* eslint-disable no-console */
import SocketIOClient from 'socket.io-client';
import { getAccessToken } from '../token';
import apiHelper from '../api';

const DEBUG_LOG = false;

type eventNames =
  | 'userTyping'
  | 'acksCursors'
  | 'newMessage'
  | 'refreshChatMessages'
  | 'mediaUpdated'
  | 'informed_consent'
  | 'newMessageHeader'
  | 'taskUpdate'
  | 'bookingUpdate'
  | 'room_change'
  | 'refetchSessionStatus'
  | 'updateUnsubmittedSessions'
  | 'updateGeneratedSessionSummary'
  | 'emergency_contact'
  | 'submittedIntakeSurvey'
  | 'talktrackCreated';

export type Listener = (parameterName: boolean) => void;

let instance: SocketService;

export default class SocketService {
  socket!: SocketIOClient.Socket;

  socketState: boolean = false;

  socketStateListeners: Listener[] = [];

  constructor() {
    if (instance) {
      return instance;
    }
    instance = this;
    this.initSocket();
  }

  static instance(): SocketService {
    return new SocketService();
  }

  private initSocket = () => {
    this.socket = SocketIOClient(apiHelper().apiEndpoint, {
      transports: ['websocket'],
    });

    this.socket.on('connect', () => {
      if (DEBUG_LOG) console.log('Sockets: connected');
      this.notifyStateListeners(true);
    });

    this.socket.on('disconnect', (reason: string) => {
      if (DEBUG_LOG) console.log('Sockets: disconnected');
      this.notifyStateListeners(false);
      if (reason === 'io server disconnect') {
        // the disconnection was initiated by the server, you need to reconnect manually
        setTimeout(() => {
          this.socket.connect();
        }, 2000);
      }
    });

    this.socket.on('reconnect', () => {
      if (DEBUG_LOG) console.log('Sockets: reconnect');
    });

    this.socket.on('error', (error: Error) => {
      if (DEBUG_LOG) console.log('Sockets: error', error);
    });
  };

  public emit = (event: string, data: any, timeout: number = 3000): Promise<any> => {
    const sendData = data || {};
    sendData.token = getAccessToken();

    return new Promise((resolve, reject) => {
      if (!this.socket.connected) return reject(new Error('Socket not connected yet'));

      let resolved = false;
      setTimeout(() => {
        if (!resolved) {
          resolved = true;
          reject(new Error('Socket ack timeout'));
        }
      }, timeout);

      return this.socket.emit(event, sendData, (res?: { success: boolean }) => {
        resolved = true;
        if (res && res.success) resolve(res);
        else reject(new Error('Socket no results'));
      });
    });
  };

  public on = (ev: eventNames, fn: Function): void => {
    this.socket.on(ev, fn);
  };

  public off = (ev: eventNames, fn?: Function): void => {
    this.socket.off(ev, fn);
  };

  private notifyStateListeners = (socketOn: boolean): void => {
    this.socketState = socketOn;
    this.socketStateListeners.forEach((fn) => fn(socketOn));
  };

  /**
   * Register callback to socket server state connected or not,
   * the callback will be called immediately with current state
   * @param  {Function} fn callbacks with a boolean parameter
   * @returns void
   */
  public addStateListener = (fn: Listener): void => {
    if (typeof fn !== 'function') return;
    this.socketStateListeners.push(fn);
    fn(this.socketState);
  };

  public removeStateListener = (fn: Listener): void => {
    if (typeof fn !== 'function') return;
    const idx = this.socketStateListeners.indexOf(fn);
    if (idx > -1) this.socketStateListeners.splice(idx, 1);
  };
}
