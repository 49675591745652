import { createContext, useReducer, FunctionComponent, useContext, useCallback } from 'react';
import { GetProgressNoteHelperDataResponse } from 'hooks/notes/useQueryProgressNoteHelperData';
import { ProgressNoteQueryResponse } from 'hooks/notes/useQueryProgressNote';
import { ProgressNoteFormState, ProgressNoteFormActions } from './types';
import getInitialTherapistState from './getInitialTherapistState';
import { FormMode, ProgressNoteFormVariant, VideoCall } from '../../types';
import { UnsubmittedSession } from '../../../../../../hooks/notes/useQueryUnsubmittedSessions';
import { UnsubmittedSessionTask } from '../../../../../../hooks/dashboard/useQueryTaskListV3';

const ProgressNoteFormStateContext = createContext<ProgressNoteFormState | undefined>(undefined);
const ProgressNoteFormActionsContext = createContext<ProgressNoteFormActions | undefined>(
  undefined
);

const progressNoteFormReducer = (
  currentState: ProgressNoteFormState,
  action: { payload?: Partial<ProgressNoteFormState> }
): ProgressNoteFormState => {
  return { ...currentState, ...action.payload };
};

interface Props {
  formMode: FormMode;
  helperData: GetProgressNoteHelperDataResponse;
  selectedNote: ProgressNoteQueryResponse | null;
  unsubmittedSession: UnsubmittedSession | UnsubmittedSessionTask | null;
  unsubmittedSessionID: string | null;
  unsubmittedFreeLiveCalls?: VideoCall[] | null;
  formVariant: ProgressNoteFormVariant;
  clientUserID?: number;
}

export const TherapyProgressNoteFormProvider: FunctionComponent<Props> = ({
  children,
  formMode,
  helperData,
  selectedNote,
  unsubmittedSession,
  unsubmittedFreeLiveCalls,
  unsubmittedSessionID,
  clientUserID,
  formVariant,
}) => {
  const [state, dispatch] = useReducer(
    progressNoteFormReducer,
    getInitialTherapistState({
      formMode,
      helperData,
      selectedNote,
      unsubmittedSession,
      unsubmittedFreeLiveCalls,
      unsubmittedSessionID,
      clientUserID,
      formVariant,
    })
  );

  const setFormState = (newState: Partial<ProgressNoteFormState>): void => {
    dispatch({ payload: newState });
  };

  const resetFormState = (): void => {
    const initialState = getInitialTherapistState({
      formMode,
      helperData,
      selectedNote,
      unsubmittedSession,
      unsubmittedFreeLiveCalls,
      unsubmittedSessionID,
      clientUserID,
      formVariant: 'default',
    });
    dispatch({ payload: initialState });
  };

  const actions: ProgressNoteFormActions = {
    setFormState: useCallback(setFormState, []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    resetFormState: useCallback(resetFormState, []),
  };

  return (
    <ProgressNoteFormStateContext.Provider value={state}>
      <ProgressNoteFormActionsContext.Provider value={actions}>
        {children}
      </ProgressNoteFormActionsContext.Provider>
    </ProgressNoteFormStateContext.Provider>
  );
};

export const useProgressNoteFormState = (): ProgressNoteFormState => {
  const context = useContext(ProgressNoteFormStateContext);
  if (context === undefined) {
    throw new Error('useProgressNoteFormState must be used within a ProgressNoteFormProvider');
  }
  return context;
};

export const useProgressNoteFormActions = (): ProgressNoteFormActions => {
  const context = useContext(ProgressNoteFormActionsContext);
  if (context === undefined) {
    throw new Error('useProgressNoteFormActions must be used within a ProgressNoteFormProvider');
  }
  return context;
};
