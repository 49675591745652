import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import AccordionContainer from '../../../../Reusable/AccordionContainer/AccordionContainer';
import Button from '../../../../Reusable/Buttons/Button';
import {
  getCurrentTreatmentPlan,
  createNewTreatmentPlan,
  getRoomPresentingProblems,
} from '../../../../../actions/TreatmentPlannerActions';
import TreatmentPlannerV2 from './TreatmentPlannerV2';
import TreatmentPlannerIcon from './TreatmentPlannerIcon';
import './TreatmentPlanner.css';

class TreatmentPlanner extends Component {
  constructor(props) {
    super(props);

    this.renderTreatmentPlan = this.renderTreatmentPlan.bind(this);
    this.renderNoTreatmentPlan = this.renderNoTreatmentPlan.bind(this);
    props.getCurrentTreatmentPlan({});
    props.getRoomPresentingProblems(props.roomID);
  }

  renderNoTreatmentPlan() {
    return (
      <div style={{ display: 'flex' }}>
        <TreatmentPlannerIcon
          style={{ display: 'flex', flex: 1, height: 70, width: 68, outline: 'none' }}
        />
        <div
          style={{
            flex: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: 5,
          }}
        >
          <div className="treatment-planner-text">No treatment plan has</div>
          <div className="treatment-planner-text">been created</div>
          <Button
            title="Create treatment plan"
            clickHandler={() => {
              if (
                this.props.currentTreatmentPlan &&
                this.props.currentTreatmentPlan.id &&
                this.props.currentTreatmentPlan.presentingProblems &&
                this.props.currentTreatmentPlan.presentingProblems.length
              ) {
                this.props.history.push(`/room/${this.props.roomID}/create-new-treatment-plan`);
              } else if (this.props.currentTreatmentPlan && this.props.currentTreatmentPlan.id) {
                this.props.history.push(`/room/${this.props.roomID}/treatment-planner-goals`);
              } else {
                this.props.createNewTreatmentPlan([]).then((data) => {
                  this.props.history.push(`/room/${this.props.roomID}/treatment-planner-goals`);
                });
              }
            }}
            style={{
              textAlign: 'left',
              paddingRight: 0,
              marginTop: 25,
              display: 'flex',
              alignSelf: 'flex-end',
            }}
            dataQa="createTreatmentPlanButton"
          />
        </div>
      </div>
    );
  }

  renderTreatmentPlan() {
    if (
      this.props.currentTreatmentPlan &&
      this.props.currentTreatmentPlan.id &&
      this.props.currentTreatmentPlan.presentingProblems &&
      this.props.currentTreatmentPlan.presentingProblems.length
    ) {
      return <TreatmentPlannerV2 treatmentPlan={this.props.currentTreatmentPlan} />;
    }
    return this.renderNoTreatmentPlan();
  }

  render() {
    return (
      <AccordionContainer
        title="Treatment planner"
        childComponents={[this.renderTreatmentPlan()]}
        dataQa="treatmentPlannerAccordion"
      />
    );
  }
}

TreatmentPlanner.propTypes = {};

const mapStateToProps = (state) => {
  return {
    presentingProblems: state.clinicalInformation.presentingProblems,
    currentTreatmentPlan: state.treatmentPlanner.currentTreatmentPlan,
    roomID: state.room.roomID,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { getCurrentTreatmentPlan, createNewTreatmentPlan, getRoomPresentingProblems },
    dispatch
  );
const TreatmentPlannerContainer = connect(mapStateToProps, mapDispatchToProps)(TreatmentPlanner);

export default withRouter(TreatmentPlannerContainer);
