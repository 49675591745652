// TODO: use shared type once live chat is merged
import { LiveSessionModality } from 'ts-frontend/types';
import {
  psychotropicMedications,
  clientProgress,
  nextFollowup,
  appearance,
  attitude,
  behavior,
  speech,
  affect,
  mood,
  thoughtProcesses,
  perception,
  insightOrJudgement,
  states,
  countries,
} from '@talkspace/configs';

export type NoteStatus = 'submitted' | 'draft' | 'needsRevision' | 'pendingReview';

export interface DischargeNoteQueryResponse {
  submittedAt: string;
  roomID: number;
  createdByUser: User;
  status: NoteStatus;
  dischargeDate: string;
  diagnoses: DiagnosisResponse[];
  dischargeReason: string | null;
  clientProgress: string | null;
  referralID: number | null;
  readOnly: boolean;
  statementCertified: boolean;
  dischargePlan: string | null;
  otherDischargeReason: string | null;
  otherReferral: string | null;
}

export interface CaseConsultationNoteFormState {
  consultationDate: Date | null;
  personConsultedName: string | null;
  personConsultedCredentials: string | null;
  consultationSummary: string | null;
  planOfAction: string | null;
  followup: string | null;
  statementCertified: boolean;
}

export interface CaseConsultationNoteFormErrors {
  globalValidationError: boolean;
  personConsultedNameError: boolean;
  personConsultedCredentialsError: boolean;
  consultationSummaryError: boolean;
  statementCertifiedError: boolean;
  consultationDateError: boolean;
}

export interface DischargeNoteFormState {
  dischargeDate: Date;
  diagnoses: Diagnosis[];
  dischargeReason: string | null;
  clientProgress: string | null;
  referralID: number | null;
  statementCertified: boolean;
  dischargePlan: string | null;
  otherDischargeReason: string | null;
  otherReferral: string | null;
}

export interface DischargeNoteFormErrors {
  diagnosesError: boolean;
  globalValidationError: boolean;
  clientProgressError: boolean;
  referralIDError: boolean;
  otherReferralError: boolean;
  dischargePlanError: boolean;
  dischargeReasonError: boolean;
  otherDischargeReasonError: boolean;
  statementCertifiedError: boolean;
}

export interface Diagnosis {
  conditionID: number;
}

export interface DiagnosisResponse {
  conditionID: number;
  createdAt: string | null;
  deletedAt: string | null;
  id: number;
  noteID: number;
}

export interface ProgressNoteSessionAttendeeAttributes {
  id: number;
  noteID: number;
  attendeeName: string | null;
  relationshipToClient: string | null;
  createdAt: string;
  deletedAt: string | null;
}

export type ProgressNoteSessionAttendeeCreationAttributes = Pick<
  ProgressNoteSessionAttendeeAttributes,
  'attendeeName' | 'relationshipToClient' | 'id'
>;

export type SeverityLevel = 'ideation' | 'plan' | 'intent' | 'attempt';

export interface ProgressNoteRiskAssessmentAttributes {
  id: number;
  noteID: number;
  createdAt: string;
  pastSuicidalIdeation: boolean;
  pastSuicidalIdeationSeverity: SeverityLevel | null;
  pastSuicidalIdeationNotes: string | null;
  currentSuicidalIdeation: boolean;
  currentSuicidalIdeationSeverity: SeverityLevel | null;
  currentSuicidalIdeationNotes: string | null;
  pastHomicidalIdeation: boolean;
  pastHomicidalIdeationSeverity: SeverityLevel | null;
  pastHomicidalIdeationNotes: string | null;
  currentHomicidalIdeation: boolean;
  currentHomicidalIdeationSeverity: SeverityLevel | null;
  currentHomicidalIdeationNotes: string | null;
  pastPsychosis: boolean;
  pastPsychosisNotes: string | null;
  currentPsychosis: boolean;
  currentPsychosisNotes: string | null;
  otherAssessment: boolean;
  otherAssessmentNotes: string | null;
}

export type ProgressNoteRiskAssessmentCreationAttributes = Omit<
  ProgressNoteRiskAssessmentAttributes,
  'id' | 'noteID' | 'createdAt'
>;

export interface ProgressNoteSubstanceUseAttributes {
  id: number;
  noteID: number;
  createdAt: string;
  pastSubstanceUse: boolean | null;
  pastCigarettesUseNotes: string | null;
  pastVapingUseNotes: string | null;
  pastAlcoholUseNotes: string | null;
  pastMarijuanaUseNotes: string | null;
  pastStimulantsUseNotes: string | null;
  pastCocaineUseNotes: string | null;
  pastHeroinUseNotes: string | null;
  pastBenzodiazepinesUseNotes: string | null;
  pastOpioidsUseNotes: string | null;
  pastOtherSubstanceUseNotes: string | null;
  currentSubstanceUse: boolean | null;
  currentCigarettesUseNotes: string | null;
  currentVapingUseNotes: string | null;
  currentAlcoholUseNotes: string | null;
  currentMarijuanaUseNotes: string | null;
  currentStimulantsUseNotes: string | null;
  currentCocaineUseNotes: string | null;
  currentHeroinUseNotes: string | null;
  currentBenzodiazepinesUseNotes: string | null;
  currentOpioidsUseNotes: string | null;
  currentOtherSubstanceUseNotes: string | null;
}

export type ProgressNoteSubstanceUseCreationAttributes = Omit<
  ProgressNoteSubstanceUseAttributes,
  'id' | 'noteID' | 'createdAt'
>;

export interface ProgressNoteTreatmentPlanProgressAttributes {
  id: number;
  noteID: number;
  buildRapport: boolean;
  shortTermTreatmentObjective: string | null;
  riskOrBarriersHandling: string | null;
  createdAt: string;
}

export type ProgressNoteTreatmentPlanProgressCreationAttributes = Pick<
  ProgressNoteTreatmentPlanProgressAttributes,
  'buildRapport' | 'shortTermTreatmentObjective' | 'riskOrBarriersHandling'
>;

export type ClientProgress = Exclude<keyof typeof clientProgress, 'closed'>;

export interface TreatmentPlanGoal {
  id: number;
  description: string;
  presentingProblemID: number;
}

export interface TreatmentPlanObjective {
  id: number;
  description: string;
  presentingProblemID: number;
}

export interface TreatmentPlanIntervention {
  id: number;
  description: string;
  presentingProblemID: number;
  objectiveID: number;
}

export interface ProgressNoteTreatmentPlanGoalAttributes {
  id: number;
  noteID: number;
  treatmentPlanGoalID: number;
  overallProgress: ClientProgress | null;
  createdAt: string;
  deletedAt: string | null;
  treatmentPlanGoal: TreatmentPlanGoal;
}

export type ProgressNoteTreatmentPlanGoalCreationAttributes = Pick<
  ProgressNoteTreatmentPlanGoalAttributes,
  'treatmentPlanGoalID' | 'overallProgress'
>;

export interface ProgressNoteTreatmentPlanObjectiveAttributes {
  id: number;
  noteID: number;
  treatmentPlanObjectiveID: number;
  overallProgress: ClientProgress | null;
  createdAt: string;
  deletedAt: string | null;
  treatmentPlanObjective: TreatmentPlanObjective;
}

export type ProgressNoteTreatmentPlanObjectiveCreationAttributes = Pick<
  ProgressNoteTreatmentPlanObjectiveAttributes,
  'treatmentPlanObjectiveID' | 'overallProgress'
>;

export interface ProgressNoteTreatmentPlanInterventionAttributes {
  id: number;
  noteID: number;
  treatmentPlanObjectiveID: number;
  treatmentPlanInterventionID: number;
  createdAt: string;
  deletedAt: string | null;
  treatmentPlanIntervention: TreatmentPlanIntervention;
}

export type ProgressNoteTreatmentPlanInterventionCreationAttributes = Pick<
  ProgressNoteTreatmentPlanInterventionAttributes,
  'treatmentPlanObjectiveID' | 'treatmentPlanInterventionID'
>;

export interface ProgressNoteSessionSummaryAttributes {
  id: number;
  noteID: number;
  createdAt: string;
  summary: string | null;
  userRoomSurveyID: number | null;
  followupPlan: string | null;
  recommendations: string | null;
  presentIllnessHistory: string | null;
  assessment: string | null;
  patientReport: string | null;
  clientState?: keyof typeof states | null;
  clientCountry?: keyof typeof countries | null;
  clientAppropriateForTelehealth: boolean | null;
  clientRequestedLanguageAssistance: boolean | null;
  languageAssistanceProvided: boolean | null;
}

export type ProgressNoteSessionSummaryCreationAttributes = Omit<
  ProgressNoteSessionSummaryAttributes,
  'id' | 'noteID' | 'createdAt'
>;

export const mentalStatusExamDict = {
  appearance,
  attitude,
  behavior,
  speech,
  affect,
  mood,
  thoughtProcesses,
  perception,
  insightOrJudgement,
};

export namespace MentalStatusExam {
  export type Appearance = keyof typeof appearance;
  export type Attitude = keyof typeof attitude;
  export type Behavior = keyof typeof behavior;
  export type Speech = keyof typeof speech;
  export type Affect = keyof typeof affect;
  export type Mood = keyof typeof mood;
  export type ThoughtProcesses = keyof typeof thoughtProcesses;
  export type Perception = keyof typeof perception;
  export type InsightOrJudgement = keyof typeof insightOrJudgement;
}

export interface ProgressNoteMentalStatusExamAttributes {
  id: number;
  noteID: number;
  createdAt: string;
  appearance: MentalStatusExam.Appearance | null;
  appearanceDescription: string | null;
  attitude: MentalStatusExam.Attitude | null;
  attitudeDescription: string | null;
  behavior: MentalStatusExam.Behavior | null;
  behaviorDescription: string | null;
  speech: MentalStatusExam.Speech | null;
  speechDescription: string | null;
  affect: MentalStatusExam.Affect | null;
  affectDescription: string | null;
  mood: MentalStatusExam.Mood | null;
  moodDescription: string | null;
  thoughtProcesses: MentalStatusExam.ThoughtProcesses | null;
  thoughtProcessesDescription: string | null;
  perception: MentalStatusExam.Perception | null;
  perceptionDescription: string | null;
  insightOrJudgement: MentalStatusExam.InsightOrJudgement | null;
}

export type ProgressNoteMentalStatusExamCreationAttributes = Omit<
  ProgressNoteMentalStatusExamAttributes,
  'id' | 'noteID' | 'createdAt'
>;

export type NextFollowup = keyof typeof nextFollowup;

export interface ProgressNotePsychiatryPlanAttributes {
  id: number;
  noteID: number;
  createdAt: string;
  labsRequested: boolean;
  labsRequestedDetails: string | null;
  nextFollowup: NextFollowup;
  otherNextFollowup: string | null;
  additionalInfo: string | null;
  risksAndBenefitsConsent: boolean;
  risksAndBenefitsConsentDetails: string | null;
}

export type ProgressNotePsychiatryPlanCreationAttributes = Omit<
  ProgressNotePsychiatryPlanAttributes,
  'id' | 'noteID' | 'createdAt'
>;

export type PsychotropicMedication = keyof typeof psychotropicMedications;

export interface ProgressNotePsychiatryPlanMedicationAttributes {
  id: number;
  noteID: number;
  medication: PsychotropicMedication | null;
  dosage: string | null;
  otherMedication: string | null;
  createdAt: string;
  deletedAt: string | null;
}

export type ProgressNotePsychiatryPlanMedicationCreationAttributes = Pick<
  ProgressNotePsychiatryPlanMedicationAttributes,
  'medication' | 'dosage' | 'otherMedication'
>;

export interface VideoCall {
  id: number;
  roomID: number;
  totalActiveCallDuration: number;
  callStartedAt: Date;
  modality: LiveSessionModality;
}

export interface ProgressNoteDiagnosisAttributes {
  id: number;
  noteID: number;
  conditionID: number;
  isProvisionalDiagnosis: boolean;
  createdAt: string;
  deletedAt: string | null;
  isBillable?: boolean;
}

export type ProgressNoteDiagnosisCreationAttributes = Partial<ProgressNoteDiagnosisAttributes>;

export interface ProgressNoteMedicalInformationAttributes {
  id: number;
  noteID: number;
  createdAt: string;
  otherCurrentPrescriptionMedications: string | null;
  currentOTCMedications: string | null;
  drugAllergies: string | null;
}

export type ProgressNoteMedicalInformationCreationAttributes = Omit<
  ProgressNoteMedicalInformationAttributes,
  'id' | 'noteID' | 'createdAt'
>;

export interface ProgressNoteMedicalInformationConditionAttributes {
  id: number;
  noteID: number;
  conditionID: number;
  createdAt: string;
  deletedAt: string | null;
}

export type ProgressNoteMedicalInformationConditionCreationAttributes = Pick<
  ProgressNoteMedicalInformationConditionAttributes,
  'conditionID'
>;

export interface ProgressNoteMedicalInformationMedicationAttributes {
  id: number;
  noteID: number;
  medicationID: number;
  createdAt: string;
  deletedAt: string | null;
}

export type ProgressNoteMedicalInformationMedicationCreationAttributes = Pick<
  ProgressNoteMedicalInformationMedicationAttributes,
  'medicationID'
>;

export interface ProgressNoteVideoCallAttributes {
  id: number;
  noteID: number;
  videoCallID: number;
}

export type ProgressNoteVideoCallCreationAttributes = Pick<
  ProgressNoteVideoCallAttributes,
  'videoCallID'
>;

export interface ProgressNoteVideoCallResponseAttributes
  extends ProgressNoteVideoCallCreationAttributes {
  liveCall?: VideoCall;
}

export interface SessionService {
  id: number;
  name: string;
  recommended: boolean;
  recommendedText: string;
  allowSync: boolean;
  allowAsync: boolean;
  cptCode: string;
  minWordCount: number | null;
  maxWordCount: number | null;
  isInitialEval: boolean;
  sessionServiceSubtitle: string;
  sessionServiceTitle: string;
  sessionServiceDisplayName: string;
  isSelectable: boolean;
}

export interface ReferralCondition {
  id: number;
  condition: string;
}

export interface User {
  firstName: string;
  lastName: string;
  id: number;
}

export type FormMode = 'create' | 'edit' | 'view';

export interface ReopenedSession {
  modalityID: number | null;
  serviceStartDate: Date | null;
  serviceEndDate: Date | null;
  videoCalls: Array<ProgressNoteVideoCallCreationAttributes>;
}

export interface ProgressNoteFormContainerLocationState {
  reopenedSession?: ReopenedSession;
  sessionHighlights?: string;
  postActionRoute?: 'notes-tab' | 'all-unsubmitted-sessions' | 'supervised-notes';
  source?: 'notes_tab' | 'task_list' | 'supervised_notes';
}

export type NoteType =
  | 'progress'
  | 'collateral'
  | 'case-consultation'
  | 'psychotherapy'
  | 'discharge'
  | 'psychiatry-referral'
  | 'transfer';

export interface PresentingProblem {
  id: number;
  text: string;
}

export interface NoteError {
  title: string;
  message: string;
  generatedSessionSummaryID?: number;
}

export interface TooltipText {
  title: string;
  text: string;
  link?: string;
  bulletPoints?: Array<{ title: string; subtitle?: string }>;
}

export enum ProgressNoteTabs {
  WriteNote = 'write-note',
  ViewChat = 'view-chat',
}

export interface TreatmentPlan {
  treatmentPlanID: number;
  treatmentPlanGoals?: ProgressNoteTreatmentPlanGoalCreationAttributes[];
  treatmentPlanObjectives?: ProgressNoteTreatmentPlanObjectiveCreationAttributes[];
  treatmentPlanInterventions?: ProgressNoteTreatmentPlanInterventionCreationAttributes[];
}

export type SubstanceUseNoteFieldName = keyof Omit<
  ProgressNoteSubstanceUseCreationAttributes,
  'pastSubstanceUse' | 'currentSubstanceUse'
>;

export interface SubstanceUseNoteField {
  label: string;
  fieldName: SubstanceUseNoteFieldName;
}

export interface ProgressNoteDiagnosticIntakeAttributes {
  id: number;
  noteID: number;
  createdAt: Date;
  presentingProblems: string | null;
  historyOfPresentingProblems: string | null;
  psychiatricHistory: string | null;
  medicalHistory: string | null;
  familyHistory: string | null;
  substanceUseHistory: string | null;
  psychosocialDevelopmentalHistory: string | null;
  legalHistory: string | null;
  traumaHistory: string | null;
}

export type ProgressNoteDiagnosticIntakeCreationAttributes = Omit<
  ProgressNoteDiagnosticIntakeAttributes,
  'id' | 'noteID' | 'createdAt'
>;

export type ProgressNoteFormVariant = 'default' | 'supervisor' | 'associate';
