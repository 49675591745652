import { useMutation, useQueryClient } from 'react-query';
import { ProgressNoteFormState } from 'components/Room/CRMContainer/NotesTab/ProgressNoteForm/context/types';
import { createProgressNoteRequest } from 'components/Room/CRMContainer/NotesTab/utils';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import {
  notesListQueryKey,
  progressNoteHelperDataQueryKey,
  progressNoteQueryKey,
  supervisedNotesQueryKey,
} from './queryKeys';
import dashboardKeys from '../dashboard/queryKeys';
import { sessionServicesQueryKey } from '../queryKeys';
import analytics from '../../components/Room/CRMContainer/NotesTab/Analytics';

interface SubmitProgressNoteMutateVariables
  extends Omit<ProgressNoteFormState, 'showGlobalError' | 'formMode'> {
  roomID: string;
  clientUserID?: number;
  noteID?: string;
  isTherapist?: boolean;
  isTransferred?: boolean;
  therapistID?: number;
}

const submitProgressNote = async (params: SubmitProgressNoteMutateVariables): Promise<void> => {
  const {
    roomID,
    noteID,
    clientUserID,
    isTherapist,
    isTransferred,
    therapistID,
    formVariant = 'default',
    ...progressNoteState
  } = params;

  const version = isTransferred && formVariant === 'default' ? 'v3' : 'v2';
  const action = formVariant === 'default' ? 'submit' : 'submit-for-review';
  const requestURL = noteID
    ? `/${version}/rooms/${roomID}/progress-notes/${noteID}/${action}`
    : `/${version}/rooms/${roomID}/progress-notes/${action}`;

  const method: keyof typeof apiWrapper = noteID ? 'patch' : 'post';

  const requestBody = createProgressNoteRequest(progressNoteState, isTherapist);
  analytics.trackProgressNoteApiCalledEvent({
    calledEndPointDescription: 'SubmitProgressNote',
    roomID,
    noteID,
    serviceStartDate: progressNoteState.serviceStartDate,
    serviceEndDate: progressNoteState.serviceEndDate,
    modalityID: progressNoteState.modalityID,
    videoCalls: progressNoteState.videoCalls,
  });
  await apiWrapper[method](`${apiHelper().apiEndpoint}${requestURL}`, requestBody);
};

const useMutationSubmitProgressNote = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, SubmitProgressNoteMutateVariables>(submitProgressNote, {
    onSuccess: (_, { roomID, noteID, clientUserID, isTransferred, therapistID, formVariant }) => {
      if (formVariant === 'supervisor' && therapistID) {
        queryClient.invalidateQueries(supervisedNotesQueryKey(therapistID));
        return;
      }

      queryClient.invalidateQueries(notesListQueryKey(roomID));
      queryClient.invalidateQueries(progressNoteHelperDataQueryKey(roomID));
      queryClient.invalidateQueries(sessionServicesQueryKey(roomID, clientUserID));
      if (noteID) {
        queryClient.invalidateQueries(progressNoteQueryKey(clientUserID, noteID, roomID));
      }
      if (isTransferred && therapistID) {
        queryClient.invalidateQueries(dashboardKeys.tasklistV3(therapistID));
      }

      const refetchSessionStatusEvent = new CustomEvent('refetchSessionStatus', {
        detail: {},
      });
      window.dispatchEvent(refetchSessionStatusEvent);
    },
  });
};

export default useMutationSubmitProgressNote;
