import { useMutation, useQueryClient } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { supervisedNotesQueryKey } from './queryKeys';

interface RequestNoteRevisionParams {
  roomID: number;
  noteID: number;
  therapistUserID: number;
}

const requestNoteRevision = async ({
  roomID,
  noteID,
}: RequestNoteRevisionParams): Promise<void> => {
  await apiWrapper.patch(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/progress-notes/${noteID}/request-revision`,
    {}
  );
};

export default function useMutationRequestNoteRevision() {
  const queryClient = useQueryClient();
  return useMutation<void, Error, RequestNoteRevisionParams>(requestNoteRevision, {
    onSuccess: (_, { therapistUserID }) => {
      queryClient.invalidateQueries(supervisedNotesQueryKey(therapistUserID));
    },
  });
}
