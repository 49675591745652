import { useRef } from 'react';
import { View, TextAreaRounded, useEmotionTheme, OptionType } from '@talkspace/react-toolkit';

import SelectContainer from '../../containers/SelectContainer';
import {
  getSelectStyleV2,
  getTextAreaContainerBorderStyleV2,
  getTextAreaStyleV2,
} from '../../../utils';

interface Props<T extends string> {
  title: string;
  options: { value: T; label: string }[];
  otherOption: string | null;
  value: T | null;
  onOptionChange: (selected: OptionType<T>) => void;
  onOtherOptionChange: (value: string) => void;
  otherOptionPlaceholder: string;
  disabled: boolean;
  isCategoryError: boolean;
  isDescriptionError: boolean;
  isLast: boolean;
}

export default function MentalStatusExamQuestion<T extends string>({
  title,
  options,
  value,
  otherOption,
  onOptionChange,
  onOtherOptionChange,
  otherOptionPlaceholder,
  disabled,
  isCategoryError,
  isDescriptionError,
  isLast,
}: Props<T>) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { colors } = useEmotionTheme();

  const handleOptionChange = (selected: OptionType<T>) => {
    if (selected.value === 'other') {
      setTimeout(() => {
        textAreaRef?.current?.focus();
      }, 0);
    }
    onOptionChange(selected);
  };

  return (
    <View>
      <SelectContainer
        value={options.find((it) => it.value === value)}
        title={title}
        handleChange={handleOptionChange}
        options={options}
        styles={getSelectStyleV2({ colors, minWidth: 195, isError: isCategoryError })}
        isDisabled={disabled}
        dataQa={`mse${title}Dropdown`}
        dataTestID={`mse${title}Dropdown`}
        error={isCategoryError ? 'Required' : undefined}
        menuPlacement={isLast ? 'top' : 'auto'}
      />

      <View row style={{ justifyContent: 'flex-end' }}>
        {value === 'other' && (
          <TextAreaRounded
            ref={textAreaRef}
            multiline
            autoSize
            style={{
              ...getTextAreaContainerBorderStyleV2(colors, isDescriptionError),
              width: 195,
              marginBottom: 10,
            }}
            value={otherOption || ''}
            onChangeText={onOtherOptionChange}
            textAreaStyle={{
              ...getTextAreaStyleV2(disabled, colors),
              margin: '6px 10px',
              width: undefined,
            }}
            disabled={disabled}
            placeholder={otherOptionPlaceholder}
            dataQa={`mse${title}TextArea`}
          />
        )}
      </View>
    </View>
  );
}
