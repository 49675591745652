import { useMutation } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';

interface ApproveProgressNoteParams {
  roomID: number;
  noteID: number;
  therapistUserID: number;
}

const approveProgressNote = async ({
  roomID,
  noteID,
}: ApproveProgressNoteParams): Promise<void> => {
  await apiWrapper.patch(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/progress-notes/${noteID}/approve`,
    {}
  );
};

export default function useMutationApproveProgressNote() {
  return useMutation<void, Error, ApproveProgressNoteParams>(approveProgressNote);
}
