import { useMutation, useQueryClient } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { availableTalkTrack } from './queryKeys';

export interface SendTalkTracksProps {
  roomID: string;
  talktrackID: number;
}

const sendTalkTracks = async (params: SendTalkTracksProps): Promise<void> => {
  const { roomID, talktrackID } = params;
  await apiWrapper.post(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/talktracks/${talktrackID}/send`,
    {}
  );
};

const useMutationSendTalktrack = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, SendTalkTracksProps>(sendTalkTracks, {
    onSuccess: (_, { roomID }) => {
      queryClient.invalidateQueries(availableTalkTrack(roomID));
    },
  });
};

export default useMutationSendTalktrack;
